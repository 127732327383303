import { CustomInput } from "@/components/customComponents/CustomInput";
import { CollectionArtworks } from "@/components/discover/artistDetail/CollectionArtworks";
import { CollectionBookmark } from "@/components/discover/artistDetail/CollectionBookmark";
import { SelectCollectionOption } from "@/components/discover/artistDetail/SelectCollectionOption";
import {
  IBookmarkSettingData,
  IRequestFavoriteArtsData,
} from "@/pages/discover/types";
import useGlobalStore from "@/store";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { debounce } from "lodash";
import React, { useState } from "react";

interface IPortFolioComProps {
  containerRef: React.RefObject<HTMLDivElement>;
  author_id: string;
  userId: string;
  user_role: string;
}

export const ArtistCollections: React.FC<IPortFolioComProps> = ({
  containerRef,
  author_id,
  userId,
  user_role,
}) => {
  const { currentRole, updateLoading } = useGlobalStore((state) => state);
  const [collectionState, setCollectionState] = useState<string>("artworks");
  const [bookmarkSettingModal, setBookmarkSettingModal] =
    useState<IBookmarkSettingData>({
      open: false,
      bookmarkId: "",
      editState: false,
      folderName: "",
      isPrivate: false,
    });

  const [requestData, setRequestData] = useState<IRequestFavoriteArtsData>({
    user_id: author_id,
    page: 1,
    size: 10,
    sort: "LATEST",
  });
  const onCollectionSearch = debounce((value: string) => {
    setRequestData((prev) => ({ ...prev, search: value }));
  }, 1000);

  const onCreateCollection = () => {
    setBookmarkSettingModal((open) => ({ ...open, open: true }));
  };

  return (
    <Row className="flex flex-col">
      <Col className="flex flex-row gap-4">
        <SelectCollectionOption
          collectionState={collectionState}
          setCollectionState={setCollectionState}
        />
        {collectionState === "artworks" && (
          <CustomInput
            roundRadius
            size="large"
            placeholder="Search"
            prefix={<SearchOutlined style={{ color: "#686868" }} />}
            className={
              "gap-[10px] py-[10px] pl-[5px] text-base max-w-[354px] sm:w-[200px] lg:w-[300px] 2xl:w-[500px] "
            }
            onChange={(e) => onCollectionSearch(e.target.value)}
          />
        )}
        {collectionState === "bookmark" &&
          author_id === userId &&
          user_role === currentRole && (
            <Col className="flex flex-1 justify-end">
              <Button
                className="py-2 px-6 h-full rounded-full bg-[#00ADAE] text-white text-sm border-none"
                onClick={onCreateCollection}
              >
                <PlusOutlined />
                <span>Create Collection</span>
              </Button>
            </Col>
          )}
      </Col>
      <Col className="mt-4">
        {collectionState === "artworks" ? (
          <CollectionArtworks
            containerRef={containerRef}
            requestData={requestData}
            user_role={user_role}
          />
        ) : (
          <CollectionBookmark
            containerRef={containerRef}
            user_role={user_role}
            author_id={author_id}
            userId={userId}
            bookmarkSetting={bookmarkSettingModal}
            setBookmarkSetting={setBookmarkSettingModal}
          />
        )}
      </Col>
    </Row>
  );
};
