import {
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useOne,
} from "@refinedev/core";
import { Button, Card, Empty, Tabs, message } from "antd";
import "antd/es/modal/style";
import "antd/es/slider/style";
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IIdentity } from "../../interfaces";

import { UserType } from "@/__generated__/graphql";
import { ArtistCollections } from "@/components/discover/artistDetail/ArtistCollections";
import { ArtistFollow } from "@/components/discover/artistDetail/ArtistFollow";
import { ArtistIntroduction } from "@/components/discover/artistDetail/ArtistIntroduction";
import { ArtistLikes } from "@/components/discover/artistDetail/ArtistLikes";
import { ArtistMenu } from "@/components/discover/artistDetail/ArtistMenu";
import { PersonalCenterCompanyJobs } from "@/pages/companies/components/PersonalCenterCompanyJobs";
import { PersonalCenterCompanyPortfolio } from "@/pages/companies/components/PersonalCenterCompanyPortfolio";
import useGlobalStore from "@/store";
import CompanyImgInfo from "./components/CompanyImgInfo";
import PersonalCenterIntroduction from "./components/PersonalCenterIntroduction";
import { Department, ICompanyInfo, Job } from "./type";
import { isEmptyExtendedInfo, transformArtistInfo } from "./utils";

const EmptyCard: React.FC = () => (
  <Card>
    <Empty
      description={
        <span>The company homepage has not been filled out yet.</span>
      }
    >
      <Link to="/companies/edit-homepage">
        <Button type="primary">Complete Company Homepage Now</Button>
      </Link>
    </Empty>
  </Card>
);

export const CompanyHomepage: React.FC<IResourceComponentsProps> = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { currentRole, updateLoading } = useGlobalStore((state) => state);
  const { data: user } = useGetIdentity<IIdentity>();
  const user_id = user?.id || "";
  const navigate = useNavigate();
  const apiUrl = useApiUrl();
  const TOKEN_KEY = "leyline-auth";
  const token = localStorage.getItem(TOKEN_KEY);
  const [authorTabState, setAuthorTabState] = useState<string>("portfolio");
  const [selectedOption, setSelectedOption] = useState("modelling");
  const [hasPostedJobs, setHasPostedJobs] = useState(false);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const handleJobClick = (jobId: string) => {
    navigate(`/jobs/detail/${jobId}`);
  };
  useEffect(() => {
    const fetchJobsDetails = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/jobs/published?page=1&size=100`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        const jobsData = await response.json();
        if (response.status === 200 && jobsData.items.length > 0) {
          setHasPostedJobs(true);
          setJobs(jobsData.items);
        } else {
          setHasPostedJobs(false);
        }
      } catch (error) {
        console.error("Error fetching jobs details:", error);
        setHasPostedJobs(false);
      }
    };
    fetchJobsDetails();
  }, [apiUrl, token]);

  const selectedDepartment = departments.find(
    (dept) => dept.name === selectedOption,
  );
  const filteredJobs = jobs.filter(
    (job) => job.department_id === selectedDepartment?.id,
  );
  const {
    data,
    isLoading: isDataLoading,
    refetch,
  } = useOne<ICompanyInfo>({
    resource: "companies",
    id: "my-company",
    queryOptions: {
      enabled: true,
    },
  });

  const [isLoading, setIsLoading] = useState(true);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    refetch();
  }, []);

  const companyInfo: ICompanyInfo | undefined = data?.data;
  const artistInfo = transformArtistInfo(companyInfo);
  // const { extended_info } = companyInfo ?? { extended_info: null };

  // const extendedInfoIsEmpty = extended_info?.logo_url
  //   ? isEmptyExtendedInfo(extended_info)
  //   : true;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!isDataLoading && data) {
      if (!data.data || data.data?.verification_status === "UNSUBMITTED") {
        message.info(
          "To access the company homepage, please first create a company and wait for approval of your company verification. Redirecting to company verification page...",
        );
        navigate("/companies/show");
      } else if (!data.data?.is_verified) {
        navigate("/companies/show");
      }
      setIsLoading(false);
    }
  }, [isDataLoading]);

  if (isLoading || !data) {
    return <div>Loading...</div>;
  }

  const renderContent = () => {
    switch (authorTabState) {
      case "portfolio":
        return <PersonalCenterCompanyPortfolio containerRef={containerRef} />;
      case "introduction":
        return (
          companyInfo && (
            <PersonalCenterIntroduction companyInfo={companyInfo} />
          )
        );
      case "jobs":
        return (
          <PersonalCenterCompanyJobs
            containerRef={containerRef}
            userId={user_id}
          />
        );
      case "likes":
        return (
          <ArtistLikes
            containerRef={containerRef}
            author_id={user_id}
            user_role={currentRole ?? "INDIVIDUAL"}
          />
        );
      case "collections":
        return (
          <ArtistCollections
            containerRef={containerRef}
            author_id={user_id}
            userId={user_id}
            user_role={currentRole ?? "INDIVIDUAL"}
          />
        );
      case "follow":
        return (
          <ArtistFollow
            containerRef={containerRef}
            author_id={user_id}
            user_role={currentRole ?? "INDIVIDUAL"}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div ref={containerRef} className="p-4 h-full overflow-auto">
      {!!companyInfo && artistInfo && (
        <ArtistIntroduction
          artistUserInfo={artistInfo}
          artistId={user_id}
          user_role={UserType.Company}
        />
      )}
      <ArtistMenu
        setAuthorTab={setAuthorTabState}
        authorTab={authorTabState}
        user_role={UserType.Company}
      />
      <div className="flex flex-col w-full">{renderContent()}</div>
    </div>
  );
};
