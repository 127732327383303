import { CustomCard } from "@/components/customComponents/CustomCard";
import { videoExtensions } from "@/utils/identifyFiles";
import { Button, Card, Col, Divider, Empty, Image, Row, Space } from "antd";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { getImgUrl } from "../../../utils/common";
import { ICompanyInfo } from "../type";
import { isEmptyExtendedInfo } from "../utils";
const videoExtensionsRegex = videoExtensions.join("|");
const PersonalCenterIntroduction = ({
  companyInfo,
}: { companyInfo: ICompanyInfo | undefined }) => {
  const { extended_info } = companyInfo ?? { extended_info: null };
  const extendedInfoIsEmpty = extended_info
    ? isEmptyExtendedInfo(extended_info)
    : true;

  const renderAddresses = () => {
    return (
      <div>
        {extended_info?.addresses?.map((address, index) => (
          <div key={address}>
            {index !== 0 && <Divider className="bg-white opacity-10 my-5" />}
            {address}
          </div>
        ))}
      </div>
    );
  };

  const renderMediaGallery = () => {
    return (
      <Row gutter={16}>
        {extended_info?.pic_urls?.map(
          (url) =>
            url && (
              <Col key={url} span={6} style={{ marginBottom: 16 }}>
                {url.match(`\\.(?:${videoExtensionsRegex})$`) ? (
                  <div className="w-full aspect-[16/9]">
                    <ReactPlayer
                      url={getImgUrl(url) as string}
                      controls
                      width="100%"
                      height="100%"
                      config={{
                        file: {
                          attributes: {
                            onContextMenu: (e: React.MouseEvent) =>
                              e.preventDefault(),
                            controlsList: "nodownload",
                          },
                        },
                      }}
                    />
                  </div>
                ) : (
                  <Image
                    className="w-full aspect-[16/9] object-cover"
                    preview={true}
                    src={getImgUrl(url) || ""}
                  />
                )}
              </Col>
            ),
        )}
      </Row>
    );
  };

  return (
    <div className="gap-4 flex flex-col">
      {extendedInfoIsEmpty ? (
        <Card>
          <Empty
            description={
              <span>The enterprise homepage has not been filled out yet.</span>
            }
          >
            <Link to="/companies/edit-homepage">
              <Button type="primary">Edit now</Button>
            </Link>
          </Empty>
        </Card>
      ) : (
        <>
          <CustomCard title="Company Description">
            <p>
              {extended_info?.description ||
                "No profile information available."}
            </p>
          </CustomCard>
          <CustomCard title="Business Field">
            <div className="flex flex-row gap-2 flex-wrap">
              {extended_info?.business_fields &&
              extended_info.business_fields.length > 0 ? (
                extended_info.business_fields.map((area) => (
                  <Space
                    key={area}
                    className="bg-[#404444] text-white rounded-full text-sm py-0.5 px-2 hover:bg-[#606464]"
                  >
                    {area}
                  </Space>
                ))
              ) : (
                <p>No business field specified.</p>
              )}
            </div>
          </CustomCard>
          <CustomCard title="Company Photo Album">
            {renderMediaGallery()}
          </CustomCard>

          <CustomCard title="Address">{renderAddresses()}</CustomCard>
        </>
      )}
    </div>
  );
};

export default PersonalCenterIntroduction;
