import { NoBookmarkIcon } from "@/components/customComponents/CustomIcons";
import { CustomInput } from "@/components/customComponents/CustomInput";
import { IIdentity } from "@/interfaces";
import {
  addArtworkToBookmarkAPI,
  createBookmarkAPI,
  getBookmarkListAPI,
} from "@/pages/discover/api";
import {
  IBookmarkItem,
  IRequestBookmarkListData,
} from "@/pages/discover/types";
import useGlobalStore from "@/store";
import Icon, { CloseOutlined } from "@ant-design/icons";
import { useGetIdentity } from "@refinedev/core";
import {
  Button,
  Checkbox,
  CheckboxProps,
  Col,
  Row,
  Space,
  message,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";

interface IBookmarkSeetingProps {
  setBookmarkSetting: (value: boolean) => void;
  artworkId: string;
  setShowAlarm: (actionType: string, bookmarkTitle: string) => void;
}

export const BookmarkSetting: React.FC<IBookmarkSeetingProps> = ({
  setBookmarkSetting,
  artworkId,
  setShowAlarm,
}) => {
  const { data: user } = useGetIdentity<IIdentity>();
  const user_id = user?.id || "";
  const { currentRole, updateLoading } = useGlobalStore((state) => state);
  const [bookmarkList, setBookmarkList] = useState<IBookmarkItem[]>([]);
  const [requestData, setRequestData] = useState<IRequestBookmarkListData>({
    page: 1,
    size: 100,
  });
  const [selectBookmark, setSelectBookmark] = useState(true);
  const [bookmarkName, setBookmarkName] = useState<string>("");
  const [isPrivate, setIsPrivate] = useState(true);

  const getBookmarkList = useCallback(async () => {
    if (!currentRole) return;
    const response = await getBookmarkListAPI(
      updateLoading,
      user_id,
      requestData,
      currentRole,
    );
    setBookmarkList(response.list);
  }, [updateLoading, user_id, requestData, currentRole]);

  useEffect(() => {
    selectBookmark && getBookmarkList();
  }, [getBookmarkList, selectBookmark]);

  const addArtworkToBookmark = async (
    bookmarkId: string,
    bookmarkTitle: string,
  ) => {
    await addArtworkToBookmarkAPI(bookmarkId, artworkId);
    setShowAlarm("bookmark", bookmarkTitle);
    setBookmarkSetting(false);
  };

  const onCreateBookmarkConfirm = async () => {
    if (bookmarkName === "") {
      message.error("Please enter bookmark name");
    } else {
      if (!currentRole) return;
      await createBookmarkAPI(currentRole, bookmarkName, isPrivate);
      setSelectBookmark(true);
      setBookmarkName("");
    }
  };

  const onCreateBookmarkName = (value: string) => {
    setBookmarkName(value);
  };

  const handlePrivateChange: CheckboxProps["onChange"] = (e) => {
    setIsPrivate(e.target.checked);
  };

  return (
    <Row className="flex flex-col rounded-xl bg-[#343638] shadow-md mt-2 text-white">
      <Col className="flex flex-row justify-between pl-5 pr-4 pb-1 pt-4">
        {selectBookmark ? (
          <span className="text-base">Select Bookmark</span>
        ) : (
          <span>Create Bookmark</span>
        )}
        {selectBookmark ? (
          <CloseOutlined onClick={() => setBookmarkSetting(false)} />
        ) : (
          <CloseOutlined onClick={() => setSelectBookmark(true)} />
        )}
      </Col>
      <Col className="border-t-[1px] border-b-[1px] border-solid border-opacity-10 border-white pl-5 pr-4 pt-4 pb-2.5 h-[120px] overflow-auto">
        {selectBookmark ? (
          <Col>
            {bookmarkList.length > 0 ? (
              bookmarkList.map((bookmark) => (
                <Row
                  key={bookmark.id}
                  className="flex flex-row justify-between items-center py-2 cursor-pointer"
                  onClick={() =>
                    addArtworkToBookmark(bookmark.id, bookmark.title)
                  }
                >
                  <Space>{bookmark.title}</Space>
                </Row>
              ))
            ) : (
              <Col className="flex justify-center items-center w-full h-full">
                <Icon className="text-[90px]" component={NoBookmarkIcon} />
              </Col>
            )}
          </Col>
        ) : (
          <Col className="flex flex-col gap-2">
            <CustomInput
              value={bookmarkName}
              placeholder="Bookmark Title"
              maxLength={30}
              className="!rounded-full !w-full !bg-transparent !border-[1px] !border-solid !border-[#606264]"
              onChange={(e) => onCreateBookmarkName(e.target.value)}
            />
            <Checkbox
              checked={isPrivate}
              className="text-white text-sm"
              onChange={handlePrivateChange}
            >
              <span>Private</span>
            </Checkbox>
          </Col>
        )}
      </Col>
      <Col className="flex justify-end py-1.5 pr-4">
        {selectBookmark ? (
          <Button
            className="py-2 px-[30px] h-full rounded-full bg-[#00ADAE] text-white text-sm border-none"
            onClick={() => setSelectBookmark(false)}
          >
            Create Bookmark
          </Button>
        ) : (
          <Button
            className="py-2 px-[30px] h-full rounded-full bg-[#00ADAE] text-white text-sm border-none"
            onClick={onCreateBookmarkConfirm}
          >
            Confirm
          </Button>
        )}
      </Col>
    </Row>
  );
};
