import { CustomInput } from "@/components/customComponents/CustomInput";
import { CustomSelect } from "@/components/customComponents/CustomSelect";
import { CustomTextArea } from "@/components/customComponents/CustomTextArea";
import {
  createArtworkAPI,
  getArtworkDetailAPI,
  getFoldersAPI,
  updateArtworkAPI,
} from "@/pages/discover/api";
import {
  ICreateArtworkFormValues,
  IFolder,
  ISubmitArtworkData,
} from "@/pages/discover/types";
import useGlobalStore from "@/store";
import {
  imageExtensions,
  isImageFiles,
  videoExtensions,
} from "@/utils/identifyFiles";
import { skillOptions, softwareOptions } from "@/utils/selectionList";
import { padImageUrl } from "@/utils/upload";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { IResourceComponentsProps, useApiUrl } from "@refinedev/core";
import {
  Button,
  Checkbox,
  Col,
  Form,
  GetProp,
  Image,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Link, useLocation, useNavigate } from "react-router-dom";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];
const TOKEN_KEY = import.meta.env.VITE_AUTH_TOKEN_KEY;
const RESOURCES_BASE_URL = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;
const videoExtensionsRegex = videoExtensions.join("|");
const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface DraggableUploadListItemProps {
  originNode: React.ReactElement<
    // biome-ignore lint:
    any,
    // biome-ignore lint:
    string | React.JSXElementConstructor<any>
  >;
  // biome-ignore lint:
  file: UploadFile<any>;
}

const DraggableUploadListItem = ({
  originNode,
  file,
}: DraggableUploadListItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: file.uid,
  });

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: "move",
    width: "100px",
    height: "100px",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      // prevent preview event when drag end
      className={isDragging ? "is-dragging" : ""}
      {...attributes}
      {...listeners}
    >
      {/* hide error tooltip when dragging */}
      {file.status === "error" && isDragging
        ? originNode.props.children
        : originNode}
    </div>
  );
};

export const CreateArtwork: React.FC<IResourceComponentsProps> = () => {
  const { currentRole, updateLoading } = useGlobalStore((state) => state);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const edit_mode = searchParams.get("edit_mode");
  const artwork_id = searchParams.get("artwork_id");
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [imgFileList, setImgFileList] = useState<UploadFile[]>([]);
  const [folderList, setFolderList] = useState<IFolder[]>([]);
  const [publishStatus, setPublishStatus] = useState<boolean>(false);
  const [typeStatus, setTypeStatus] = useState<string>("ORIGINAL");
  const navigate = useNavigate();
  const apiUrl = useApiUrl();
  const [form] = Form.useForm();

  const handlePreviewClose = () => {
    setPreviewOpen(false);
    setPreviewImage("");
  };

  useEffect(() => {
    if (!currentRole) return;
    // Fetch folders api
    const fetchFolders = async () => {
      const data = await getFoldersAPI(currentRole);
      setFolderList(data);
    };

    fetchFolders();
  }, [currentRole]);

  useEffect(() => {
    const workState = localStorage.getItem("workState");
    if (!currentRole || workState === "true") return;
    if (edit_mode === "true" && artwork_id !== null) {
      const getArtworkDetail = async () => {
        const data = await getArtworkDetailAPI(
          updateLoading,
          currentRole,
          artwork_id,
        );
        form.setFieldsValue({
          mediaFiles: data.medias.map((media: string) => ({
            uid: media,
            name: media,
            status: "done",
            url: media,
          })),
          artworkTitle: data.title,
          artworkDescription: data.description,
          skills: data.skills,
          software: data.software,
          folder: data.folder_id,
        });
        setImgFileList(
          data.medias.map((media: string) => ({
            uid: media,
            name: media,
            status: "done",
            url: media,
          })),
        );
        setPublishStatus(data.is_published);
        setTypeStatus(data.type);
      };
      getArtworkDetail();
    }
  }, [edit_mode, artwork_id, currentRole, form, updateLoading]);

  // biome-ignore lint:
  useEffect(() => {
    const workState = localStorage.getItem("workState");
    const previewValues: ICreateArtworkFormValues = JSON.parse(
      localStorage.getItem("previewValues") || "{}",
    );

    if (workState === "true") {
      form.setFieldsValue({
        mediaFiles: previewValues.mediaFiles.map((media: UploadFile) =>
          media.type
            ? {
                uid: padImageUrl(media.response?.id || ""),
                name: padImageUrl(media.response?.id || ""),
                status: "done",
                url: padImageUrl(media.response?.id || ""),
              }
            : {
                uid: media.uid,
                name: media.name,
                status: media.status,
                url: media.url,
              },
        ),
        artworkTitle: previewValues.artworkTitle,
        artworkDescription: previewValues.artworkDescription,
        skills: previewValues.skills,
        software: previewValues.software,
        folder: previewValues.folder,
      });

      setImgFileList(
        previewValues.mediaFiles.map((media: UploadFile) =>
          media.type
            ? {
                uid: padImageUrl(media.response?.id || ""),
                name: padImageUrl(media.response?.id || ""),
                status: "done",
                url: padImageUrl(media.response?.id || ""),
              }
            : {
                uid: media.uid,
                name: media.name,
                status: media.status,
                url: media.url,
              },
        ),
      );
      setPublishStatus(previewValues.publishStatus);
      setTypeStatus(previewValues.type);
    }
  }, []);

  const handleImgPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps["onChange"] = ({
    fileList: newFileList,
    file,
  }) => {
    form.setFieldsValue({ mediaFiles: newFileList });
    setImgFileList(newFileList);
    if (file.status === "uploading") {
      updateLoading(true);
    } else if (file.status === "done" || file.status === "error") {
      updateLoading(false);
    }
  };

  const coverPicture = (data: string[]) => {
    for (const media of data) {
      if (isImageFiles(media)) {
        return media;
      }
    }
    return "";
  };

  const beforeUpload = (file: FileType) => {
    const isImage = file.type.startsWith("image/");
    const isVideo = file.type.startsWith("video/");
    const isLt20M = file.size / 1024 / 1024 < 20;
    const isLt100M = file.size / 1024 / 1024 < 100;

    if (isImage && !isLt20M) {
      message.error("Image must be smaller than 20MB!");
      return Upload.LIST_IGNORE;
    }

    if (isVideo && !isLt100M) {
      message.error("Video must be smaller than 50MB!");
      return Upload.LIST_IGNORE;
    }

    if (!isImage && !isVideo) {
      message.error("You can only upload image or video files!");
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const uploadButton = (
    <Button className="border-none bg-[#292D31] py-[30px] px-7 h-auto flex flex-col items-center justify-center gap-2 rounded-sm">
      <PlusOutlined className="text-sm text-[#686868]" />
      <span className="text-sm text-[#686868] !ml-0">Upload</span>
    </Button>
  );

  const handleSubmit = async (values: ICreateArtworkFormValues) => {
    values.publishStatus = publishStatus;
    values.type = typeStatus;
    const medias: string[] = [];
    let cover_picture = "";
    for (const file of values.mediaFiles) {
      if (file.type && file.response) {
        medias.push(padImageUrl(file.response.id));
      } else {
        medias.push(file.name);
      }
    }
    cover_picture = await coverPicture(medias);

    const submitArtWorkData: ISubmitArtworkData = {
      title: values.artworkTitle ? values.artworkTitle : "",
      description: values.artworkDescription ? values.artworkDescription : "",
      skills: values.skills ? values.skills : [],
      software: values.software ? values.software : [],
      folder_id: values.folder,
      cover_picture: cover_picture,
      medias: medias,
      is_published: values.publishStatus,
      type: values.type,
    };
    if (edit_mode === "true" && artwork_id !== null) {
      const response = await updateArtworkAPI(submitArtWorkData, artwork_id);
      response !== null && message.success("Artwork updated successfully!");
    } else {
      if (!currentRole) return;
      const response = await createArtworkAPI(
        updateLoading,
        submitArtWorkData,
        currentRole,
      );
      response !== null && message.success("Artwork submitted successfully!");
    }
    localStorage.setItem("workState", "false");
    navigate("/portfolio/home");
  };

  const handlePreview = async () => {
    try {
      const values = await form.validateFields();
      values.publishStatus = publishStatus;
      values.type = typeStatus;
      localStorage.setItem("previewValues", JSON.stringify(values));
      if (edit_mode === "true" && artwork_id !== null) {
        navigate(
          `/discover/portfolio/preview?edit_mode=true&artwork_id=${artwork_id}`,
        );
      } else {
        navigate("/discover/portfolio/preview");
      }
    } catch (errorInfo) {
      console.log("Validate Failed:", errorInfo);
    }
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setImgFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        const newFileList = arrayMove(prev, activeIndex, overIndex);

        // Update form state with the new order
        form.setFieldsValue({ mediaFiles: newFileList });

        return newFileList;
      });
    }
  };

  return (
    <Row className="flex flex-col flex-1 h-full px-3 pt-8">
      <Col>
        <Link to={"/portfolio/home"}>
          <LeftOutlined className="text-white text-base" />
        </Link>
        <Typography.Text className="text-white text-base font-bold">
          {edit_mode === "true" ? "Edit Artwork" : "Create Artwork"}
        </Typography.Text>
      </Col>
      <Col className="flex-1 overflow-auto">
        <Col className="flex min-h-full justify-center items-center pb-8">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            className="flex flex-col gap-2 max-w-[720px] create-artwork-form"
          >
            <Col className="flex flex-row gap-2">
              <Col className="text-white text-base justify-end flex flex-row gap-1 w-[170px]">
                <span className="text-[#FF4D4F]">*</span>
                <span>Media files(Max:10) :</span>
              </Col>
              <Col className="flex flex-col gap-2.5 flex-1">
                <Form.Item
                  name="mediaFiles"
                  valuePropName="imgFileList"
                  getValueFromEvent={(e) => e.imgFileList}
                  rules={[
                    {
                      validator: () => {
                        const hasImage = imgFileList.some((file) => {
                          // Check if the file has a type (local file uploads)
                          if (file.type) {
                            return file.type.startsWith("image/");
                          }

                          // Otherwise, infer the type from the file URL or name (for remote files)
                          const url = file.url || file.name || "";
                          return imageExtensions.some((ext) =>
                            url.toLowerCase().endsWith(ext),
                          );
                        });
                        if (!hasImage) {
                          return Promise.reject(
                            new Error("Please upload at least one image."),
                          );
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <DndContext sensors={[sensor]} onDragEnd={handleDragEnd}>
                    <SortableContext
                      items={imgFileList.map((file) => file.uid)}
                      strategy={verticalListSortingStrategy}
                    >
                      <Upload
                        // customRequest={customUpload}
                        multiple={true}
                        maxCount={10}
                        action={`${apiUrl}/discover/upload?role=${currentRole}`}
                        listType="picture-card"
                        fileList={imgFileList}
                        onPreview={handleImgPreview}
                        onChange={handleChange}
                        beforeUpload={beforeUpload}
                        itemRender={(originNode, file) => (
                          <DraggableUploadListItem
                            originNode={originNode}
                            file={file}
                          />
                        )}
                        headers={{
                          authorization: `Bearer ${localStorage.getItem(
                            TOKEN_KEY,
                          )}`,
                        }}
                      >
                        {imgFileList.length >= 10 ? null : uploadButton}
                      </Upload>
                    </SortableContext>
                  </DndContext>
                  {previewImage && (
                    <Image
                      wrapperStyle={{ display: "none" }}
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                          !visible && setPreviewImage(""),
                      }}
                      src={previewImage}
                    />
                  )}
                </Form.Item>
                {previewImage &&
                  (previewImage.match(`\\.(?:${videoExtensionsRegex})$`) ? (
                    <Space
                      className="w-full aspect-[16/9] absolute"
                      key={previewImage}
                      onClick={() => setPreviewOpen(true)}
                    >
                      <Modal
                        visible={previewOpen}
                        footer={null}
                        onCancel={handlePreviewClose}
                        styles={{
                          content: {
                            background: "transparent",
                            padding: 0,
                          },
                        }}
                        width="50%"
                      >
                        <ReactPlayer
                          url={previewImage}
                          controls
                          width="100%"
                          height="100%"
                          config={{
                            file: {
                              attributes: {
                                onContextMenu: (e: React.MouseEvent) =>
                                  e.preventDefault(),
                                controlsList: "nodownload",
                              },
                            },
                          }}
                        />
                      </Modal>
                    </Space>
                  ) : (
                    <Image
                      wrapperStyle={{ display: "none" }}
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                          !visible && setPreviewImage(""),
                      }}
                      src={previewImage}
                    />
                  ))}
                <Col className="flex flex-col">
                  <Typography.Text className="text-[#686868] text-sm">
                    1. Support batch upload pictures & videos
                  </Typography.Text>
                  <Typography.Text className="text-[#686868] text-sm">
                    2. Image size does not exceed 20mb, video size does not
                    exceed 100mb
                  </Typography.Text>
                </Col>
              </Col>
            </Col>
            <Col className="flex flex-row gap-2">
              <Col className="text-white text-base justify-end flex flex-row gap-1 w-[170px]">
                <span>Artwork Title :</span>
              </Col>
              <Col className="w-[400px]">
                <Form.Item
                  name="artworkTitle"
                  rules={[
                    {
                      validator: (_, value, callback) => {
                        const artworkDescription =
                          form.getFieldValue("artworkDescription");
                        if (!value && !artworkDescription) {
                          return Promise.reject(
                            new Error(
                              "The title and description cannot both be empty.",
                            ),
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <CustomInput maxLength={100} placeholder="Please enter..." />
                </Form.Item>
              </Col>
            </Col>
            <Col className="flex flex-row gap-2">
              <Col className="text-white text-base justify-end flex flex-row gap-1 w-[170px]">
                <span>Artwork Description :</span>
              </Col>
              <Col className="w-[400px]">
                <Form.Item
                  name="artworkDescription"
                  rules={[
                    {
                      validator: (_, value) => {
                        const artworkTitle = form.getFieldValue("artworkTitle");
                        if (!value && !artworkTitle) {
                          return Promise.reject(
                            new Error(
                              "The title and description cannot both be empty.",
                            ),
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <CustomTextArea
                    placeholder="Please enter..."
                    showCount
                    maxLength={10000}
                    autoSize={{ minRows: 4, maxRows: 30 }}
                  />
                </Form.Item>
              </Col>
            </Col>
            <Col className="flex flex-row gap-2">
              <Col className="text-white text-base text-right w-[170px]">
                Skills (Max: 20) :
              </Col>
              <Col className="w-[400px]">
                <Form.Item name="skills">
                  <CustomSelect
                    mode="multiple"
                    maxCount={20}
                    maxTagCount="responsive"
                    placeholder="Please Select"
                    options={skillOptions}
                  />
                </Form.Item>
              </Col>
            </Col>
            <Col className="flex flex-row gap-2">
              <Col className="text-white text-base text-right w-[170px]">
                Software (Max: 20) :
              </Col>
              <Col className="w-[400px]">
                <Form.Item name="software">
                  <CustomSelect
                    maxTagCount="responsive"
                    mode="multiple"
                    maxCount={20}
                    placeholder="Please Select"
                    options={softwareOptions}
                  />
                </Form.Item>
              </Col>
            </Col>
            <Col className="flex flex-row gap-2">
              <Col className="text-white text-base justify-end flex flex-row gap-1 w-[170px]">
                <span>Folder :</span>
              </Col>
              <Col className="w-[400px]">
                <Form.Item name="folder">
                  <CustomSelect placeholder="Please Select">
                    {folderList.map((folder) => (
                      <Select.Option key={folder.id} value={folder.id}>
                        {folder.folder_name}
                      </Select.Option>
                    ))}
                  </CustomSelect>
                </Form.Item>
              </Col>
            </Col>
            <Col className="flex flex-row gap-2 mb-3">
              <Col className="text-white text-base flex flex-row justify-end gap-1 w-[170px]">
                <span className="text-[#FF4D4F]">*</span>
                <span>Type :</span>
              </Col>
              <Col>
                <Col className="flex flex-row gap-1.5">
                  <Button
                    type="primary"
                    className={`py-1 px-4 rounded-full ${
                      typeStatus === "REPOST"
                        ? " bg-transparent border-solid border-[1px] border-[#606264] text-[#606264] text-sm"
                        : "bg-[#00ADAE] text-white text-sm border-none"
                    }`}
                    onClick={() => setTypeStatus("ORIGINAL")}
                  >
                    Original
                  </Button>
                  <Button
                    type="primary"
                    className={`py-1 px-4 rounded-full ${
                      typeStatus === "REPOST"
                        ? "bg-[#00ADAE] text-white text-sm border-none"
                        : " bg-transparent border-solid border-[1px] border-[#606264] text-[#606264] text-sm"
                    }`}
                    onClick={() => setTypeStatus("REPOST")}
                  >
                    Repost
                  </Button>
                </Col>
              </Col>
            </Col>
            <Col className="flex flex-row gap-2">
              <Col className="text-white text-base flex flex-row justify-end gap-1 w-[170px]">
                <span className="text-[#FF4D4F]">*</span>
                <span>Publish status :</span>
              </Col>
              <Col>
                <Col className="flex flex-row gap-1.5">
                  <Button
                    type="primary"
                    className={`py-1 px-4 rounded-full ${
                      publishStatus
                        ? " bg-transparent border-solid border-[1px] border-[#606264] text-[#606264] text-sm"
                        : "bg-[#00ADAE] text-white text-sm border-none"
                    }`}
                    onClick={() => setPublishStatus(false)}
                  >
                    Not Published
                  </Button>
                  <Button
                    type="primary"
                    className={`py-1 px-4 rounded-full ${
                      publishStatus
                        ? "bg-[#00ADAE] text-white text-sm border-none"
                        : " bg-transparent border-solid border-[1px] border-[#606264] text-[#606264] text-sm"
                    }`}
                    onClick={() => setPublishStatus(true)}
                  >
                    Published
                  </Button>
                </Col>
              </Col>
            </Col>
            <Col className="flex flex-row gap-2">
              <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("You must accept the agreement"),
                          ),
                  },
                ]}
                wrapperCol={{ offset: 6, span: 16 }}
              >
                <Checkbox className="text-xs">
                  By uploading onto the website you confirm that no third party
                  Intellectual Property rights have been violated, and you are
                  solely responsible for such infringements if any disputes
                  arise from your uploaded files.
                </Checkbox>
              </Form.Item>
            </Col>
            <Col className="flex flex-row gap-4 mt-[36px] ml-[170px]">
              <Button
                className="py-2 px-[30px] h-full rounded-full bg-transparent border-solid border-[1px] border-[#606264] text-[#606264] text-sm"
                onClick={() => navigate("/portfolio/home")}
              >
                Cancel
              </Button>
              <Button
                className="py-2 px-[30px] h-full rounded-full bg-transparent border-solid border-[1px] border-[#606264] text-[#606264] text-sm"
                onClick={handlePreview}
              >
                Preview
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="py-2 px-[30px] h-full rounded-full bg-[#00ADAE] text-white text-sm border-none"
              >
                {edit_mode === "true" ? "Update" : "Submit"}
              </Button>
            </Col>
          </Form>
        </Col>
      </Col>
    </Row>
  );
};
