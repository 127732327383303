import {
  LockIcon,
  NoDataIcon,
} from "@/components/customComponents/CustomIcons";
import { BookmarkDeleteConfirmModal } from "@/components/discover/artistDetail/BookmarkDeleteConfirmModal";
import { BookmarkDetail } from "@/components/discover/artistDetail/BookmarkDetail";
import { BookmarkSettingModal } from "@/components/discover/artistDetail/BookmarkSettingModal";
import { LeyLineIcon } from "@/components/icons";
import { getBookmarkListAPI } from "@/pages/discover/api";
import {
  IBookmarkItem,
  IBookmarkSettingData,
  IRequestBookmarkListData,
} from "@/pages/discover/types";
import useGlobalStore from "@/store";
import { getImgUrl } from "@/utils/common";
import Icon, { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useInfiniteScroll } from "ahooks";
import { Col, Image, Row, Space, Typography } from "antd";
import React, { useState } from "react";

interface ICollectionBookmarkProps {
  containerRef: React.RefObject<HTMLDivElement>;
  user_role: string;
  author_id: string;
  userId: string;
  bookmarkSetting: IBookmarkSettingData;
  setBookmarkSetting: (value: IBookmarkSettingData) => void;
}

export const CollectionBookmark: React.FC<ICollectionBookmarkProps> = ({
  containerRef,
  user_role,
  author_id,
  userId,
  bookmarkSetting,
  setBookmarkSetting,
}) => {
  const { currentRole, updateLoading } = useGlobalStore((state) => state);
  const [bookmarkState, setBookmarkState] = useState<boolean>(false);
  const [modalState, setModalState] = useState<boolean>(false);
  const [bookmarkId, setBookmarkId] = useState<string>("");
  const [handleChangeState, setHandleChangeState] = useState<string>("origin");
  const [bookmarkInfo, setBookmarkInfo] = useState<IBookmarkItem>({
    id: "",
    title: "",
    collection_count: 0,
    artworks: [],
    is_private: false,
  });

  const onSelectBookmark = (
    id: string,
    title: string,
    collection_count: number,
  ) => {
    setBookmarkInfo({
      id,
      title,
      collection_count,
      artworks: [],
      is_private: false,
    });
    setBookmarkState(true);
  };

  const handleEditBookmark = (
    bookmark_id: string,
    title: string,
    is_private?: boolean,
  ) => {
    setBookmarkSetting({
      ...bookmarkSetting,
      open: true,
      bookmarkId: bookmark_id,
      isPrivate: is_private,
      folderName: title,
      editState: true,
    });
  };

  const onBookmarkDelete = (confirmBookmarkId: string) => {
    setModalState(true);
    setBookmarkId(confirmBookmarkId);
  };

  const [bookmarkRequestData, setBookmarkRequestData] =
    useState<IRequestBookmarkListData>({
      page: 1,
      size: 10,
    });

  const getBookmarkList = async (page: number) => {
    const response = await getBookmarkListAPI(
      updateLoading,
      author_id,
      { ...bookmarkRequestData, page },
      user_role,
    );

    return {
      list: response.list,
      nextPage: response.page < response.pages ? response.page + 1 : undefined,
    };
  };

  const {
    data: infiniteData,
    loadMore,
    loading,
    reload,
  } = useInfiniteScroll((d) => getBookmarkList(d ? d.nextPage : 1), {
    target: containerRef,
    isNoMore: (d) => !d?.nextPage,
    reloadDeps: [handleChangeState],
  });

  return (
    <Row>
      {bookmarkState ? (
        <BookmarkDetail
          containerRef={containerRef}
          bookmarkInfo={bookmarkInfo}
          setBookmarkState={setBookmarkState}
        />
      ) : infiniteData?.list?.length === 0 ? (
        <Col className="flex flex-col justify-center items-center w-full h-full">
          <Icon className="text-[200px]" component={NoDataIcon} />
          <Typography.Text className="text-[#686868] text-base">
            No Data
          </Typography.Text>
        </Col>
      ) : (
        <Row className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 w-full">
          {infiniteData?.list?.map((bookmark) =>
            userId !== author_id && bookmark.is_private ? null : (
              <Col
                className="w-full rounded-sm bg-[#232529] flex flex-col p-5"
                key={bookmark.id}
              >
                <Col className="flex flex-row justify-between w-full">
                  <Col className="flex flex-row flex-1 gap-4">
                    {author_id === userId &&
                      currentRole === user_role &&
                      bookmark.is_private && (
                        <Icon component={LockIcon} className="text-[15px]" />
                      )}
                    <Col className="flex flex-1">
                      <Typography.Paragraph
                        ellipsis={{ rows: 1 }}
                        className="text-white text-base !mb-0 cursor-pointer"
                        onClick={() =>
                          onSelectBookmark(
                            bookmark.id,
                            bookmark.title,
                            bookmark.collection_count,
                          )
                        }
                      >
                        {bookmark.title}
                      </Typography.Paragraph>
                    </Col>
                    {author_id === userId && user_role === currentRole && (
                      <Col className="flex flex-row gap-4 text-[#686868]">
                        <EditOutlined
                          className="text-[15px]"
                          onClick={() =>
                            handleEditBookmark(
                              bookmark.id,
                              bookmark.title,
                              bookmark.is_private,
                            )
                          }
                        />
                        <DeleteOutlined
                          onClick={() => onBookmarkDelete(bookmark.id)}
                          className="text-[15px]"
                        />
                      </Col>
                    )}
                  </Col>
                </Col>
                <Typography.Text className="text-base text-[#686868] pt-1 pb-3">
                  {bookmark.collection_count} Artworks
                </Typography.Text>
                <div className="w-full grid grid-cols-3 gap-4 flex-1">
                  {bookmark.artworks.length >= 3
                    ? bookmark.artworks
                        .slice(0, 3)
                        .map((artworkImage: string) => (
                          <Image
                            key={artworkImage} // Using index as key here, ideally should use unique identifier
                            preview={false}
                            src={getImgUrl(artworkImage) || ""}
                            height="126px"
                            className="w-full object-cover rounded-sm"
                          />
                        ))
                    : bookmark.artworks.map((artworkImage: string) => (
                        <Image
                          key={artworkImage} // Using index as key here, ideally should use unique identifier
                          preview={false}
                          src={getImgUrl(artworkImage) || ""}
                          height="126px"
                          className="w-full object-cover rounded-sm"
                        />
                      ))}
                  {Array.from({ length: 3 - bookmark.artworks.length }).map(
                    () => {
                      const uniqueKey = `${new Date().getTime()}-${Math.random()}`;
                      return (
                        <Space
                          key={uniqueKey}
                          className="bg-[#D9D9D9] rounded-sm h-[126px] flex justify-center items-center"
                        >
                          <Icon component={LeyLineIcon} />
                        </Space>
                      );
                    },
                  )}
                </div>
              </Col>
            ),
          )}
        </Row>
      )}
      <BookmarkDeleteConfirmModal
        bookmarkId={bookmarkId}
        modalState={modalState}
        setModalState={setModalState}
        setHandleChangeState={setHandleChangeState}
      />
      <BookmarkSettingModal
        bookmarkSetting={bookmarkSetting}
        setBookmarkSetting={setBookmarkSetting}
        currentRole={currentRole || "INDIVIDUAL"}
        setHandleChangeState={setHandleChangeState}
      />
    </Row>
  );
};
