import { UserType } from "@/__generated__/graphql";
import {
  CompanyIcon,
  DefaultUserIcon,
} from "@/components/customComponents/CustomIcons";
import { LeyLineIcon } from "@/components/icons";
import { IArtworkItem, ICompany, IUser } from "@/pages/discover/types";
import { getImgUrl } from "@/utils/common";
import { videoExtensions } from "@/utils/identifyFiles";
import Icon, { EyeOutlined, FireFilled } from "@ant-design/icons";
import { Col, Image, Row, Space, Typography } from "antd";
import Avatar from "antd/es/avatar/avatar";
import React from "react";
import { Link } from "react-router-dom";
const videoExtensionsRegex = videoExtensions.join("|");
interface PortfolioItemProps {
  artworkItem: IArtworkItem;
}

const PortfolioItem: React.FC<PortfolioItemProps> = ({ artworkItem }) => {
  return (
    <Row className="flex flex-col pb-2 gap-2 custom-alibaba-font">
      <Col className="relative group w-full h-[230px]">
        <Image
          src={artworkItem.cover_picture}
          preview={false}
          width="100%"
          height="230px"
          className="rounded-xl object-cover"
        />
        {artworkItem.score && artworkItem.score > 20 ? (
          <div className="absolute z-50 top-4 right-4 rounded-full bg-white opacity-70 px-1">
            <FireFilled className="text-[#FF4D4F]" />
          </div>
        ) : null}

        <Row className="rounded-xl object-cover w-full h-full absolute top-0 left-0 transition-opacity duration-300 opacity-0 group-hover:opacity-100 overflow-hidden">
          {artworkItem.medias.length >= 4 && (
            <Row className="rounded-xl bg-black object-cover w-full h-full absolute top-0 left-0 transition-opacity duration-300 opacity-0 group-hover:opacity-100 overflow-hidden gap-[2px]">
              <Col className="h-[50%] w-full flex gap-[2px]">
                {artworkItem.medias.slice(0, 2).map((media) =>
                  media.match(`\\.(?:${videoExtensionsRegex})$`) ? (
                    <div
                      key={media}
                      className="w-[50%] h-full flex justify-center items-center"
                    >
                      <Icon component={LeyLineIcon} />
                    </div>
                  ) : (
                    <Image
                      key={media}
                      preview={false}
                      src={media}
                      width="50%"
                      height="100%"
                    />
                  ),
                )}
              </Col>
              <Col className="h-[50%] w-full flex gap-[2px]">
                {artworkItem.medias.slice(2, 4).map((media) =>
                  media.match(`\\.(?:${videoExtensionsRegex})$`) ? (
                    <div
                      key={media}
                      className="w-[50%] h-full flex justify-center items-center"
                    >
                      <Icon component={LeyLineIcon} />
                    </div>
                  ) : (
                    <Image
                      key={media}
                      preview={false}
                      src={media}
                      width="50%"
                      height="100%"
                    />
                  ),
                )}
              </Col>
            </Row>
          )}
        </Row>
        <div
          style={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)",
          }}
          className="absolute inset-0 bg-opacity-75 flex items-end opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-xl"
        >
          <div className="text-base w-full text-white px-4 pb-4 flex flex-row items-center justify-between flex-1 gap-2">
            <div className="flex gap-2 items-center w-full relative">
              <Link
                to={`/discover/author/${artworkItem.user_id}?role=${artworkItem.role}`}
              >
                <div className="relative">
                  <Avatar
                    className={`min-w-[38px] min-h-[38px] object-cover ${
                      artworkItem.role === UserType.Company &&
                      "border-2 border-solid border-[#03B3B4]"
                    }`}
                    src={
                      getImgUrl(
                        artworkItem.role === UserType.Individual
                          ? (artworkItem?.author as IUser).avatar_url
                          : (artworkItem?.author as ICompany).logo_url,
                      ) || <DefaultUserIcon />
                    }
                  />
                  {artworkItem.role === UserType.Company && (
                    <Space className="absolute z-10 left-1/2 top-[-15%] transform -translate-x-1/2">
                      <CompanyIcon width="16px" height="16px" />
                    </Space>
                  )}
                </div>
              </Link>
              <Typography.Text className="text-base text-white overflow-hidden overflow-ellipsis whitespace-nowrap custom-alibaba-font">
                {artworkItem.role === UserType.Individual
                  ? (artworkItem.author as IUser).user_name
                  : (artworkItem.author as ICompany).shortname}
              </Typography.Text>
              <div className="w-auto flex flex-row items-center gap-1.5 flex-1 justify-end">
                <EyeOutlined className="opacity-60 text-white" />
                <Typography.Text className="opacity-60 text-base text-white whitespace-nowrap custom-alibaba-font">
                  {artworkItem.visited_count}
                </Typography.Text>
              </div>
            </div>
          </div>
        </div>
      </Col>

      <Col>
        <Typography.Paragraph
          className="text-base text-[#fff9]"
          ellipsis={{
            rows: 2,
          }}
        >
          {artworkItem.title} {artworkItem.description.replace(/\n/g, " ")}
        </Typography.Paragraph>
      </Col>
    </Row>
  );
};

export default PortfolioItem;
