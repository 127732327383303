import { formatToMiddleStyle } from "@/utils/date";
import { EnvironmentOutlined } from "@ant-design/icons";
import { useApiUrl } from "@refinedev/core";
import {
  Avatar,
  Card,
  Col,
  Divider,
  Drawer,
  Flex,
  Image,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { IUserInfo } from "../../../interfaces";
import { padLeftImgUrl } from "../../../utils/common";
import { httpGet } from "../../../utils/http";

interface Props {
  open: boolean;
  userId: string;
  onCancel: () => void;
}

const { TabPane } = Tabs;
const { Title, Text } = Typography;
const defaultAvatar = "https://placehold.jp/150x150.png";
const defaultCover =
  "https://placehold.jp/c2bcbc/ffffff/1600x140.png?text=PageCover";

const ResumeDetailDrawer = ({ open, userId, onCancel }: Props) => {
  const [resumeData, setResumeData] = useState<IUserInfo>();
  const apiUrl = useApiUrl();

  const getResumeData = useCallback(async () => {
    if (!userId) return;
    const res = await httpGet(`${apiUrl}/users/${userId}/resume`);
    console.log("resume res data:", res);
    if (res.status === 200) setResumeData(res.data);
  }, [apiUrl, userId]);

  useEffect(() => {
    open && getResumeData();
  }, [open, getResumeData]);

  const { profile, work_preference, work_experience, project_experience } =
    resumeData || {};

  return (
    <Drawer
      open={open}
      title="The resume detail"
      onClose={onCancel}
      placement="right"
      closable
      width={600}
      maskClosable={true}
    >
      {open && (
        <>
          {!resumeData ? (
            <div>Loading...</div>
          ) : (
            <>
              <div
                style={{
                  backgroundImage: `url(${
                    padLeftImgUrl(profile?.page_cover_url) || defaultCover
                  })`,
                  aspectRatio: 5 / 1,
                  backgroundSize: "100% 100%",
                  padding: "20px",
                  color: "white",
                }}
              >
                <Row align="middle">
                  <Col span={2} />
                  <Col flex="0 1 100px">
                    <Avatar
                      size={64}
                      src={padLeftImgUrl(profile?.avatar_url) || defaultAvatar}
                    />
                  </Col>
                  <Col flex="1 1 100px">
                    <Space direction="vertical">
                      <Title level={4} style={{ color: "white" }}>
                        {profile?.user_name}
                      </Title>
                      {profile?.industry && (
                        <Text style={{ color: "white" }}>
                          {profile?.industry}
                        </Text>
                      )}
                      {profile?.introduction && (
                        <Text style={{ color: "white" }}>
                          {profile?.introduction}
                        </Text>
                      )}
                      {profile?.country ||
                        (profile?.city && (
                          <Text style={{ color: "white" }}>
                            {profile?.country} {profile?.city}
                            <EnvironmentOutlined />
                          </Text>
                        ))}
                    </Space>
                  </Col>
                </Row>
              </div>
              <div style={{ marginTop: "12px" }}>
                <Card size="small">
                  <Row gutter={24}>
                    <Col span={24}>
                      <Divider orientation="left" orientationMargin="0">
                        Professional Summary
                      </Divider>
                      <Flex align="start">
                        <Text>{profile?.summary}</Text>
                      </Flex>
                      <Divider orientation="left" orientationMargin="0">
                        Skills
                      </Divider>
                      <Flex align="start">
                        {work_preference?.skills?.map((tag, index) => {
                          return (
                            <Tag key={tag} color="blue">
                              {tag}
                            </Tag>
                          );
                        })}
                      </Flex>
                      <Divider orientation="left" orientationMargin="0">
                        Softwares
                      </Divider>

                      <Divider orientation="left" orientationMargin="0">
                        Project Experience
                      </Divider>
                      {project_experience?.map((proj) => (
                        <Card
                          key={proj.id}
                          className="mb-3"
                          bodyStyle={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            padding: "8px",
                            borderRadius: "8px",
                            backgroundColor: "#0000ff30",
                          }}
                        >
                          <Image
                            alt="project cover"
                            src={padLeftImgUrl(proj.cover_url)}
                            width={100}
                            height={100}
                            className="object-cover mr-5"
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              flex: 1,
                              paddingLeft: "16px",
                            }}
                          >
                            <Text strong style={{ marginBottom: 4 }}>
                              {proj.name}
                            </Text>
                            <Text style={{ marginBottom: 4 }}>{proj.role}</Text>
                            <Text type="secondary">
                              {formatToMiddleStyle(proj.start_date)} -{" "}
                              {proj.end_date
                                ? formatToMiddleStyle(proj.end_date)
                                : "Present"}
                            </Text>

                            <Text type="secondary">{proj.description}</Text>
                          </div>
                        </Card>
                      ))}

                      <Divider orientation="left" orientationMargin="0">
                        Work Experience
                      </Divider>
                      {work_experience?.map((work) => (
                        <Card
                          key={work.id}
                          className="mb-3"
                          bodyStyle={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            padding: "16px",
                            borderRadius: "8px",
                            backgroundColor: "#0000ff30",
                          }}
                        >
                          <div style={{ marginBottom: 8 }}>
                            <Text
                              strong
                              style={{ fontSize: "16px", marginRight: 8 }}
                            >
                              {work.title}
                            </Text>
                            <Text style={{ fontSize: "14px", marginRight: 8 }}>
                              {work.department_name}
                            </Text>
                            <Text style={{ fontSize: "14px" }}>
                              {work.city}
                            </Text>
                            ,{" "}
                            <Text style={{ fontSize: "14px", marginRight: 8 }}>
                              {work.country}
                            </Text>
                          </div>
                          <div style={{ marginBottom: 8 }}>
                            <Text strong style={{ fontSize: "15px" }}>
                              {work.company_name}
                            </Text>
                          </div>
                          <div style={{ marginBottom: 8 }}>
                            <Text type="secondary">
                              {formatToMiddleStyle(work.start_date)} -{" "}
                              {work.end_date
                                ? formatToMiddleStyle(work.end_date)
                                : "Present"}
                            </Text>
                          </div>
                          <Text type="secondary">{work.description}</Text>
                        </Card>
                      ))}
                    </Col>
                  </Row>
                </Card>
              </div>
            </>
          )}
        </>
      )}
    </Drawer>
  );
};

export default ResumeDetailDrawer;
