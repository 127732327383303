import {
  UserType,
  useSetRemoteCustomDataConversationMutation,
} from "@/__generated__/graphql";
import { CustomButton, CustomTag } from "@/components/customComponents";
import LeyIcon from "@/components/leylineIcons";
import ApplyJobDrawer from "@/components/users/components/ApplyJobDrawer";
import { JobInfoProvider } from "@/contexts/jobInfo";
import { useUserData } from "@/hooks";
import Applicants from "@/pages/enterprises/components/Applicants";
import CompanyInfoCard from "@/pages/enterprises/components/CompanyInfoCard";
import ProjectStatusTag from "@/pages/enterprises/components/ProjectStatusTag";
import ShowFileOrLink from "@/pages/enterprises/components/ShowFileOrLink";
import {
  checkApplyForJob,
  getApplications,
  getJobDetail,
} from "@/pages/enterprises/services";
import { ApplyInfoType, judgeIsMyself } from "@/pages/enterprises/utils";
import useGlobalStore from "@/store";
import { formatToMiddleStyle } from "@/utils/date";
import { ShortUserType, jumpChatPage } from "@/utils/imUtils";
import { CloseOutlined, ShareAltOutlined } from "@ant-design/icons";
import { useRequest } from "ahooks";
import {
  Col,
  Divider,
  Image,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
  message,
} from "antd";
import { FC, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;
const { Title, Text, Link } = Typography;

interface DrawerRefType {
  open: (info?: ApplyInfoType) => void;
}
interface Props {
  id: string;
  onClose: () => void;
}
const JobDetail: FC<Props> = ({ id, onClose }) => {
  const applyJobRef = useRef<DrawerRefType>();
  const { currentRole, chatClient } = useGlobalStore((state) => state);
  const profile = useUserData();
  const [setRemoteCustomDataMutation] =
    useSetRemoteCustomDataConversationMutation();
  const navigate = useNavigate();

  const { data, loading } = useRequest(() => getJobDetail(id), {
    manual: false,
    ready: !!id,
    refreshDeps: [id],
    onSuccess(res) {
      sessionStorage.setItem("jobtouserid", res.user_id);
      sessionStorage.setItem("jobtoprojectid", res.project_id);
      sessionStorage.setItem("jobtocompanyid", res.company_id);
    },
  });

  const { data: applicationsData } = useRequest(
    () => getApplications({ job_id: id, page: 1, size: 10 }),
    {
      manual: false,
      ready: !!id,
      refreshDeps: [id],
    },
  );

  const { loading: checkLoading, run: checkApplyApi } = useRequest(
    () =>
      checkApplyForJob({
        job_id: id,
        role: ShortUserType[currentRole as UserType],
      }),
    {
      manual: true,
      ready: !!id && !!currentRole,
      refreshDeps: [id, currentRole],
      onSuccess() {
        sessionStorage.setItem("currentJobIdToApply", id);
        applyJobRef.current?.open();
      },
      onError(err) {
        // @ts-ignore
        message.error(err.response?.data?.detail || "");
      },
    },
  );

  const fnApplyNow = () => {
    if (!profile) return navigate("/login");
    checkApplyApi();
  };

  const fnShare = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/mktp/enterprises/public-job-details/${id}`,
    );
    message.success("Link copied successfully");
  };

  const companyInfo = useMemo(() => {
    return {
      company_business_fields: data?.company_business_fields,
      company_country: data?.company_country,
      company_industry: data?.company_industry,
      company_logo_url: data?.company_logo_url,
      company_scale: data?.company_scale,
      company_shortname: data?.company_shortname,
      is_company_verified: data?.is_company_verified,
    };
  }, [data]);

  const replaceLinks = (text: string) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlPattern, (url) => {
      return `<a style="color: #00ADAE" href="${url}" target="_blank">${url}</a>`;
    });
  };

  const isMyJob = useMemo(() => judgeIsMyself(data?.user_id), [data?.user_id]);

  return (
    <div className="flex flex-1 h-full bg-[#333538] rounded-xl overflow-hidden">
      <div className="h-full flex-1 overflow-auto px-[60px] py-12">
        <Spin spinning={loading} className=" absolute top-1/2 left-1/2" />
        <Title className="!text-white text-3xl !font-normal">
          {data?.title}
        </Title>
        <div className="text-[#999] text-base mb-5">Skills and Requirement</div>

        <div
          className="text-white whitespace-pre-wrap"
          // biome-ignore lint/security/noDangerouslySetInnerHtmlWithChildren: <explanation>
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{
            __html: data?.description ? replaceLinks(data?.description) : "",
          }}
        >
          {/*{data?.description ? replaceLinks(data?.description) : ''}*/}
        </div>

        <div className="text-center flex justify-between items-center mt-10">
          <div>
            <div className="text-[#999]">Location</div>
            <Text className="text-white/[.8]">{data?.location || "--"}</Text>
          </div>
          <Divider
            type="vertical"
            style={{ borderColor: "rgba(255, 255, 255, .1)" }}
          />
          <div>
            <div className="text-[#999]">Work Model</div>
            <Text className="text-white/[.8]">{data?.remote_option}</Text>
          </div>
          <Divider
            type="vertical"
            style={{ borderColor: "rgba(255, 255, 255, .1)" }}
          />
          <div>
            <div className="text-[#999]">Job Type</div>
            <Text className="text-white/[.8]">{data?.employment_type}</Text>
          </div>
          <Divider
            type="vertical"
            style={{ borderColor: "rgba(255, 255, 255, .1)" }}
          />
          <div>
            <div className="text-[#999]">Create Date (Local Time)</div>
            <Text className="text-white/[.8]">
              {formatToMiddleStyle(data?.created_at)}
            </Text>
          </div>
        </div>
        <Divider style={{ borderColor: "rgba(255, 255, 255, .1)" }} />
        <Title level={5} className="!text-[#999] !mb-6">
          Software
        </Title>
        <div>
          {data?.software
            // @ts-ignore
            ?.filter((v) => v.trim().length > 0)
            // @ts-ignore
            ?.map((v) => {
              return <CustomTag key={v} className="mr-2 mb-2" text={v} />;
            })}
        </div>
        <Divider style={{ borderColor: "rgba(255, 255, 255, .1)" }} />
        <Title level={5} className="!text-[#999] !mb-6">
          Benchmark
        </Title>
        <ShowFileOrLink {...data?.benchmark} />
        <Divider style={{ borderColor: "rgba(255, 255, 255, .1)" }} />
        <Title level={5} className="!text-[#999] !mb-6">
          Test
        </Title>
        <ShowFileOrLink {...data?.test} />
      </div>
      {/* Right Section */}
      <div className="w-[540px] shrink-0 h-full bg-white/[.03] pt-[50px] relative">
        <span
          className="p-4 cursor-pointer absolute right-0 top-0 text-[#999]"
          onClick={onClose}
          onKeyUp={() => {}}
        >
          <CloseOutlined />
        </span>
        {/* 120 = footer part height */}
        <div
          className={`px-[50px] ${
            isMyJob ? "h-[calc(100%-50px)]" : "h-[calc(100%-120px)]"
          } overflow-auto`}
        >
          <Image
            rootClassName="w-full block "
            className="rounded-md mb-7 object-cover"
            preview={false}
            src={IMG_PREFIX + data?.project_thumbnail_url}
          />
          <Title level={3} className="!text-white !font-normal">
            {data?.project_name}
          </Title>
          <div className="flex justify-between mb-3">
            <Text className="text-[#999]">
              {formatToMiddleStyle(data?.project_start_date)}&nbsp;to&nbsp;
              {formatToMiddleStyle(data?.project_end_date)}
            </Text>
            <ProjectStatusTag status={data?.progress_status} />
          </div>
          <Text className="text-[#999]">{data?.project_description}</Text>
          <Divider style={{ borderColor: "rgba(255, 255, 255, .1)" }} />
          <CompanyInfoCard companyInfo={companyInfo} />
          {applicationsData?.total > 0 && (
            <>
              <Divider style={{ borderColor: "rgba(255, 255, 255, .1)" }} />
              <Title level={3} className="!text-white !font-normal">
                Applicants For This Job
              </Title>
              <Applicants data={applicationsData} />
            </>
          )}
        </div>
        {!isMyJob && (
          <div className="flex justify-center px-12 py-10 bg-white/[.03]">
            <CustomButton
              size="large"
              icon={<LeyIcon icon="Msg" style={{ fontSize: 18 }} />}
              ghost
              type="primary"
              className="mr-4 align-middle"
              onClick={() => {
                if (!profile) return navigate("/login");
                jumpChatPage(
                  { ...data, job_title: data.title, job_id: id },
                  chatClient,
                  setRemoteCustomDataMutation,
                  navigate,
                  1,
                );
              }}
            >
              Send Message
            </CustomButton>
            <CustomButton
              className="mr-4"
              size="large"
              icon={<LeyIcon icon="Edit" style={{ fontSize: 18 }} />}
              type="primary"
              onClick={fnApplyNow}
              loading={checkLoading}
            >
              Apply Now
            </CustomButton>
            <CustomButton
              size="large"
              icon={<ShareAltOutlined style={{ fontSize: 18 }} />}
              type="primary"
              onClick={fnShare}
            />
          </div>
        )}
        <JobInfoProvider
          jobData={{ ...data, job_id: id, job_title: data?.title }}
        >
          <ApplyJobDrawer ref={applyJobRef} refresh={() => {}} />
        </JobInfoProvider>
      </div>
    </div>
  );
};

export default JobDetail;
