import { CustomButton } from "@/components/customComponents";
import { isImageFile, isVideoFile } from "@/utils/upload";
import { UploadOutlined } from "@ant-design/icons";
import { useApiUrl } from "@refinedev/core";
import {
  Button,
  ConfigProvider,
  Input,
  InputProps,
  Upload,
  message,
} from "antd";
import { UploadProps } from "antd/es/upload";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles/upload.module.css";

const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;
type OmitUploadProps = Omit<UploadProps, "onChange">;

interface CustomUploadProps extends OmitUploadProps {
  onChange?: (e: UploadFile[]) => void;
}

export const CustomUpload = ({
  fileList,
  onChange,
  ...props
}: CustomUploadProps) => {
  const apiUrl = useApiUrl();
  const [tempFileList, setTempFileList] = useState<UploadFile[]>([]);
  const themeConfig = useContext(ConfigProvider.ConfigContext);

  useEffect(() => {
    setTempFileList(fileList ?? []);
  }, [fileList]);

  const beforeUpload = (file: RcFile) => {
    const isOver10M = file.size / 1024 / 1024 > 10;
    if (isOver10M) {
      message.warning("File must smaller than 10MB!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const previewFile = (file: UploadFile, isImage: boolean) => {
    if (!file || !file.originFileObj) return;
    const src = file.url || URL.createObjectURL(file.originFileObj as Blob);
    if (isImage) {
      const imgWindow = window.open(src);
      if (imgWindow) {
        imgWindow.document.write(`<img src="${src}" style="width: 100%;">`);
      }
    } else if (isVideoFile(file)) {
      const videoWindow = window.open(src);
      if (videoWindow) {
        videoWindow.document.write(
          `<video src="${src}" controls style="width: 100%;"></video>`,
        );
      }
    }
  };

  return (
    <ConfigProvider
      theme={
        {
          // token: {
          //   colorTextPlaceholder: "#686868",
          // },
        }
      }
    >
      <Upload
        rootClassName={
          // @ts-ignore
          themeConfig?.theme?.mode === "dark" ? styles.uploadWrapper : ""
        }
        action={`${apiUrl}/users/upload`}
        headers={{
          // biome-ignore lint:
          authorization: "Bearer " + localStorage.getItem("leyline-auth"),
        }}
        name="file"
        multiple={false}
        fileList={tempFileList}
        listType="picture"
        maxCount={1}
        beforeUpload={beforeUpload}
        onChange={({ fileList }) => {
          const newFileList = fileList.map((file) => {
            return {
              ...file,
              url: IMG_PREFIX + (file.url ? file.url : file.response?.id),
            };
          });
          setTempFileList(newFileList);
          onChange?.(newFileList);
        }}
        onPreview={async (file) => {
          previewFile(file, isImageFile(file));
        }}
        {...props}
      >
        <CustomButton icon={<UploadOutlined />}>Click to Upload</CustomButton>
      </Upload>
    </ConfigProvider>
  );
};
