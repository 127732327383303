import { NoDataIcon } from "@/components/customComponents/CustomIcons";
import PortfolioItem from "@/components/discover/homepage/PortfolioItem";
import { getCollectionArtsAPI } from "@/pages/discover/api";
import { IRequestFavoriteArtsData } from "@/pages/discover/types";
import useGlobalStore from "@/store";
import { artworkListLocalStorage } from "@/utils/artworkListLocalStorage";
import Icon from "@ant-design/icons";
import { useInfiniteScroll } from "ahooks";
import { Col, Row, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

interface ICollectionArtworksProps {
  containerRef: React.RefObject<HTMLDivElement>;
  requestData: IRequestFavoriteArtsData;
  user_role: string;
}

export const CollectionArtworks: React.FC<ICollectionArtworksProps> = ({
  containerRef,
  requestData,
  user_role,
}) => {
  const { updateLoading } = useGlobalStore((state) => ({
    updateLoading: state.updateLoading,
  }));

  const getCollectionArts = async (page: number) => {
    const response = await getCollectionArtsAPI(
      updateLoading,
      { ...requestData, page },
      user_role,
    );
    artworkListLocalStorage(response);
    return {
      list: response.list,
      nextPage: response.page < response.pages ? response.page + 1 : undefined,
    };
  };

  const {
    data: infiniteData,
    loadMore,
    loading,
    reload,
  } = useInfiniteScroll((d) => getCollectionArts(d ? d.nextPage : 1), {
    target: containerRef,
    isNoMore: (d) => !d?.nextPage,
    reloadDeps: [requestData],
  });

  return (
    <Row>
      {infiniteData?.list.length === 0 ? (
        <Col className="flex flex-col justify-center items-center w-full h-full">
          <Icon className="text-[200px]" component={NoDataIcon} />
          <Typography.Text className="text-[#686868] text-base">
            No Data
          </Typography.Text>
        </Col>
      ) : (
        <Row className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
          {infiniteData?.list.map((artwork) => (
            <Link to={`/discover/portfolio/${artwork.id}`} key={artwork.id}>
              <PortfolioItem artworkItem={artwork} />
            </Link>
          ))}
        </Row>
      )}
    </Row>
  );
};
