import { LeyLineIcon } from "@/components/icons";
import Icon, {
  EditOutlined,
  HomeOutlined,
  MessageOutlined,
  SearchOutlined,
  SoundOutlined,
} from "@ant-design/icons";
// @ts-ignore
import { decodeText } from "@leyline/chat-uikit-react/dist/cjs/components/TUIMessage/utils/decodeText";
import { useMenu, useParsed, useTranslate } from "@refinedev/core";
import TencentCloudChat, { Message } from "@tencentcloud/chat";
import { TUILogin } from "@tencentcloud/tui-core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Avatar,
  Badge,
  Button,
  ConfigProvider,
  Divider,
  Dropdown,
  Image,
  Layout as AntdLayout,
  Menu,
  MenuProps,
  Popover,
  Row,
  Space,
  Typography,
  message,
  notification,
  theme,
} from "antd";

import styles from "./index.module.css";

import { useTranslation } from "react-i18next";

import {
  CompanyIcon,
  DefaultUserIcon,
  RingIcon,
} from "@/components/customComponents/CustomIcons";
import { CustomInput } from "@/components/customComponents/CustomInput";
import { CustomMenus } from "@/components/customComponents/CustomMenus";
import NavigationList from "@/components/header/components/NavigationList";
import Promotions from "@/components/header/components/Promotions";
import menuData, {
  openKeys,
  renderFooterMenu,
} from "@/components/sider/menuData";
import { renderMenuTreeView, useMyLogout } from "@/components/sider/utils";
import { NotificationDrawer } from "@/components/users/components/NotificationDrawer";
import { PRIVACY_POLICY_URL, USER_AGREEMENT_URL } from "@/constants";
import themeConfig from "@/constants/themeConfig";
import { getTotalNotificationUnreadCountAPI } from "@/pages/discover/api";
import { judgeIsCompanyRole, jumpChatPageByMsg } from "@/utils/imUtils";
import { UserType, useImportImUserMutation } from "../../__generated__/graphql";
import { useHasCompanyRole, useUserData } from "../../hooks";
import useGlobalStore from "../../store";
import { getImgUrl, padLeftImgUrl } from "../../utils/common";
import { handleDefaultRole, updateLocalRole } from "./utils";

const { Header: AntdHeader } = AntdLayout;
const { TOTAL_UNREAD_MESSAGE_COUNT_UPDATED, MESSAGE_RECEIVED } =
  TencentCloudChat.EVENT;

interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
}

interface IOptions {
  label: string | React.ReactNode;
  options: IOptionGroup[];
}
const IM_SDK_APP_ID = import.meta.env.VITE_IM_SDK_APP_ID;

const initialConfig = {
  [UserType.Individual]: 0,
  [UserType.Company]: 0,
};

export const Header: React.FC = () => {
  const [unreadCount, setUnreadCount] = useState(0);
  const navigate = useNavigate();
  const profile = useUserData();
  const {
    currentRole,
    chatClient,
    updateCurrentRole,
    updateChatClient,
    updateArtworkSearch,
  } = useGlobalStore((state) => state);
  const [notifVisibleState, setNotifVisibleState] = useState(false);
  const isCompanyRole = judgeIsCompanyRole(currentRole);
  const [importImUser] = useImportImUserMutation();
  const { defaultOpenKeys } = useMenu({});
  const [selectedKey, setSelectedKey] = useState<string[]>([]);
  const [unreadTotalCount, setUnreadTotalCount] = useState(0);
  const t = useTranslate();
  const handleLogout = useMyLogout();

  const { companyInfo, hasEmployerView } = useHasCompanyRole(isCompanyRole);

  const { pathname } = useParsed();
  const isChatPath = pathname?.includes("/enterprises/chat");
  const isSearchPath =
    pathname === "/" || pathname?.includes("/discover/portfolio");

  const setArtworkSearch = (value: string) => {
    updateArtworkSearch(value);
    navigate("/");
  };
  const getUnreadCount = useCallback(async () => {
    if (!currentRole || !profile) return;
    const response = await getTotalNotificationUnreadCountAPI(currentRole);
    const unreadTotalCount = response.total;
    setUnreadTotalCount(unreadTotalCount);
  }, [currentRole, profile]);

  useEffect(() => {
    getUnreadCount();
    const interval = setInterval(getUnreadCount, 10000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [getUnreadCount]);

  useEffect(() => {
    pathname && setSelectedKey([pathname]);
  }, [pathname]);

  // const renderTitle = (title: string) => (
  //   <HeaderTitle>
  //     <Text className="text-base">{title}</Text>
  //     <Link to={`/${title.toLowerCase()}`}>{t("search.more")}</Link>
  //   </HeaderTitle>
  // );

  // const renderItem = (title: string, imageUrl: string, link: string) => ({
  //   value: title,
  //   label: (
  //     <Link to={link} className="flex items-center">
  //       <Avatar size={64} src={imageUrl} className="m-w-16" />
  //       <Text className="ml-4">{title}</Text>
  //     </Link>
  //   ),
  // });

  // const menuItems: MenuProps["items"] = [...(i18n.languages || [])]
  //   .sort()
  //   .map((lang: string) => ({
  //     key: lang,
  //     onClick: () => changeLanguage(lang),
  //     icon: (
  //       <span style={{ marginRight: 8 }}>
  //         <Avatar size={16} src={`images/flags/${lang}.svg`} />
  //       </span>
  //     ),
  //     label: lang === "en" ? "English " : "简体中文",
  //   }));
  const renderAvatar = useCallback(() => {
    return (
      <div className="relative">
        <Avatar
          className={`min-w-[38px] min-h-[38px] object-cover ${
            isCompanyRole && "border-2 border-solid border-[#03B3B4]"
          }`}
          src={
            getImgUrl(
              isCompanyRole
                ? companyInfo?.extended_info?.logo_url
                : profile?.avatar_url,
            ) || <DefaultUserIcon />
          }
          alt={
            (isCompanyRole
              ? companyInfo?.extended_info?.name
              : profile?.user_name) || ""
          }
        />
        {isCompanyRole && (
          <Space className="absolute z-10 left-1/2 top-[-18%] transform -translate-x-1/2">
            <CompanyIcon width="16px" height="16px" />
          </Space>
        )}
      </div>
    );
  }, [isCompanyRole, companyInfo, profile]);

  const switchRole = useCallback(() => {
    if (!isCompanyRole && !hasEmployerView)
      return message.warning("You have not passed enterprise certification.");
    const newRole = isCompanyRole ? UserType.Individual : UserType.Company;
    updateCurrentRole(newRole);
    updateLocalRole(newRole);
  }, [isCompanyRole, hasEmployerView, updateCurrentRole]);

  const goToHome = useCallback(() => {
    navigate(isCompanyRole ? "/companies" : "/users/profile");
  }, [navigate, isCompanyRole]);

  const editInfo = useCallback(() => {
    navigate(isCompanyRole ? "/companies/edit-homepage" : "/users/edit");
  }, [navigate, isCompanyRole]);

  const menuContent = useMemo(
    () => (
      <div className={`w-[240px] ${styles.menuContent}`}>
        <div className="flex px-6 pt-3 mb-3 items-center">
          {renderAvatar()}
          <Typography.Paragraph
            ellipsis={{ rows: 1 }}
            className="!mb-0 ml-3 text-white"
          >
            {isCompanyRole
              ? companyInfo?.extended_info?.name
              : profile?.user_name || ""}
          </Typography.Paragraph>
        </div>
        <div className="flex justify-between px-3 pb-4 border-b-[1px] border-solid border-gray-700">
          <span
            className="text-gray-400 hover:text-white text-xs cursor-pointer"
            onClick={editInfo}
            onKeyUp={() => {}}
          >
            <EditOutlined className="mr-2" />
            <span>{isCompanyRole ? "Edit Introduction" : "Edit Resume"}</span>
          </span>
          <span className="text-gray-500 text-xs">|</span>
          <span
            className="text-gray-400 hover:text-white text-xs cursor-pointer"
            onClick={goToHome}
            onKeyUp={() => {}}
          >
            <HomeOutlined className="cursor-pointer mr-2" />
            <span>Homepage</span>
          </span>
        </div>
        <CustomMenus
          selectedKeys={selectedKey}
          openKeys={openKeys}
          defaultOpenKeys={defaultOpenKeys}
          mode="inline"
          onSelect={(e) => {
            if (e.key === "/logout") {
              handleLogout();
            } else if (e.key === "switchRole") {
              switchRole();
            } else if (e.key === "userAgreement") {
              window.open(USER_AGREEMENT_URL, "_blank");
            } else if (e.key === "privacyPolicy") {
              window.open(PRIVACY_POLICY_URL, "_blank");
            } else {
              setSelectedKey(e.selectedKeys);
              navigate(e.key);
            }
          }}
          className="bg-transparent"
          items={[...menuData, ...renderFooterMenu(isCompanyRole)]}
        />
      </div>
    ),
    [
      selectedKey,
      defaultOpenKeys,
      navigate,
      handleLogout,
      renderAvatar,
      isCompanyRole,
      companyInfo,
      profile,
      goToHome,
      editInfo,
      switchRole,
    ],
  );

  const onClickNotification = useCallback(
    (msg: Message) => {
      jumpChatPageByMsg(msg, navigate, chatClient);
    },
    [navigate, chatClient],
  );

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const handleMsg = useCallback((msgPayload: any) => {
    const atomMsgList = decodeText(msgPayload);
    return (
      <>
        {/* biome-ignore lint/suspicious/noExplicitAny: <explanation> */}
        {atomMsgList.map((item: any) => {
          switch (item.name) {
            case "img":
              return <Image src={item.src} width={20} height={20} />;
            default:
              return <span>{item.text}</span>;
          }
        })}
      </>
    );
  }, []);

  const init = async (curRole: UserType) => {
    const handledUrl = padLeftImgUrl(companyInfo?.extended_info?.logo_url);
    const res = await importImUser({
      variables: {
        getUserSigIn: {
          role: [UserType.Company, UserType.Applicantcompany].includes(curRole)
            ? UserType.Company
            : UserType.Individual,
          companyName: companyInfo?.extended_info?.shortname,
          companyLogo: handledUrl,
        },
      },
    });
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const loginInfo: any = {
      SDKAppID: IM_SDK_APP_ID,
      userID: res.data?.importImUser?.imId,
      userSig: res.data?.importImUser?.userSig,
      useUploadPlugin: false,
    };
    const { chat } = TUILogin.getContext();
    if (chat) {
      await chat.logout();
      chat.login({ userID: loginInfo.userID, userSig: loginInfo.userSig });
    } else {
      await TUILogin.login(loginInfo);
      const { chat } = TUILogin.getContext();
      updateChatClient(chat);
    }
  };

  // biome-ignore lint:
  useEffect(() => {
    if (
      currentRole &&
      (currentRole === UserType.Individual ||
        (currentRole === UserType.Company && !!companyInfo))
    )
      init(currentRole);
  }, [currentRole, companyInfo]);

  useEffect(() => {
    const defaultRole = handleDefaultRole();
    // @ts-ignore
    updateCurrentRole(defaultRole);
  }, [updateCurrentRole]);

  useEffect(() => {
    if (!chatClient || !currentRole) return;
    // switch role to reset unreadCount
    setUnreadCount(0);
    const totalUnreadMsgHandler = (e: { data: number }) => {
      setUnreadCount(e.data);
    };
    const receivedMsgHandler = (e: { data: Message[] }) => {
      if (isChatPath) return;
      // biome-ignore lint/complexity/noForEach: <explanation>
      e.data.forEach((msg: Message) => {
        notification.open({
          message: null,
          description: (
            <div>
              <div>
                <Image src={msg.avatar} width={24} height={24} />
                <span className="ml-2">{msg.nick}</span>:
              </div>
              <div className="text-gray-500">{handleMsg(msg.payload)}</div>
            </div>
          ),
          onClick: () => onClickNotification(msg),
          style: { minWidth: 240, maxWidth: 400, width: "max-content" },
          placement: "bottomRight",
        });
      });
    };
    chatClient.on(TOTAL_UNREAD_MESSAGE_COUNT_UPDATED, totalUnreadMsgHandler);
    chatClient.on(MESSAGE_RECEIVED, receivedMsgHandler);
    return () => {
      chatClient.off(TOTAL_UNREAD_MESSAGE_COUNT_UPDATED, totalUnreadMsgHandler);
      chatClient.off(MESSAGE_RECEIVED, receivedMsgHandler);
    };
  }, [chatClient, currentRole, onClickNotification, handleMsg, isChatPath]);

  return (
    <ConfigProvider theme={themeConfig}>
      <AntdHeader className="bg-[#232529] sticky top-0 h-20 px-6 z-[1001] border-b-[1px] border-[#000] border-solid">
        <Row align="middle" justify={"space-between"}>
          <Space size="middle" className="h-20 gap-[26px]">
            <Link to="/">
              <div className="flex items-center">
                <LeyLineIcon />
              </div>
            </Link>
            <NavigationList />
          </Space>
          <Space>
            {profile ? (
              <Space size="middle" align="center">
                <Space size="middle" className="flex items-center">
                  {isSearchPath && (
                    <CustomInput
                      roundRadius
                      size="large"
                      placeholder="Search"
                      prefix={<SearchOutlined style={{ color: "#686868" }} />}
                      className={
                        "text-base  w-[200px] sm:w-[200px] lg:w-[300px] 2xl:w-[500px]"
                      }
                      onChange={(e) => setArtworkSearch(e.target.value)}
                    />
                  )}
                  <Promotions />
                  <Dropdown
                    placement="topCenter"
                    menu={{
                      items: [
                        {
                          key: "msg",
                          icon: <MessageOutlined />,
                          label: (
                            <Link to="/enterprises/chat">
                              Message
                              <Badge
                                size="small"
                                className="ml-2"
                                count={unreadCount}
                              />
                            </Link>
                          ),
                        },
                        {
                          key: "ntf",
                          icon: <SoundOutlined />,
                          label: (
                            <Space onClick={() => setNotifVisibleState(true)}>
                              Notification
                              <Badge
                                size="small"
                                className="ml-2"
                                count={unreadTotalCount}
                              />
                            </Space>
                          ),
                        },
                      ],
                    }}
                  >
                    <div className="flex items-center justify-center text-white hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-white">
                      <Badge dot={!!unreadCount || !!unreadTotalCount}>
                        <Icon
                          className="text-xl cursor-pointer"
                          component={RingIcon}
                        />
                      </Badge>
                    </div>
                  </Dropdown>
                </Space>

                <Link to="/users/home">
                  <div onClick={(e) => e.stopPropagation()} onKeyUp={() => {}}>
                    <Popover
                      color="#202124"
                      overlayInnerStyle={{ padding: 0 }}
                      content={menuContent}
                      title={null}
                    >
                      {renderAvatar()}
                    </Popover>
                  </div>
                </Link>

                {notifVisibleState && (
                  <NotificationDrawer
                    setVisible={setNotifVisibleState}
                    visible={notifVisibleState}
                  />
                )}
              </Space>
            ) : (
              <>
                <Button
                  className="py-2.5 px-4 h-full rounded-full bg-[#00adae1a] border-0 text-[#00ADAE] text-base"
                  type="default"
                  onClick={() => navigate("/login", { replace: false })}
                >
                  Sign In
                </Button>
                <Button
                  className="py-2.5 px-4 h-full rounded-full bg-[#00ADAE] border-0 text-white text-base"
                  type="primary"
                  onClick={() => navigate("/register", { replace: false })}
                >
                  Sign Up
                </Button>
              </>
            )}
          </Space>
        </Row>
      </AntdHeader>
    </ConfigProvider>
  );
};
