import { axiosInstance } from "@/utils/http";
import { DownloadOutlined, EditOutlined } from "@ant-design/icons";
import { useApiUrl } from "@refinedev/core";
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Tabs,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CompanyVerificationModal from "../../components/projects/CompanyVerificationModal";
import CreateDepartmentModal from "../../components/projects/CreateDepartmentModal";
import { formatToMiddleStyle } from "../../utils/date";
import { formatJobDetails } from "../../utils/helper";
import EventPage from "./EventPage";

const { Title, Text } = Typography;

const { TabPane } = Tabs;
const { Option } = Select;

interface ProjectDetails {
  id: string;
  name: string;
  progress_status: string;
  description: string;
  timezone: string;
  start_date: string;
  end_date: string;
  start_date_utc: string;
  end_date_utc: string;
  created_at: string;
  thumbnail_url: string;
  verification_method: VerificationMethod | null;
  is_enabled: boolean;
  project_headcount: number;
  project_expenses: number;
  is_verified: boolean;
  verification_status: string;
  verification: Verification | null;
  is_violated: boolean;
  violation_status: string;
  violation: Violation | null;
}

interface Verification {
  comments: string;
  reviewed_at: string;
  reviewer: string;
}

interface VerificationMethod {
  contract_url: string;
  license_number: string;
  license_pic_urls: string[];
  license_type: string;
  manager_license_pic_url: string;
  manager_name: string;
}

interface Violation {
  comments: string;
  reviewed_at: string;
  reviewer: string;
}

interface Job {
  department_id: string;
  id: string;
  title: string;
  description: string;
  created_at: string;
  location: string;
  employment_type: string;
  headcount: number;
  payment_method: string;
  rate: number;
  rate_unit: string;
  remote_option: string;
  count: number;
  count_unit: string;
  is_enabled: boolean;
  total_applicants: number;
  total_views: number;
  total_interviewed: number;
  total_communicated: number;
}

interface GroupOption {
  id: string;
  name: string;
  description: string;
}

const { Search } = Input;

export const ProjectDetailsNew: React.FC = () => {
  const apiUrl = useApiUrl();
  const TOKEN_KEY = "leyline-auth";
  const token = localStorage.getItem(TOKEN_KEY);
  const marketplaceUploadUrl = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

  const navigate = useNavigate();

  const [projectName, setProjectName] = useState("");
  const [activeTopTab, setActiveTopTab] = useState("jobs");
  const [hasPostedJobs, setHasPostedJobs] = useState(false);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [showCreateDepartmentModal, setShowCreateDepartmentModal] =
    useState(false);

  const [isVerified, setIsVerified] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const [isProjectEnabled, setIsProjectEnabled] = useState<boolean>(true);
  const [isProjectViolated, setIsProjectViolated] = useState<boolean>(false);

  const [verification, setVerification] = useState<Verification | null>(null);
  const [verificationMethod, setVerificationMethod] =
    useState<VerificationMethod | null>(null);
  const [violation, setViolation] = useState<Violation | null>(null);
  const [fullThumbnailUrl, setFullThumbnailUrl] = useState("");
  const [showDeniedAlert, setShowDeniedAlert] = useState(false);
  const [showViolatedAlert, setShowViolatedAlert] = useState(false);
  const [showViolatedResubmittedWarning, setShowViolatedResubmittedWarning] =
    useState(false);
  const [projectDetails, setProjectDetails] = useState<ProjectDetails | null>(
    null,
  );
  const [groupOptions, setGroupOptions] = useState<GroupOption[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleVerificationClick = () => {
    navigate("/projects/verification");
  };

  const handlePostJobClick = () => {
    navigate("/jobs/create?go=1");
  };

  const renderDeniedAlert = () => {
    if (showDeniedAlert) {
      return (
        <Alert
          message="Verification denied"
          description={
            <>
              <div>Feedback: {verification?.comments || "No comments"}</div>
              <div>
                Verification Date:{" "}
                {formatToMiddleStyle(verification?.reviewed_at) || "N/A"}
              </div>
            </>
          }
          type="error"
          showIcon
          banner
        />
      );
    }
    return null;
  };

  const renderViolationAlert = () => {
    if (showViolatedAlert) {
      return (
        <Alert
          message={
            <>
              <div className="text-red-500 font-semibold">
                The project has violated guidelines
              </div>
            </>
          }
          description={
            <>
              <div className="text-red-500">
                Feedback: {violation?.comments || "No comments"}
              </div>
              <div className="text-red-500">
                Determination Date:{" "}
                {formatToMiddleStyle(violation?.reviewed_at || "N/A")}
              </div>
              <div>
                Note: Job information associated with violated projects will NOT
                be displayed. Please edit corresponding information and resubmit
                the project in time.
              </div>
            </>
          }
          type="error"
          showIcon
          banner
        />
      );
    }
    return null;
  };

  const renderViolationResubmissionWarning = () => {
    if (showViolatedResubmittedWarning) {
      return (
        <Alert
          message={
            <>
              <div className="text-orange-500 font-semibold">
                You have already resubmitted the project. Please wait for
                approval.
              </div>
            </>
          }
          description={
            <>
              <div className="text-orange-500">
                Feedback: {violation?.comments || "No comments"}
              </div>
              <div className="text-orange-500">
                Determination Date: {violation?.reviewed_at || "N/A"}
              </div>
              <div>
                Note: Job information associated with violated projects will NOT
                be displayed to artists.
              </div>
            </>
          }
          type="warning"
          showIcon
          banner
        />
      );
    }
    return null;
  };

  const handleJobClick = (jobId: string) => {
    navigate(`/jobs/detail/${jobId}`);
  };

  const handleJobKeyPress = (event: React.KeyboardEvent, jobId: string) => {
    return;
  };

  useEffect(() => {
    const checkCompanyVerification = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiUrl}/companies/my-company`,
        );
        if (
          !response.data ||
          Object.keys(response.data).length === 0 ||
          response.data.is_verified !== true
        ) {
          setIsModalVisible(true);
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    checkCompanyVerification();
  }, [apiUrl]);

  const handleConfirm = () => {
    setIsModalVisible(false);
    navigate("/companies/show");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    navigate("/users/home");
  };
  const projectId = sessionStorage.getItem("currentProjectId");
  useEffect(() => {
    if (projectId) {
      fetchProjectDetails(projectId);
      const fetchJobsDetails = async () => {
        try {
          const response = await fetch(`${apiUrl}/jobs/projects/${projectId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          const jobsData = await response.json();
          if (response.status === 200 && jobsData.length > 0) {
            setHasPostedJobs(true);
            setJobs(jobsData);
            console.log("Job Data: ", jobsData);
          } else {
            setHasPostedJobs(false);
          }
        } catch (error) {
          console.error("Error fetching jobs details:", error);
          setHasPostedJobs(false);
        }
      };
      fetchJobsDetails();
    }
  }, [apiUrl, token, projectId]);

  const fetchProjectDetails = async (projectId: string) => {
    try {
      const response = await fetch(`${apiUrl}/projects/${projectId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (response.status === 200) {
        console.log("Project Details:", data);
        setIsProjectEnabled(data.is_enabled === true);
        setIsProjectViolated(data.is_violated === true);
        setProjectName(data.name);
        setVerification(data.verification);
        setVerificationMethod(data.verification_method);
        setViolation(data.violation);
        setProjectDetails(data);

        if (data.thumbnail_url) {
          const newFullThumbnailUrl = `${marketplaceUploadUrl}${data.thumbnail_url}`;
          setFullThumbnailUrl(newFullThumbnailUrl);
        }
      } else {
        console.error("Cannot fetch project details:", response.status, data);
      }
    } catch (error) {
      console.error("Error fetching project details:", error);
    }
  };

  useEffect(() => {
    if (
      projectDetails?.verification_status === "APPROVED" ||
      projectDetails?.verification_status === "PENDING" ||
      projectDetails?.verification_status === "DENIED"
    ) {
      setIsVerified(true);
    }
  }, [projectDetails]);

  const handleEditVerificationClick = () => {
    navigate("/projects/edit-verification");
  };

  useEffect(() => {
    if (!verification) {
      return;
    }

    if (projectDetails?.verification_status === "DENIED") {
      setShowDeniedAlert(true);
    } else {
      setShowDeniedAlert(false);
    }

    if (projectDetails?.is_violated === true) {
      if (
        projectDetails?.violation_status === "DENIED" ||
        projectDetails?.violation_status === "UNSUBMITTED"
      ) {
        setShowViolatedResubmittedWarning(false);
        setShowViolatedAlert(true);
      } else if (projectDetails?.violation_status === "PENDING") {
        setShowViolatedAlert(false);
        setShowViolatedResubmittedWarning(true);
      }
    } else {
      setShowViolatedAlert(false);
      setShowViolatedResubmittedWarning(false);
    }
  }, [verification, projectDetails]);

  const renderVerificationButton = () => {
    const { is_verified, verification_status } = projectDetails || {};

    const verificationButtonMap: { [key: string]: JSX.Element } = {
      UNSUBMITTED: (
        <Title
          level={5}
          type="warning"
          underline
          onClick={handleVerificationClick}
          className="cursor-pointer inline-block"
        >
          Not verified? Verify now
        </Title>
      ),
      PENDING: (
        <Title type="warning" level={5} className="inline-block">
          Submitted, waiting for verification
        </Title>
      ),
      APPROVED: (
        <Title type="success" level={5} className="inline-block">
          Verified
        </Title>
      ),
      DENIED: (
        <Title
          level={5}
          type="danger"
          underline
          onClick={handleEditVerificationClick}
          className="cursor-pointer inline-block"
        >
          Verification failed! Edit now
        </Title>
      ),
    };

    const status =
      !is_verified && verification_status === "UNSUBMITTED"
        ? "UNSUBMITTED"
        : verification_status;

    const buttonToRender = status ? verificationButtonMap[status] : null;

    return buttonToRender ? (
      <div className="text-right mb-4">{buttonToRender}</div>
    ) : null;
  };

  useEffect(() => {
    const fetchGroupOptions = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiUrl}/jobs/groups/options`,
        );
        setGroupOptions(response.data);
      } catch (error) {
        console.error("Error fetching group options:", error);
      }
    };

    fetchGroupOptions();
  }, [apiUrl]);

  const handleSearch = async (value: string) => {
    try {
      const response = await axiosInstance.get(
        `${apiUrl}/jobs/projects/${projectId}?title=${value}`,
      );
      setJobs(response.data);
    } catch (error) {
      console.error("Error searching jobs:", error);
    }
  };

  const handleGroupFilter = async (selectedGroups: string[]) => {
    try {
      const url = `${apiUrl}/jobs/projects/${projectId}`;

      const response = await axiosInstance.get(url, {
        params: {
          group_ids: selectedGroups,
        },
      });
      setJobs(response.data);
    } catch (error) {
      console.error("Error filtering jobs by group:", error);
    }
  };

  return (
    <Row gutter={[16, 16]} className="min-h-screen">
      <CompanyVerificationModal
        isVisible={isModalVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <CreateDepartmentModal
        isVisible={showCreateDepartmentModal}
        onClose={() => setShowCreateDepartmentModal(false)}
      />
      <Col xl={6} xs={24} className="overflow-auto">
        <EventPage />
      </Col>
      <Col xl={18} xs={24} className="overflow-auto">
        <Card bordered className="rounded-lg">
          {renderViolationAlert()}
          {renderViolationResubmissionWarning()}
          <Tabs defaultActiveKey="1" onChange={(key) => setActiveTopTab(key)}>
            <TabPane tab="Jobs" key="jobs" />
            <TabPane tab="Verification Information" key="verificationInfo" />
          </Tabs>
          <div className="text-right">{renderVerificationButton()}</div>
          {activeTopTab === "jobs" && (
            <div>
              <Row align="middle" className="my-4 mt-10">
                <Col span={8} className="m-4">
                  <Search
                    placeholder="Search jobs"
                    enterButton
                    onSearch={handleSearch}
                  />
                </Col>
                <Col span={7}>
                  <Select
                    mode="multiple"
                    placeholder="Filter by groups"
                    onChange={handleGroupFilter}
                    className="w-full"
                  >
                    {groupOptions.map((group) => (
                      <Option key={group.id} value={group.id}>
                        {group.name}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col span={8} className="text-right">
                  {isProjectEnabled && !isProjectViolated && (
                    <Button type="primary" onClick={handlePostJobClick}>
                      Post Job
                    </Button>
                  )}
                </Col>
              </Row>
              {hasPostedJobs ? (
                <>
                  {jobs.map((job) => (
                    <div
                      key={job.id}
                      onClick={() => handleJobClick(job.id)}
                      onKeyUp={(event) => handleJobKeyPress(event, job.id)}
                      tabIndex={0}
                      role="button"
                      className="cursor-pointer"
                    >
                      <Row
                        gutter={16}
                        className="my-4 p-2 shadow rounded bg-white"
                      >
                        <Col
                          span={8}
                          className="flex justify-center items-center"
                        >
                          <Image
                            src={fullThumbnailUrl}
                            alt="Project Cover"
                            className="rounded-lg"
                            width={250}
                            height={250}
                            preview={false}
                          />
                        </Col>
                        <Col span={16}>
                          <Title level={3}>{job.title}</Title>
                          <Row justify="space-between" className="mb-4 mt-6">
                            <Text type="secondary">{projectName}</Text>
                            <Text type="secondary">
                              {formatJobDetails(
                                job.location,
                                job.remote_option,
                                job.employment_type,
                              )}
                            </Text>
                          </Row>
                          <Row justify="space-between" className="mb-4">
                            <Tag
                              color={job.is_enabled === true ? "green" : "red"}
                            >
                              {job.is_enabled === true ? "Open" : "Closed"}
                            </Tag>
                            <Text type="secondary">
                              Local Create Date:{" "}
                              {formatToMiddleStyle(job.created_at)}
                            </Text>
                          </Row>
                          <Row justify="center" className="mt-16">
                            <Col>
                              <div className="text-center">
                                <Title level={4}>{job.total_views}</Title>
                                <Text>Viewed</Text>
                              </div>
                            </Col>
                            <Divider
                              type="vertical"
                              className="h-8 bg-gray-400"
                            />
                            <Col>
                              <div className="text-center">
                                <Title level={4}>
                                  {job.total_communicated ?? 0}
                                </Title>
                                <Text>Communicated</Text>
                              </div>
                            </Col>
                            <Divider
                              type="vertical"
                              className="h-8 bg-gray-400"
                            />
                            <Col>
                              <div className="text-center">
                                <Title level={4}>{job.total_applicants}</Title>
                                <Text>Applied</Text>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              ) : (
                <p>
                  No posted jobs yet, post a job <b>NOW</b>!
                </p>
              )}
            </div>
          )}
          {activeTopTab === "projectStats" && <div />}
          {activeTopTab === "verificationInfo" && (
            <div>
              {renderDeniedAlert()}
              <Modal
                title="Confirm Edit"
                visible={showVerificationModal}
                onOk={() => {
                  navigate("/projects/edit-verification");
                  setShowVerificationModal(false);
                }}
                onCancel={() => setShowVerificationModal(false)}
              >
                <p>
                  Updating the verification details will require re-initiating
                  the verification process. Are you sure to edit?
                </p>
              </Modal>
              {!isVerified ? (
                <div className="flex flex-col justify-center items-center">
                  <p className="text-center mt-10 mb-4">
                    Projects that pass verification will be given priority
                    display and an exclusive verification badge,
                    <br /> adding more exposure and appeal to the job postings
                    for the current project!
                  </p>
                  <Button type="primary" onClick={handleVerificationClick}>
                    Verify Now
                  </Button>
                </div>
              ) : (
                <div className="mt-10 ml-10 space-y-8">
                  <p className="font-semibold">
                    Verification Method:{" "}
                    <span className="font-normal">Contract Verification</span>
                  </p>
                  <Row gutter={16}>
                    <Col span={8}>
                      <p className="font-semibold">
                        Project Representative:{" "}
                        <span className="font-normal">
                          {verificationMethod?.manager_name || "N/A"}
                        </span>
                      </p>
                    </Col>
                    <Col span={8}>
                      <p className="font-semibold">
                        Document Number:{" "}
                        <span className="font-normal">
                          {verificationMethod?.license_number || "N/A"}
                        </span>
                      </p>
                    </Col>
                    <Col span={8}>
                      <p className="font-semibold">
                        Document Type:{" "}
                        <span className="font-normal">
                          {verificationMethod?.license_type || "N/A"}
                        </span>
                      </p>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8} className="md:w-1/4 sm:w-1/2 w-full">
                      <p className="font-semibold flex items-center">
                        Document Photos:
                        <span className="font-normal flex items-center ml-2 flex-wrap">
                          {verificationMethod?.license_pic_urls?.map(
                            (url, index) => (
                              <div className="m-1" key={url}>
                                <Image
                                  src={`${marketplaceUploadUrl}${url}`}
                                  alt="Document Photo"
                                  preview={true}
                                  width={96}
                                  height={96}
                                />
                              </div>
                            ),
                          )}
                        </span>
                      </p>
                    </Col>
                    <Col span={8} className="md:w-1/4 sm:w-1/2 w-full">
                      <p className="font-semibold flex items-center">
                        Hand-held Document Photo:
                        <span className="font-normal flex items-center ml-2">
                          <Image
                            src={`${marketplaceUploadUrl}${verificationMethod?.manager_license_pic_url}`}
                            alt="Hand-held Document Photo"
                            preview={true}
                            width={96}
                            height={96}
                          />
                        </span>
                      </p>
                    </Col>
                    <Col span={8}>
                      <p className="font-semibold">
                        Project Contract:
                        <span className="font-normal ml-4">
                          <Button
                            icon={<DownloadOutlined />}
                            href={`${marketplaceUploadUrl}${verificationMethod?.contract_url}`}
                            target="_blank"
                            download
                          >
                            Download
                          </Button>
                        </span>
                      </p>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button
                      type="primary"
                      icon={<EditOutlined />}
                      onClick={handleEditVerificationClick}
                      disabled={
                        projectDetails?.verification_status === "PENDING"
                      }
                    >
                      Edit Verification Information
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </Card>
      </Col>
    </Row>
  );
};
