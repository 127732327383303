import { UserType } from "@/__generated__/graphql";
import { CompanyIcon, DefaultUserIcon } from "@/components/customComponents";
import {
  ICompany,
  IFollowNotificationData,
  IUser,
} from "@/pages/discover/types";
import { getImgUrl } from "@/utils/common";
import { formatToLongStyle } from "@/utils/date";
import { Avatar, Col, Row, Space, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

interface IFollowerDrawerItemProps {
  commentItem: IFollowNotificationData;
}
// FollowerDrawerItem component using mock data
export const FollowerDrawerItem: React.FC<IFollowerDrawerItemProps> = ({
  commentItem,
}) => {
  return (
    <Row className="flex flex-row gap-2.5 flex-1 px-[15px]">
      <Link
        to={`/discover/author/${commentItem.target.user_id}?role=${commentItem.target.role}`}
      >
        <div className="relative">
          <Avatar
            className={`object-cover ${
              commentItem.target.role === UserType.Company &&
              "border-2 border-solid border-[#03B3B4]"
            }`}
            size={40}
            src={
              commentItem.target.role === UserType.Company ? (
                (commentItem.author_info as ICompany).logo_url ? (
                  getImgUrl((commentItem.author_info as ICompany).logo_url)
                ) : (
                  <DefaultUserIcon />
                )
              ) : (commentItem.author_info as IUser).avatar_url ? (
                getImgUrl((commentItem.author_info as IUser).avatar_url)
              ) : (
                <DefaultUserIcon />
              )
            }
          />
          {commentItem.target.role === UserType.Company && (
            <Space className="absolute z-10 left-1/2 top-[-18%] transform -translate-x-1/2">
              <CompanyIcon width="16px" height="16px" />
            </Space>
          )}
        </div>
      </Link>
      <Row className="flex flex-col flex-1">
        <Col className="flex flex-row justify-between">
          <Link
            to={`/discover/author/${commentItem.user_id}?role=${commentItem.role}`}
          >
            <Typography.Text className="text-white text-opacity-85 text-base">
              {commentItem.target.role === UserType.Company
                ? (commentItem.author_info as ICompany).shortname
                : (commentItem.author_info as IUser).user_name}
            </Typography.Text>
          </Link>
        </Col>
        <Typography.Paragraph
          className="text-[#606264] text-sm w-full !mb-0.5"
          ellipsis={{ rows: 1 }}
        >
          {commentItem.message} {formatToLongStyle(commentItem.created_at)}
        </Typography.Paragraph>
      </Row>
    </Row>
  );
};
