import { ChatSDK } from "@tencentcloud/chat";
import { create } from "zustand";
import { UserType } from "./__generated__/graphql";

interface GlobalState {
  loading: boolean; // loading state for global loading
  chatClient: ChatSDK | null;
  currentRole: UserType | null;
  activeCol: string; // present portfolio or author view on home page
  artworkSearch: string;
  updateLoading: (loading: boolean) => void;
  updateCurrentRole: (role: UserType) => void;
  updateChatClient: (chat: ChatSDK | null) => void;
  updateActiveCol: (col: string) => void;
  updateArtworkSearch: (search: string) => void;
}

// @ts-ignore
export const useGlobalStore = create<GlobalState>((set) => ({
  loading: false,
  chatClient: null,
  currentRole: null,
  activeCol: "portfolio",
  artworkSearch: "",
  updateLoading: (loading: boolean) => set(() => ({ loading })),
  updateCurrentRole: (newRole: UserType) =>
    set(() => ({ currentRole: newRole })),
  // @ts-ignore
  updateChatClient: (chat) => set(() => ({ chatClient: chat })),
  updateActiveCol: (col) => set(() => ({ activeCol: col })),
  updateArtworkSearch: (search) => set(() => ({ artworkSearch: search })),
}));

export default useGlobalStore;
