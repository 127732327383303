import { CommentPanel } from "@/components/discover/workDetail/CommentPanel";
import { useUserData } from "@/hooks";
import {
  getArtworkDetailAPI,
  getArtworkGuestDetailAPI,
} from "@/pages/discover/api";
import { IArtworkItem, ICreateArtworkFormValues } from "@/pages/discover/types";
import useGlobalStore from "@/store";
import { getImgUrl } from "@/utils/common";
import { videoExtensions } from "@/utils/identifyFiles";
import { padImageUrl } from "@/utils/upload";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { IResourceComponentsProps } from "@refinedev/core";
import { Col, Image, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const RESOURCES_BASE_URL = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;
const videoExtensionsRegex = videoExtensions.join("|");
export const PortfolioDetail: React.FC<IResourceComponentsProps> = () => {
  const { currentRole, artworkSearch, updateLoading, updateArtworkSearch } =
    useGlobalStore((state) => state);
  const { id } = useParams();
  const profile = useUserData();
  const [artwork, setArtwork] = useState<IArtworkItem | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [artworkList, setArtworkList] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const getArtworkDetail = useCallback(async () => {
    if (!currentRole || !id || id === "preview") return;
    const response = await getArtworkDetailAPI(updateLoading, currentRole, id);
    setArtwork(response);
  }, [id, updateLoading, currentRole]);

  const getArtworkGuestDetail = useCallback(async () => {
    if (!id || id === "preview") return;
    const response = await getArtworkGuestDetailAPI(updateLoading, id);
    setArtwork(response);
  }, [id, updateLoading]);

  useEffect(() => {
    if (!profile) {
      getArtworkGuestDetail();
    } else {
      getArtworkDetail();
    }
  }, [getArtworkDetail, getArtworkGuestDetail, profile]);

  const previewValues: ICreateArtworkFormValues = JSON.parse(
    localStorage.getItem("previewValues") || "{}",
  );

  useEffect(() => {
    if (id === "preview") {
      setArtwork((prevArtwork) => ({
        ...prevArtwork,
        id: prevArtwork?.id ?? "preview",
        user_id: prevArtwork?.user_id ?? "",
        commented_count: 0,
        medias: [],
        liked_count: 0,
        favorited_count: 0,
        title: previewValues.artworkTitle,
        description: previewValues.artworkDescription,
        skills: previewValues.skills,
        software: previewValues.software,
        folder_id: previewValues.folder,
        is_published: previewValues.publishStatus,
      }));
    }
  }, [id, previewValues]);

  useEffect(() => {
    const savedArtworkList = localStorage.getItem("artworkList");
    if (savedArtworkList) {
      const artworkArray = JSON.parse(savedArtworkList);
      setArtworkList(artworkArray);

      const pathParts = location.pathname.split("/");
      const currentArtworkId = pathParts[pathParts.length - 1];
      const index = artworkArray.indexOf(currentArtworkId);
      setCurrentIndex(index);
    }
  }, [location]);

  const navigateToArtwork = (index: number) => {
    if (index >= 0 && index < artworkList.length) {
      const nextArtworkId = artworkList[index];
      navigate(`/discover/portfolio/${nextArtworkId}`);
    }
  };

  const handleLeftClick = () => {
    navigateToArtwork(currentIndex - 1);
  };

  const handleRightClick = () => {
    navigateToArtwork(currentIndex + 1);
  };

  return (
    <Row className="gap-4 p-6 flex flex-row flex-1 w-full h-[calc(100vh-80px)]">
      <Col className="bg-black rounded-xl text-white overflow-hidden flex flex-row flex-1 py-[52px] px-2 h-full portfolio-detail-view">
        <Col className="flex items-center">
          {id !== "preview" &&
            (currentIndex <= 0 ? (
              <LeftOutlined className="custom-arrow !text-opacity-50 !text-[#578A8C]" />
            ) : (
              <LeftOutlined
                onClick={handleLeftClick}
                className="custom-arrow"
              />
            ))}
        </Col>
        <Col className="px-3 flex flex-col gap-9 overflow-y-scroll flex-1">
          {id === "preview"
            ? previewValues.mediaFiles?.map((file) =>
                file.type ? (
                  file.response.id.match(`\\.(?:${videoExtensionsRegex})$`) ? (
                    <div className="w-full aspect-[16/9]" key={file.uid}>
                      <ReactPlayer
                        url={padImageUrl(file.response.id)}
                        controls
                        width="100%"
                        height="100%"
                        config={{
                          file: {
                            attributes: {
                              onContextMenu: (e: React.MouseEvent) =>
                                e.preventDefault(),
                              controlsList: "nodownload",
                            },
                          },
                        }}
                      />
                    </div>
                  ) : (
                    <Image
                      preview={false}
                      key={file.uid}
                      src={padImageUrl(file.response.id)}
                    />
                  )
                ) : file.name.match(`\\.(?:${videoExtensionsRegex})$`) ? (
                  <div className="w-full aspect-[16/9]" key={file.uid}>
                    <ReactPlayer
                      url={file.name}
                      controls
                      width="100%"
                      height="100%"
                      config={{
                        file: {
                          attributes: {
                            onContextMenu: (e: React.MouseEvent) =>
                              e.preventDefault(),
                            controlsList: "nodownload",
                          },
                        },
                      }}
                    />
                  </div>
                ) : (
                  <Image preview={false} key={file.uid} src={file.name} />
                ),
              )
            : artwork?.medias?.map((media) =>
                media.match(`\\.(?:${videoExtensionsRegex})$`) ? (
                  <div className="w-full aspect-[16/9]" key={media}>
                    <ReactPlayer
                      url={media}
                      controls
                      width="100%"
                      height="100%"
                      config={{
                        file: {
                          attributes: {
                            onContextMenu: (e: React.MouseEvent) =>
                              e.preventDefault(),
                            controlsList: "nodownload",
                          },
                        },
                      }}
                    />
                  </div>
                ) : (
                  <Image
                    key={media}
                    className="w-full object-cover"
                    preview={false}
                    src={media}
                  />
                ),
              )}
        </Col>
        <Col className="flex items-center">
          {id !== "preview" &&
            (currentIndex >= artworkList.length - 1 ? (
              <RightOutlined className="custom-arrow !text-opacity-50 !text-[#578A8C]" />
            ) : (
              <RightOutlined
                onClick={handleRightClick}
                className="custom-arrow"
              />
            ))}
        </Col>
      </Col>
      {artwork && (
        <CommentPanel
          preview={id === "preview" ? true : false}
          artwork={artwork}
        />
      )}
    </Row>
  );
};
