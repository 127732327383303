import { UserType } from "@/__generated__/graphql";
import { httpGet, httpPost } from "@/utils/http";

export const sendEmail = (params: {
  receiver_im_id: string;
  message_content: string;
  sender_im_nickname: string;
}) => httpPost("/jobs/im-email", params);

export const getJobDetail = async (jobId: string) => {
  const res = await httpGet(`/jobs/${jobId}`);
  return res.data;
};

export const getPublicJobs = async (params: {
  page: number;
  size: number;
  employment_types: string[];
  remote_options: string[];
  name: string;
  is_verified: boolean;
}) => {
  return httpGet("/jobs", {
    params,
  });
};

export const getApplications = async (params: {
  job_id: string;
  page: number;
  size: number;
}) => {
  const res = await httpGet(`/applications/${params.job_id}`, {
    params: {
      page: params.page,
      size: params.size,
    },
  });

  return {
    ...res.data,
    items:
      // @ts-ignore
      res?.data?.items?.map((x) => {
        return {
          ...x,
          authorItem: {
            artworks: x.applicant_artworks,
            company: x.applicant_company,
            role: x.applicant_role.toUpperCase(),
            ...x.applicant_user,
          },
        };
      }) || [],
  };
};

export const checkApplyForJob = async (params: {
  job_id: string;
  role: UserType | "Company" | "Individual";
}) => httpPost(`/applications/check/${params.job_id}?role=${params.role}`);
