import { useApiUrl } from "@refinedev/core";
import { Drawer } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import CompanyImgInfo from "../../../pages/companies/components/CompanyImgInfo";
import Introduction from "../../../pages/companies/components/PersonalCenterIntroduction";
import { ICompanyInfo } from "../../../pages/companies/type";
import { padLeftImgUrl } from "../../../utils/common";
import { httpGet } from "../../../utils/http";
import { axiosInstance } from "../../../utils/http";

interface Props {
  open: boolean;
  companyId: string;
  userId?: string;
  onCancel: () => void;
}

const ResumeDetailDrawer = ({ open, companyId, userId, onCancel }: Props) => {
  const [resumeData, setResumeData] = useState<ICompanyInfo>();
  const apiUrl = useApiUrl();

  const getResumeData = useCallback(async () => {
    if (companyId) {
      const res = await httpGet(`${apiUrl}/companies/${companyId}/public`);
      console.log("resume res data:", res);
      if (res.status === 200)
        setResumeData({ ...res.data.id, extended_info: res.data });
    } else if (userId) {
      const res = await axiosInstance.get(`${apiUrl}/companies/by-user`);
      if (res.status === 200) setResumeData(res.data);
    }
  }, [apiUrl, companyId, userId]);

  useEffect(() => {
    open && getResumeData();
  }, [open, getResumeData]);

  return (
    <Drawer
      open={open}
      title="The resume detail"
      onClose={onCancel}
      placement="right"
      closable
      width={600}
      maskClosable={true}
    >
      {open && (
        <>
          <CompanyImgInfo companyInfo={resumeData} readonly />
          <Introduction companyInfo={resumeData} />
        </>
      )}
    </Drawer>
  );
};

export default ResumeDetailDrawer;
