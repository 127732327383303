import { axiosInstance } from "@/utils/http";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import moment from "moment-timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

export const calculateRelativeTime = (timestamp: string) => {
  const then = dayjs(timestamp);
  const now = dayjs();
  return then.from(now);
};

// export const formatToMiddleStyle = (
//   timestamp: string | undefined,
//   nullShow?: string,
// ) => {
//   return timestamp
//     ? moment.utc(timestamp).local().format("YYYY/MM/DD")
//     : nullShow ?? "";
// };

// export const formatToShortStyle = (
//   timestamp: string | undefined,
//   nullShow?: string,
// ) => {
//   return timestamp
//     ? moment.utc(timestamp).local().format("MM/DD")
//     : nullShow ?? "";
// };

export const formatRemainingTime = (targetTime: string, duration = 0) => {
  if (!targetTime) return "";
  const futureDate = moment.utc(targetTime).local().add(duration, "day");
  const currentDate = moment();
  const isFutureTime = currentDate.isBefore(futureDate);
  if (isFutureTime) {
    const duration = moment.duration(futureDate.diff(currentDate));
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${days} days ${hours} hours ${minutes} minutes`;
  }
  return "";
};

export const formatTo =
  (f = "MM/DD/YYYY") =>
  (timestamp: string | undefined, nullShow?: string) => {
    return timestamp ? dayjs(timestamp).format(f) : nullShow ?? "";
  };

export const formatToYMDHmS = formatTo("YYYY/MM/DD HH:mm:ss");

export const formatToYMD = formatTo("YYYY/MM/DD");

export const formatToMD = formatTo("MM/DD");

const getUserTimezone = () => {
  return localStorage.getItem("userTimezone") || guessUserTimezone();
};

export const formatToLongStyle = (
  timestamp: string | undefined,
  nullShow?: string,
) => {
  const userTimezone = getUserTimezone();
  return timestamp
    ? dayjs.utc(timestamp).tz(userTimezone).format("MMM DD, YYYY HH:mm:ss")
    : nullShow ?? "";
};

export const formatToLongNewStyle = (
  timestamp: string | undefined,
  nullShow?: string,
) => {
  return timestamp
    ? moment.utc(timestamp).local().format("YYYY-MM-DD HH:mm:ss")
    : nullShow ?? "";
};

export const formatToMiddleStyle = (
  timestamp: string | undefined,
  nullShow?: string,
) => {
  const userTimezone = getUserTimezone();
  return timestamp
    ? dayjs.utc(timestamp).tz(userTimezone).format("MMM DD, YYYY")
    : nullShow ?? "";
};

export const formatToLongNoSecondStyle = (
  timestamp: string | undefined,
  nullShow?: string,
) => {
  const userTimezone = getUserTimezone();
  return timestamp
    ? dayjs.utc(timestamp).tz(userTimezone).format("MMM DD, YYYY, HH:mm")
    : nullShow ?? "";
};

export const formatToShortStyle = (
  timestamp: string | undefined,
  nullShow?: string,
) => {
  const userTimezone = getUserTimezone();
  return timestamp
    ? dayjs.utc(timestamp).tz(userTimezone).format("MMM DD")
    : nullShow ?? "";
};

export const formatToLongStyleUTC = (
  timestamp: string | undefined,
  nullShow?: string,
) => {
  // Format: Apr 12, 2024 17:00:00
  return timestamp
    ? dayjs.utc(timestamp).format("MMM DD, YYYY HH:mm:ss")
    : nullShow ?? "";
};

export const formatToMiddleStyleUTC = (
  timestamp: string | undefined,
  nullShow?: string,
) => {
  // Format: Apr 12, 2024
  return timestamp
    ? dayjs.utc(timestamp).format("MMM DD, YYYY")
    : nullShow ?? "";
};

export const formatToShortStyleUTC = (
  timestamp: string | undefined,
  nullShow?: string,
) => {
  // Format: Apr 12
  return timestamp ? dayjs.utc(timestamp).format("MMM DD") : nullShow ?? "";
};

export const datePickerFormat = "MMM DD, YYYY";

export const generateTimezoneOptions = () => {
  return moment.tz.names().map((tz) => {
    const offset = moment.tz(tz).format("Z");
    return {
      label: `GMT ${offset} - ${tz.replace(/_/g, " ")}`,
      value: tz,
    };
  });
};

export const getTimezoneText = (timezone: string | undefined) => {
  return generateTimezoneOptions().find((tzItem) => tzItem.value === timezone)
    ?.label;
};

export const guessUserTimezone = () => {
  const guessedTimezone = dayjs.tz.guess();
  return guessedTimezone || "America/Los_Angeles";
};

export const setDefaultTimezone = async () => {
  try {
    const response = await axiosInstance.get("/users/settings");
    const timezone = response.data.timezone;

    if (!timezone) {
      const guessedTimezone = guessUserTimezone();
      await axiosInstance.put("/users/settings", {
        timezone: guessedTimezone,
      });
    }
  } catch (error) {
    console.error("Error checking/updating timezone:", error);
  }
};
