import { PRIVACY_POLICY_URL, USER_AGREEMENT_URL } from "@/constants";
import { datePickerFormat } from "@/utils/date";
import { Edit, useStepsForm } from "@refinedev/antd";
import {
  IResourceComponentsProps,
  useApiUrl,
  useGo,
  useParsed,
  useTranslate,
} from "@refinedev/core";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { companyTypeOptions, countryOptions } from "../../utils/common";
import { axiosInstance } from "../../utils/http";

interface InitialValuesType {
  id?: string;
  country: string;
  detail: {
    name: string;
    abbriviation: string;
    type: string;
    built_at?: dayjs.Dayjs | null;
  };
  email_verification: {
    website: string;
    applicant: string;
    applicant_role: string;
    applicant_email: string;
  };
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const mapResponseToInitialValues = (response: any): InitialValuesType => {
  if (!response || !response.extended_info) {
    return {
      id: "",
      country: "",
      detail: {
        name: "",
        abbriviation: "",
        type: "",
        built_at: null,
      },
      email_verification: {
        website: "",
        applicant: "",
        applicant_role: "",
        applicant_email: "",
      },
    };
  }

  return {
    id: response.id || "",
    country: response.extended_info.country || "",
    detail: {
      name: response.extended_info.name || "",
      abbriviation: response.extended_info.shortname || "",
      type: response.extended_info.type || "",
      built_at: response.extended_info.established_at
        ? dayjs(response.extended_info.established_at)
        : null,
    },
    email_verification: {
      website: response.website || "",
      applicant: response.applicant_name || "",
      applicant_role: response.applicant_role || "",
      applicant_email: response.applicant_email || "",
    },
  };
};

export const CompanyCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { id, params } = useParsed();
  const type = params?.type;

  const stepForm = useStepsForm({
    resource: "companies",
    id: id,
    meta: {
      suffix: "my-company",
    },
    action: "edit",
    warnWhenUnsavedChanges: false,
    queryMeta: {
      suffix: "my-company",
      ignoreId: true,
    },
    onMutationError: (error) => {
      message.error(error?.response?.data?.detail || "Update failed");
    },
  });
  const { current, formProps, saveButtonProps, onFinish, queryResult } =
    stepForm;
  const go = useGo();

  const [initialValues, setInitialValues] = useState<
    InitialValuesType | undefined
  >(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/companies/my-company");
        const mappedValues = mapResponseToInitialValues(response.data);
        setInitialValues(mappedValues);
        formProps.form?.setFieldsValue(mappedValues);
        const email = mappedValues?.email_verification?.applicant_email || "";
        setIsButtonEnabled(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email),
        );
      } catch (error) {
        console.error("Failed to fetch company data", error);
      }
    };
    fetchData();
  }, [formProps.form]);

  const [isOTPSent, setIsOTPSent] = useState(false);
  const [otpCountdown, setOtpCountdown] = useState(15);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  function goToStatus() {
    go({
      to: "/companies/show",
    });
  }

  const sendOTP = async () => {
    try {
      setIsOTPSent(true);
      setOtpCountdown(15);
      // @ts-ignore
      const email = formProps?.form.getFieldValue([
        "email_verification",
        "applicant_email",
      ]);
      await axiosInstance.post("/companies/generate_code", null, {
        params: {
          email,
        },
      });
      message.success(
        "Your One-Time Password (OTP) has been sent successfully! Please check your email and spam folder",
      );
    } catch (error) {
      console.error(error);
      message.error("One-Time Password (OTP) sent failed");
      setIsOTPSent(false);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isOTPSent && otpCountdown > 0) {
      interval = setInterval(() => {
        setOtpCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (otpCountdown === 0) {
      setIsOTPSent(false);
    }
    return () => clearInterval(interval);
  }, [isOTPSent, otpCountdown]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setIsButtonEnabled(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email));
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const onSubmit = async (values: any) => {
    try {
      const data = {
        country: values.country,
        name: values.detail.name,
        shortname: values.detail.abbriviation,
        type: values.detail.type,
        established_at: values.detail.built_at
          ? values.detail.built_at.toISOString()
          : null,
        website: values.email_verification.website,
        applicant_name: values.email_verification.applicant,
        applicant_role: values.email_verification.applicant_role,
        applicant_email: values.email_verification.applicant_email,
        otp: values.email_verification.totp,
      };

      if (initialValues?.id) {
        await axiosInstance.put(`/companies/${initialValues.id}`, data);
        message.success("Company information updated successfully");
      } else {
        await axiosInstance.post("/companies", data);
        message.success("Company information submitted successfully");
      }

      goToStatus();

      return true;
    } catch (error) {
      console.error(error);
      message.error("Company information submission failed");
    }
  };

  return (
    <Row gutter={24} className="min-h-screen">
      <Col span={24} className="overflow-auto">
        <Card bordered className="rounded-lg">
          <Edit
            headerButtons={() => <></>}
            footerButtons={
              <Button
                type="primary"
                onClick={async () => {
                  try {
                    formProps?.form
                      ?.validateFields({ validateOnly: false })
                      .then(
                        (v) => {
                          onSubmit(v);
                        },
                        (e) => {
                          console.error(e);
                        },
                      );
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                Submit
              </Button>
            }
          >
            <Form
              {...formProps}
              style={{
                marginTop: 30,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
              layout="horizontal"
              onFinish={onSubmit}
              initialValues={initialValues}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <Row key="Company Information" gutter={20}>
                <Col span={24}>
                  <Form.Item
                    label="Country"
                    name="country"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={countryOptions}
                      showSearch
                      filterOption={(input, option) =>
                        option?.label
                          ? option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          : false
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="Company Name"
                    name={["detail", "name"]}
                    rules={[{ required: true }, { max: 100 }]}
                  >
                    <Input
                      placeholder="Please do not enter special characters, and within 100 characters."
                      maxLength={100}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Company Abbreviation"
                    name={["detail", "abbriviation"]}
                    rules={[{ required: true }, { max: 60 }]}
                  >
                    <Input
                      placeholder="Please do not enter special characters, and within 60 characters."
                      maxLength={60}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Company Type"
                    name={["detail", "type"]}
                    rules={[{ required: true }]}
                  >
                    <Select options={companyTypeOptions} showSearch />
                  </Form.Item>
                  <Form.Item
                    label="Establishment Date"
                    name={["detail", "built_at"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter the establishment date",
                      },
                    ]}
                  >
                    <DatePicker format={datePickerFormat} />
                  </Form.Item>
                  <Form.Item
                    label="Website"
                    name={["email_verification", "website"]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Applicant"
                    name={["email_verification", "applicant"]}
                    rules={[{ required: true }]}
                  >
                    <Input maxLength={30} />
                  </Form.Item>
                  <Form.Item
                    label="Applicant Role"
                    name={["email_verification", "applicant_role"]}
                    rules={[{ required: true }]}
                  >
                    <Input maxLength={30} />
                  </Form.Item>
                  <Form.Item
                    label="Applicant's Business Email"
                    name={["email_verification", "applicant_email"]}
                    validateTrigger="onBlur"
                    rules={[
                      { required: true },
                      {
                        type: "email",
                        message: "Invalid email address",
                      },
                    ]}
                  >
                    <Input
                      onChange={handleEmailChange}
                      addonAfter={
                        <Button
                          onClick={sendOTP}
                          disabled={!isButtonEnabled || isOTPSent}
                        >
                          {isOTPSent
                            ? `Resend OTP (${otpCountdown})`
                            : "Send the OTP"}
                        </Button>
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label="One-Time Password"
                    name={["email_verification", "totp"]}
                    validateTrigger="onBlur"
                    rules={[{ required: true, len: 6 }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error("You must accept the agreement"),
                              ),
                      },
                    ]}
                    wrapperCol={{ offset: 8, span: 16 }}
                  >
                    <Checkbox
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      I agree to{" "}
                      <a
                        className="!text-[#1677ff] hover:underline"
                        href={USER_AGREEMENT_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        User Agreement
                      </a>{" "}
                      and{" "}
                      <a
                        className="!text-[#1677ff] hover:underline"
                        href={PRIVACY_POLICY_URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Edit>
        </Card>
      </Col>
    </Row>
  );
};
