import { setDefaultTimezone } from "@/utils/date";

import { useGetIdentity, useInvalidate } from "@refinedev/core";
import { useGo } from "@refinedev/core";
import { App, Button, Result, Spin, Tabs } from "antd";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { UserType } from "@/__generated__/graphql";
import {
  getPublishedApplications,
  getReceivedApplications,
} from "@/pages/users/services";
import useGlobalStore from "@/store";
import { IResourceComponentsProps, useOne } from "@refinedev/core";
import { useInfiniteScroll } from "ahooks";
import { ArtistApplicantCard } from "../../components/users/ArtistApplicantCard";
import { JobAppliedCard } from "../../components/users/JobAppliedCard";
import { useConfigProvider } from "../../contexts";
import { useHasCompanyRole, useRefreshToken } from "../../hooks";
import { IProfile, IWorkPreference } from "../../interfaces";
import { JobUnmatchModal } from "../jobs/modalUnmatch";

// according to the Avatar props
export interface IIdentity {
  name: string;
  avatar: string;
}

// type HiringType = "Find a job" | "Find an artist";
type TalkFilterType = "appliedComp" | "appliedIndi" | "applications";

const PAGE_SIZE = 10;

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const { message } = App.useApp();
  const { currentRole } = useGlobalStore((state) => state);
  const ref = useRef<HTMLDivElement>(null);

  const { data, isLoading, isError } = useOne({
    resource: "users",
    id: "profile",
    queryOptions: {
      enabled: true,
    },
  });

  const go = useGo();
  const identityData = useGetIdentity(); // refresh the identity data
  const { companyInfo, hasEmployerView } = useHasCompanyRole(false);

  // Accessing the profile data
  const profile = data?.data.profile as IProfile;
  const work_preference = data?.data.work_preference as IWorkPreference;
  // const isNewUser = true;
  const isNewUser =
    profile?.user_name === null || profile?.user_name?.length === 0;

  const [talkFilterCompany, setTalkFilterCompany] =
    useState<TalkFilterType | null>(null);
  const [isUnmatchModalOpen, setIsUnmatchModalOpen] = useState(false);
  const [unmatchReason, setUnmatchReason] = useState<{
    reason: string;
    remark?: string;
  }>({ reason: "" });
  const curActionInPageRef = useRef(1);

  const isCompany = useMemo(
    () =>
      [UserType.Company, UserType.Applicantcompany].includes(
        currentRole as UserType,
      ),
    [currentRole],
  );

  useEffect(() => {
    setTalkFilterCompany(isCompany ? "applications" : "appliedComp");
  }, [isCompany]);

  useEffect(() => {
    setDefaultTimezone();
  }, []);

  const fetchItems = useCallback(
    async (page: number) => {
      if (!currentRole || isNewUser || !talkFilterCompany) {
        isNewUser &&
          message.info(
            "Please fill in your basic information to complete your profile.",
            3,
          );
        return {
          total: 0,
          list: [],
          nextPage: 1,
        };
      }
      const api =
        talkFilterCompany === "applications"
          ? getReceivedApplications
          : getPublishedApplications;
      const res = await api({
        page,
        size: PAGE_SIZE,
        ...(talkFilterCompany === "appliedComp"
          ? { role: isCompany ? "Company" : "Individual" }
          : null),
      });
      return {
        total: res.data.total,
        list: res.data.items,
        nextPage:
          res.data.page < res.data.pages ? res.data.page + 1 : undefined,
      };
    },
    [currentRole, isNewUser, message, talkFilterCompany, isCompany],
  );

  const {
    data: applicationsData,
    loading,
    loadingMore,
    noMore,
    mutate,
    error: isErrorApplica,
  } = useInfiniteScroll((d) => (d ? fetchItems(d?.nextPage) : fetchItems(1)), {
    target: ref,
    isNoMore: (d) => !d?.nextPage,
    reloadDeps: [talkFilterCompany],
  });

  const fnRefresh = async () => {
    const curPageNewData = await fetchItems(curActionInPageRef.current);
    mutate((previousData) => {
      const updatedList = [...(previousData?.list ?? [])];
      const curPageStartIndex = (curActionInPageRef.current - 1) * PAGE_SIZE;
      updatedList.splice(curPageStartIndex, PAGE_SIZE, ...curPageNewData.list);
      return {
        ...previousData,
        list: updatedList,
      };
    });
  };

  const handleCurActionInPage = (index: number) => {
    const curPage = Math.ceil((index + 1) / PAGE_SIZE);
    curActionInPageRef.current = curPage;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Something is wrong...</div>;
  }

  const askUnmatch = () => {
    setIsUnmatchModalOpen(true);
  };

  const renderArtistApplied = () => {
    // @ts-ignore
    if (applicationsData?.list?.length > 0) {
      return applicationsData?.list.map((x, i) => {
        return (
          <div onClick={() => handleCurActionInPage(i)} onKeyUp={() => {}}>
            {/* @ts-ignore */}
            <JobAppliedCard key={x.id} applied={x} refreshList={fnRefresh} />
          </div>
        );
      });
    }
    return (
      <Result
        title="List is empty"
        extra={
          <Button
            type="primary"
            key="console"
            onClick={() => {
              navigate("/enterprises/public-jobs");
            }}
          >
            Browse Job Openings
          </Button>
        }
      />
    );
  };
  const renderApplicants = () => {
    // @ts-ignore
    if (applicationsData?.list?.length > 0) {
      return applicationsData?.list?.map((x) => {
        return (
          <ArtistApplicantCard
            // @ts-ignore
            key={x.id}
            applicant={x}
            askUnmatch={askUnmatch}
            refreshList={fnRefresh}
          />
        );
      });
    }
    return <Result title="List is empty" extra={<React.Fragment />} />;
  };

  return (
    <div className="bg-white overflow-auto h-full" ref={ref}>
      <JobUnmatchModal // @ts-ignore
        open={isUnmatchModalOpen} // @ts-ignore
        onOk={(reason, remark) => {
          setUnmatchReason({ reason, remark });
          setIsUnmatchModalOpen(false);
        }}
        onCancel={() => {
          setIsUnmatchModalOpen(false);
        }}
      />

      {/* {hasEmployerView ? (
        <Segmented
          defaultValue="Find a job"
          style={{ marginBottom: 12 }}
          onChange={(value) => {
            // setTalkFilter("talked")
            setHiringType(value as HiringType);
          }}
          options={["Find a job", "Find an artist"]}
        />
      ) : null} */}

      {/* <HomePageInfoCard
        profile={profile}
        companyInfo={companyInfo}
        isNewUser={isNewUser}
        handlePathChange={handlePathChange}
        isEmployeeView={isEmployeeView}
        hasEmployerView={hasEmployerView}
        // currentApplyRole={currentApplyRole}
        // onSwitchApplyRole={onSwitchApplyRole}
      /> */}

      {/* <NewUserModal
        open={newUserModalOpen}
        onCreate={onProfileCreate}
        onCancel={() => {
          setNewUserModalOpen(false);
        }}
      /> */}

      <div className="sticky top-0 z-10 px-4 pt-4 bg-white">
        <Tabs
          key="recruiter"
          defaultActiveKey="appliedComp"
          activeKey={talkFilterCompany as TalkFilterType}
          onChange={(key) => {
            setTalkFilterCompany(key as TalkFilterType);
            mutate({
              list: [],
              total: 0,
            });
          }}
        >
          <Tabs.TabPane tab="Applied" key="appliedComp" />
          {isCompany && <Tabs.TabPane tab="Received" key="applications" />}
        </Tabs>
      </div>

      {talkFilterCompany === "appliedComp"
        ? renderArtistApplied()
        : renderApplicants()}

      <div className="text-center">
        {loading && <Spin />}
        {loadingMore && <Spin tip="Loading more..." />}
        {noMore && <div>No more data</div>}
      </div>
    </div>
  );
};
