import { axiosInstance } from "@/utils/http";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useApiUrl } from "@refinedev/core";
import {
  Button,
  Card,
  Col,
  Image,
  Modal,
  Row,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatToMiddleStyle } from "../../utils/date";

const { Title, Text } = Typography;

interface CardData {
  id: string;
  name: string;
  description: string;
  progress_status: string;
  created_at: string;
  thumbnail_url: string;
  is_verified: boolean;
  is_violated: boolean;
  is_enabled: boolean;
  start_date: string;
  end_date: string;
  start_date_utc: string;
  end_date_utc: string;
}

export const EventPage: React.FC = () => {
  const apiUrl = useApiUrl();
  const TOKEN_KEY = "leyline-auth";
  const token = localStorage.getItem(TOKEN_KEY);
  const marketplaceUploadUrl = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

  const navigate = useNavigate();

  const [card, setCard] = useState<CardData | null>(null);

  const getStatusText = (card: CardData) => {
    if (card.is_enabled === false) {
      return "Terminated";
    }
    switch (card.progress_status) {
      case "IN_PROGRESS":
        return "Ongoing";
      case "NOT_STARTED":
        return "Not Started";
      case "FINISHED":
        return "Finished";
      default:
        return card.progress_status;
    }
  };

  const getStatusTagColor = (card: CardData) => {
    if (card.is_enabled === false) {
      return "red";
    }

    switch (card.progress_status) {
      case "IN_PROGRESS":
        return "blue";
      case "NOT_STARTED":
        return "green";
      case "FINISHED":
        return "gray";
      default:
        return "default";
    }
  };

  useEffect(() => {
    const projectId = sessionStorage.getItem("currentProjectId");
    if (projectId) {
      fetchProjectDetails(projectId);
    }
  }, []);

  const fetchProjectDetails = async (projectId: string) => {
    try {
      const response = await fetch(`${apiUrl}/projects/${projectId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        setCard(data);
        console.log("Project Details:", data);
      } else {
        console.error("Cannot fetch project details:", response.status, data);
      }
    } catch (error) {
      console.error("Error fetching project details:", error);
    }
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const showConfirmation = () => {
    setShowConfirmationModal(true);
  };

  const checkJobsDetailEmpty = async (projectId: string): Promise<boolean> => {
    try {
      const response = await fetch(`${apiUrl}/jobs/projects/${projectId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      return !data || data.length === 0;
    } catch (error) {
      console.error("Error checking job details:", error);
      return false;
    }
  };

  const handleTerminateOrRestart = async () => {
    const projectId = sessionStorage.getItem("currentProjectId");
    if (!projectId) {
      console.error("Project ID is not found");
      return;
    }

    if (card && card.is_enabled !== false) {
      const isEmpty = await checkJobsDetailEmpty(projectId);
      if (!isEmpty) {
        Modal.confirm({
          title: "Terminate Confirmation",
          content:
            "Terminating the project will close all open jobs. Are you sure you want to terminate?",
          onOk: () => handleConfirmation(),
          onCancel: () => {},
          centered: true,
        });
      } else {
        showConfirmation();
      }
    } else {
      showConfirmation();
    }
  };

  const handleConfirmation = async () => {
    setShowConfirmationModal(false);
    const projectId = sessionStorage.getItem("currentProjectId");
    if (!projectId) {
      console.error("Project ID is not found");
      return;
    }
    try {
      const isEnabling = card?.is_enabled === false;
      const projectStatus = isEnabling ? "open" : "closed";
      const response = await axiosInstance.put(
        `/projects/${projectId}/opening-status`,
        null,
        {
          params: {
            project_status: projectStatus,
          },
        },
      );

      if (response.status === 200) {
        message.success(
          `Project has been ${
            isEnabling ? "restarted" : "terminated"
          }. Redirecting to project management page...`,
          () => {
            navigate("/projects");
          },
        );
        fetchProjectDetails(projectId);
      } else {
        throw new Error(
          `Failed to ${isEnabling ? "restart" : "terminate"} the project: ${
            response.status
          }`,
        );
      }
    } catch (error) {
      console.error(
        `Error ${
          card?.is_enabled === false ? "restarting" : "terminating"
        } project:`,
        error,
      );
      message.error(
        `Failed to ${
          card?.is_enabled === false ? "restart" : "terminate"
        } the project.`,
      );
    }
  };

  return (
    <Row gutter={[16, 16]} className="w-full">
      {card && (
        <Col span={24} className="flex justify-center">
          <Card bordered className="rounded-lg w-full max-w-4xl mx-auto">
            <Button
              className="mb-4"
              type="text"
              onClick={() => navigate(-1)}
              icon={<ArrowLeftOutlined />}
            />

            <div className="flex flex-col">
              <div className="flex justify-center w-full">
                <Image
                  src={
                    card.thumbnail_url?.includes("https")
                      ? card.thumbnail_url
                      : `${marketplaceUploadUrl}${card.thumbnail_url}`
                  }
                  alt="Project Thumbnail"
                  className="rounded-lg mb-4"
                  preview={false}
                  width={300}
                  height={300}
                />
              </div>
              <Title level={4} className="text-center">
                {card.name}
              </Title>
              <Row justify="space-between" className="mb-4">
                <Text type="secondary">
                  {formatToMiddleStyle(card.start_date_utc)} ~{" "}
                  {formatToMiddleStyle(card.end_date_utc)}
                </Text>
                <Tag color={getStatusTagColor(card)}>{getStatusText(card)}</Tag>
              </Row>
              <Text className="block mt-10 mb-40">{card.description}</Text>
              <Text type="secondary" className="block text-center mb-4">
                Local Create Date: {formatToMiddleStyle(card.created_at)}
              </Text>
              <Row justify="space-between">
                <Button
                  type={card.is_enabled === false ? "primary" : "default"}
                  danger={card.is_enabled !== false}
                  onClick={handleTerminateOrRestart}
                  style={
                    card.is_enabled === false
                      ? { backgroundColor: "green", borderColor: "green" }
                      : {}
                  }
                >
                  {card.is_enabled === false ? "Restart" : "Terminate"}
                </Button>
                <Link to="/projects/edit-project" key="edit-project">
                  <Button type="primary">Edit</Button>
                </Link>
              </Row>
            </div>
          </Card>
        </Col>
      )}
      <Modal
        title={
          card?.is_enabled === false
            ? "Restart Confirmation"
            : "Terminate Confirmation"
        }
        visible={showConfirmationModal}
        onOk={handleConfirmation}
        onCancel={() => setShowConfirmationModal(false)}
        centered
      >
        {card?.is_enabled === false
          ? "Are you sure you want to restart the current project?"
          : "Are you sure you want to terminate the current project?"}
      </Modal>
    </Row>
  );
};

export default EventPage;
