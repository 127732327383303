import { UserType } from "@/__generated__/graphql";
import { NoDataIcon } from "@/components/customComponents/CustomIcons";
import PortfolioItem from "@/components/discover/homepage/PortfolioItem";
import { getMyArtworkListAPI } from "@/pages/discover/api";
import { ISearchMyArtworkList } from "@/pages/discover/types";
import useGlobalStore from "@/store";
import { artworkListLocalStorage } from "@/utils/artworkListLocalStorage";
import Icon from "@ant-design/icons";
import { useInfiniteScroll } from "ahooks";
import { Col, Row, Typography } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

interface IPersonalCenterCompanyPortfolioProps {
  containerRef: React.RefObject<HTMLDivElement>;
}

export const PersonalCenterCompanyPortfolio: React.FC<
  IPersonalCenterCompanyPortfolioProps
> = ({ containerRef }) => {
  const navigate = useNavigate();
  const { updateLoading } = useGlobalStore((state) => state);
  const [searchData, setSearchData] = useState<ISearchMyArtworkList>({
    page: 1,
    size: 10,
    sort: "LATEST",
  });
  const getArtistList = async (page: number) => {
    // if (!currentRole) return { list: [], nextPage: undefined };
    const response = await getMyArtworkListAPI(
      updateLoading,
      UserType.Company,
      {
        ...searchData,
        page,
      },
    );
    artworkListLocalStorage(response);
    return {
      list: response.list,
      nextPage: response.page < response.pages ? response.page + 1 : undefined,
    };
  };

  const {
    data: infiniteData,
    loadMore,
    loading,
    reload,
  } = useInfiniteScroll((d) => getArtistList(d ? d.nextPage : 1), {
    target: containerRef,
    isNoMore: (d) => !d?.nextPage,
    reloadDeps: [],
  });

  return (
    <Row className="flex flex-col">
      {infiniteData?.list.length === 0 ? (
        <Col className="flex flex-col justify-center items-center w-full h-full">
          <Icon className="text-[200px]" component={NoDataIcon} />
          <Typography.Text className="text-[#686868] text-base">
            No Data
          </Typography.Text>
        </Col>
      ) : (
        <Row className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
          {infiniteData?.list.map((artwork) => (
            <Link to={`/discover/portfolio/${artwork.id}`} key={artwork.id}>
              <PortfolioItem artworkItem={artwork} />
            </Link>
          ))}
        </Row>
      )}
    </Row>
  );
};
