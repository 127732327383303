import { UserType } from "@/__generated__/graphql";
import {
  CustomDropDown,
  CustomPopconfirm,
} from "@/components/customComponents";
import { SelectJob } from "@/components/discover/workDetail/SelectJob";
import { createLikeAPI, removeCommentAPI } from "@/pages/discover/api";
import { ICommentItem, ISetReplyCommentInfo } from "@/pages/discover/types";
import useGlobalStore from "@/store";
import { getImgUrl } from "@/utils/common";
import {
  CaretRightOutlined,
  EllipsisOutlined,
  LikeFilled,
  MessageFilled,
} from "@ant-design/icons";
import {
  Avatar,
  Col,
  ConfigProvider,
  Dropdown,
  Popconfirm,
  Row,
  Space,
  Tag,
  Typography,
  message,
} from "antd";
import { formatDistanceToNow, parseISO } from "date-fns";
import { Link } from "react-router-dom";

interface ICommentItemProps {
  setReloadState: (state: string) => void;
  getCommentCount: () => void;
  artworkUserId: string;
  replyState?: boolean;
  user_id: string;
  commentItem: ICommentItem;
  rootId?: string;
  commentListData: ICommentItem[];
  setIsCommentInputVisible: (state: boolean) => void;
  setCommentReplyState: (state: boolean) => void;
  setReplyCommentInfo: (info: ISetReplyCommentInfo) => void;
}

export const CommentItem: React.FC<ICommentItemProps> = ({
  setReloadState,
  getCommentCount,
  artworkUserId,
  replyState,
  user_id,
  commentItem,
  rootId,
  commentListData,
  setIsCommentInputVisible,
  setCommentReplyState,
  setReplyCommentInfo,
}) => {
  const { currentRole } = useGlobalStore((state) => state);
  const formatDateToNow = (dateString: string) => {
    return formatDistanceToNow(parseISO(dateString), { addSuffix: true });
  };
  const onCommentLike = async (commentId: string, parentId: string) => {
    if (!currentRole) return;
    const response = await createLikeAPI(commentId, "COMMENT", currentRole);
    if (response.id) {
      setReloadState(`like_${commentId}`);
    } else {
      message.error("Failed to like comment");
    }
  };

  const onCommentDelete = async (commentId: string) => {
    const response = await removeCommentAPI(commentId);
    if (response === "success") {
      setReloadState(`delete_${commentId}`);
      getCommentCount();
    } else {
      message.error("Failed to delete comment");
    }
  };

  const onCommentReply = (
    commentId: string,
    userName: string,
    content: string,
  ) => {
    setIsCommentInputVisible(true);
    setCommentReplyState(true);
    setReplyCommentInfo({
      parent_id: commentId,
      user_name: userName,
      content: content,
    });
  };

  return (
    <Row className="flex flex-row gap-2 flex-1">
      <Link
        to={`/discover/author/${commentItem.user_id}?role=${commentItem.user_type}`}
      >
        <Avatar
          className="object-cover"
          size={replyState ? 26 : 40}
          src={
            commentItem.user_type === UserType.Company
              ? commentItem.companyInfo.logo_url
                ? getImgUrl(commentItem.companyInfo.logo_url)
                : "https://placehold.co/64x64"
              : commentItem.userInfo.avatar_url
                ? getImgUrl(commentItem.userInfo.avatar_url)
                : "https://placehold.co/64x64"
          }
        />
      </Link>
      <Row className="flex flex-col flex-1">
        <Col className="flex flex-row justify-between">
          <Col className="flex flex-row gap-4 items-center">
            <Typography.Text className="text-white text-opacity-85 text-base">
              {commentItem.parent_id === rootId ||
              commentItem.parent_comment_author === null ? (
                commentItem.user_type === UserType.Company ? (
                  commentItem.companyInfo.shortname
                ) : (
                  commentItem.userInfo.user_name
                )
              ) : (
                <>
                  {commentItem.user_type === UserType.Company
                    ? commentItem.companyInfo.shortname
                    : commentItem.userInfo.user_name}
                  <CaretRightOutlined className="text-[#666666]" />
                  {commentItem.parent_comment_author}
                </>
              )}
            </Typography.Text>
            {commentItem.user_id === artworkUserId && (
              <Tag color="#00ADAE" className="px-1.5 rounded-full">
                Author
              </Tag>
            )}
          </Col>
          {user_id === commentItem.user_id || user_id === artworkUserId ? (
            <ConfigProvider
              theme={{
                components: {
                  Dropdown: {
                    paddingBlock: undefined,
                  },
                },
              }}
            >
              <CustomDropDown
                menu={{
                  items: [
                    {
                      key: "1",
                      label: (
                        <CustomPopconfirm
                          title="Are you sure you want to delete this comment?"
                          placement="left"
                          onConfirm={() => onCommentDelete(commentItem.id)}
                          className="opacity-50 text-white flex gap-2"
                        >
                          <Space className="text-[#606264] text-base bg-[#1D1F22] py-0.5 px-1.5">
                            <span>Delete</span>
                          </Space>
                        </CustomPopconfirm>
                      ),
                    },
                  ],
                }}
                placement="bottom"
              >
                <EllipsisOutlined className="text-white" />
              </CustomDropDown>
            </ConfigProvider>
          ) : (
            <EllipsisOutlined className="text-white" />
          )}
        </Col>
        <Typography.Paragraph
          className="text-[#606264] text-base w-full !mb-0.5"
          ellipsis={{ rows: 1 }}
        >
          {commentItem.user_type === UserType.Company
            ? `${commentItem.companyInfo.industry} | ${commentItem.companyInfo.scale}`
            : commentItem.userInfo.introduction}
        </Typography.Paragraph>
        <Col>
          <Typography.Paragraph
            className="text-white text-opacity-85 text-base w-full !mb-0.5"
            ellipsis={{ rows: 3 }}
          >
            {commentItem.content}
          </Typography.Paragraph>
        </Col>
        {commentItem.job_id && <SelectJob jobId={commentItem.job_id} />}
        <Col className="flex flex-row justify-between text-[#606264] text-base">
          <Col>
            <span>
              {formatDateToNow(
                commentItem.created_at ?? new Date().toISOString(),
              )}
            </span>
          </Col>
          <Col className="flex flex-row gap-2">
            <Col
              onClick={() =>
                onCommentLike(commentItem.id, commentItem.parent_id)
              }
              className="cursor-pointer"
            >
              <LikeFilled
                className={`${commentItem.is_liked && "text-[#fe2c55]"}`}
              />{" "}
              <span>{commentItem.liked_count}</span>
            </Col>
            <Col
              className="cursor-pointer"
              onClick={() =>
                onCommentReply(
                  commentItem.id,
                  commentItem.user_type === UserType.Company
                    ? commentItem.companyInfo.shortname
                    : commentItem.userInfo.user_name,
                  commentItem.content,
                )
              }
            >
              <MessageFilled /> <span>Reply</span>
            </Col>
          </Col>
        </Col>
      </Row>
    </Row>
  );
};
