import { ErrorBoundary } from "@sentry/react";
import { Alert, Button } from "antd";
import React from "react";

interface ErrorBoundaryWrapperProps {
  fallback?: (errorProps: {
    error: Error;
    componentStack: string;
    resetError: () => void;
  }) => React.ReactElement;
  children: React.ReactNode;
}

const ErrorBoundaryWrapper: React.FC<ErrorBoundaryWrapperProps> = ({
  fallback,
  children,
}) => {
  return (
    <ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <React.Fragment>
          <Alert
            message={error.toString()}
            showIcon
            description={React.createElement(
              "pre",
              {
                style: {
                  fontSize: "0.9em",
                  overflowX: "auto",
                },
              },
              componentStack,
            )}
            type="error"
            action={
              <Button
                size="large"
                danger
                onClick={() => {
                  resetError();
                }}
              >
                Click to reset the app!
              </Button>
            }
          />
        </React.Fragment>
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
