import {
  UserType,
  useSetRemoteCustomDataConversationMutation,
} from "@/__generated__/graphql";
import {
  CompanyIcon,
  DefaultUserIcon,
  FollowIcon,
  FollowingIcon,
} from "@/components/customComponents/CustomIcons";
import { JobPanel } from "@/components/discover/workDetail/JobPanel";
import { IIdentity, IProfile } from "@/interfaces";
import {
  createFollowAPI,
  getFollowCountAPI,
  isFollowingAPI,
  removeFollowAPI,
} from "@/pages/discover/api";
import { ICompany, IJobData } from "@/pages/discover/types";
import useGlobalStore from "@/store";
import { getImgUrl } from "@/utils/common";
import { formatNumber } from "@/utils/formatNumber";
import { setConversationCustomData } from "@/utils/imUtils";
import Icon, {
  ArrowLeftOutlined,
  CloseOutlined,
  EditOutlined,
  EnvironmentFilled,
  MessageFilled,
} from "@ant-design/icons";
import { useGetIdentity } from "@refinedev/core";
import { Avatar, Button, Col, Image, Row, Space, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

interface IArtistIntroductionProps {
  artistUserInfo: IProfile | ICompany;
  artistId: string;
  user_role: string;
}

export const ArtistIntroduction: React.FC<IArtistIntroductionProps> = ({
  artistUserInfo,
  artistId,
  user_role,
}) => {
  const { currentRole, chatClient } = useGlobalStore((state) => state);
  const navigate = useNavigate();
  const { data: user } = useGetIdentity<IIdentity>();
  const [isFollowed, setIsFollowed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [jobPanelVisible, setJobPanelVisible] = useState(false);
  const [selectJob, setSelectJob] = useState<IJobData>();
  const [setRemoteCustomDataMutation] =
    useSetRemoteCustomDataConversationMutation();
  const [followCount, setFollowCount] = useState({
    follower_count: 0,
    followee_count: 0,
  });
  const user_id = user?.id || "";

  const getFollowCount = useCallback(async () => {
    if (!user_role) return;
    const response = await getFollowCountAPI(artistId, user_role);
    setFollowCount(response);
  }, [artistId, user_role]);

  const getIsFollowing = useCallback(async () => {
    if (!currentRole) return;
    const response = await isFollowingAPI(currentRole, artistId, user_role);
    setIsFollowed(response);
  }, [artistId, currentRole, user_role]);

  useEffect(() => {
    getFollowCount();
  }, [getFollowCount]);

  useEffect(() => {
    if (user_id !== artistId) {
      getIsFollowing();
    }
  }, [getIsFollowing, user_id, artistId]);

  const onUnFollowing = async (id: string) => {
    if (!currentRole) return;
    await removeFollowAPI(currentRole, id, user_role);
    setIsFollowed(false);
  };

  const onFollowing = async (id: string) => {
    if (!currentRole) return;
    await createFollowAPI(currentRole, id, user_role);
    setIsFollowed(true);
  };

  const goToChatPage = async (selectJob?: IJobData) => {
    const opponentImId = `${artistId}_${user_role?.toLowerCase()}`;
    await setConversationCustomData(
      chatClient,
      setRemoteCustomDataMutation,
      opponentImId,
      {
        jInfo: {
          // TODO: Just fill in these three fields
          id: selectJob?.id, // job_id,
          titl: selectJob?.title, // job_title,
          pName: selectJob?.project_name, // project_name,
          role: 2,
        },
      },
    );
    navigate("/enterprises/chat", {
      state: {
        opponentImId,
        activeTab: 2,
      },
    });
  };

  useEffect(() => {
    if (selectJob !== undefined) {
      goToChatPage(selectJob);
    }
  }, [selectJob, goToChatPage]);

  return (
    <Row
      className="w-full flex flex-row gap-5 rounded-xl py-[30px] px-8 relative"
      style={{
        border: "0.4px solid rgba(180, 205, 210, 0.54)",
        backdropFilter: "blue(10px)",
        background:
          "linear-gradient(91deg, rgba(0, 0, 0, 0.80) 18.06%, rgba(16, 16, 51, 0.30) 82.31%",
      }}
    >
      {user_role === UserType.Company
        ? (artistUserInfo as ICompany).cover_url && (
            <Col className="absolute top-0 left-0 rounded-xl w-full h-full">
              <Image
                preview={false}
                className="rounded-xl opacity-40"
                width="100%"
                height="100%"
                src={getImgUrl((artistUserInfo as ICompany).cover_url) || ""}
              />
            </Col>
          )
        : (artistUserInfo as IProfile).page_cover_url && (
            <Col className="absolute top-0 left-0 rounded-xl w-full h-full">
              <Image
                preview={false}
                className="rounded-xl opacity-40"
                width="100%"
                height="100%"
                src={
                  getImgUrl((artistUserInfo as IProfile).page_cover_url) || ""
                }
              />
            </Col>
          )}
      <Col className="absolute top-4 left-5 cursor-pointer">
        <ArrowLeftOutlined
          className="text-white"
          onClick={() => navigate(-1)}
        />
      </Col>
      <Col>
        <div className="relative">
          <Avatar
            size={120}
            className={`object-cover ${
              user_role === UserType.Company &&
              "border-[3px] border-solid border-[#03B3B4]"
            }`}
            src={
              getImgUrl(
                user_role === UserType.Company
                  ? (artistUserInfo as ICompany).logo_url
                  : (artistUserInfo as IProfile).avatar_url,
              ) || <DefaultUserIcon width="120px" height="120px" />
            }
          />
          {user_role === UserType.Company && (
            <Space className="absolute z-10 left-1/2 top-[-12%] transform -translate-x-1/2">
              <CompanyIcon width="36px" height="36px" />
            </Space>
          )}
        </div>
      </Col>
      {jobPanelVisible && (
        <div className="absolute top-16 left-40 z-50">
          <JobPanel
            setJobPanelVisible={setJobPanelVisible}
            setSelectJob={setSelectJob}
          />
        </div>
      )}
      <Col className="w-full text-base text-[#fff9] flex flex-col flex-1 overflow-hidden pt-1">
        <Col className="text-base font-bold flex flex-row">
          <Typography.Paragraph
            ellipsis={{ rows: 1 }}
            className="text-2xl text-white !mb-0"
          >
            {user_role === UserType.Company
              ? (artistUserInfo as ICompany).name
              : (artistUserInfo as IProfile).user_name}
          </Typography.Paragraph>
          <div className="text-[#00ADAE] text-base ml-4 flex gap-2 items-center">
            {user_id !== artistId && (
              <>
                {currentRole === UserType.Company && (
                  <MessageFilled onClick={() => setJobPanelVisible(true)} />
                )}
                {isFollowed ? (
                  <Space
                    className="text-[#00ADAE] text-base flex gap-1 items-center cursor-pointer"
                    onClick={() => onUnFollowing(artistId)}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  >
                    {isHovered ? (
                      <>
                        <CloseOutlined />
                        <span className="text-[#00ADAE] custom-alibaba-font">
                          UnFollow
                        </span>
                      </>
                    ) : (
                      <>
                        <Icon component={FollowingIcon} />
                        <span className="text-[#00ADAE] custom-alibaba-font">
                          Following
                        </span>
                      </>
                    )}
                  </Space>
                ) : (
                  <Space
                    className="text-[#00ADAE] text-base flex gap-1 items-center cursor-pointer"
                    onClick={() => onFollowing(artistId)}
                  >
                    <Icon component={FollowIcon} />
                    <Typography.Text className="text-[#00ADAE] custom-alibaba-font">
                      Follow
                    </Typography.Text>
                  </Space>
                )}
              </>
            )}
          </div>
          {user_id === artistId && user_role === currentRole && (
            <div className="flex-1 float-right">
              {user_role === UserType.Company ? (
                <Link to={"/companies"} className="float-right">
                  <Button>
                    <EditOutlined />
                    <span>Edit Cover</span>
                  </Button>
                </Link>
              ) : (
                <Link to={"/users/edit"} className="float-right">
                  <Button>
                    <EditOutlined />
                    <span>Edit Cover</span>
                  </Button>
                </Link>
              )}
            </div>
          )}
        </Col>
        <Col className="text-base overflow-hidden whitespace-nowrap text-ellipsis opacity-85 text-white">
          {user_role === UserType.Company
            ? (artistUserInfo as ICompany).industry
              ? (artistUserInfo as ICompany).scale
                ? `${(artistUserInfo as ICompany).industry} | ${
                    (artistUserInfo as ICompany).scale
                  }`
                : (artistUserInfo as ICompany).industry
              : (artistUserInfo as ICompany).scale
                ? (artistUserInfo as ICompany).scale
                : ""
            : (artistUserInfo as IProfile).introduction}
        </Col>
        <Col className="flex flex-row gap-1">
          {user_role === UserType.Company
            ? (artistUserInfo as ICompany).country && (
                <>
                  <EnvironmentFilled />
                  <Typography.Text className="text-[#999] custom-alibaba-font">
                    {(artistUserInfo as ICompany).country}
                  </Typography.Text>
                </>
              )
            : ((artistUserInfo as IProfile).city ||
                (artistUserInfo as IProfile).country) && (
                <>
                  <EnvironmentFilled />
                  <Typography.Text className="text-[#999] custom-alibaba-font">
                    {(artistUserInfo as IProfile).city
                      ? (artistUserInfo as IProfile).country
                        ? `${(artistUserInfo as IProfile).city}, ${
                            (artistUserInfo as IProfile).country
                          }`
                        : (artistUserInfo as IProfile).city
                      : (artistUserInfo as IProfile).country}
                  </Typography.Text>
                </>
              )}
        </Col>
        <Col className="text-base text-[#606264] flex gap-1 items-center mt-2.5">
          <Typography.Text className="text-[#606264] text-base custom-alibaba-font">
            {formatNumber(followCount.followee_count)} Following{" "}
            {formatNumber(followCount.follower_count)} Followers
          </Typography.Text>
        </Col>
      </Col>
    </Row>
  );
};
