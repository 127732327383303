import { NoDataIcon } from "@/components/customComponents/CustomIcons";
import PortfolioItem from "@/components/discover/homepage/PortfolioItem";
import { IIdentity, IProfile } from "@/interfaces";
import {
  getArtworkListAPI,
  getAuthArtworkListAPI,
  useUserProfile,
} from "@/pages/discover/api";
import { IArtworkItem, ISearchArtworkLIst } from "@/pages/discover/types";
import useGlobalStore from "@/store";
import { artworkListLocalStorage } from "@/utils/artworkListLocalStorage";
import Icon from "@ant-design/icons";
import { useGetIdentity } from "@refinedev/core";
import { useInfiniteScroll } from "ahooks";
import { Typography, message } from "antd";
import { debounce, upperCase } from "lodash";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

interface IPortfolioProps {
  portfolioFilter: string;
  containerRef: React.RefObject<HTMLDivElement>;
  artworkSearch: string;
}

const Portfolio: React.FC<IPortfolioProps> = ({
  portfolioFilter,
  containerRef,
  artworkSearch,
}) => {
  const { data: user } = useGetIdentity<IIdentity>();
  const { data } = useUserProfile(user?.id || "");
  const navigate = useNavigate();
  const { currentRole, updateLoading } = useGlobalStore((state) => state);

  const [searchArtworkData, setSearchArtworkData] =
    useState<ISearchArtworkLIst>({
      sort: upperCase(portfolioFilter),
      size: 10,
      page: 1,
    });

  const debouncedSearch = debounce((searchTerm) => {
    setSearchArtworkData({ ...searchArtworkData, search: searchTerm });
  }, 500); // 300ms debounce time
  // biome-ignore lint:
  useEffect(() => {
    debouncedSearch(artworkSearch);

    // Cleanup the debounce function on component unmount
    return () => {
      debouncedSearch.cancel();
    };
  }, [artworkSearch]);

  useEffect(() => {
    if (portfolioFilter === "Following" && !data) {
      message.error("Please login to view following artists' artwork");
      navigate("/login");
    } else {
      setSearchArtworkData({
        sort: upperCase(portfolioFilter),
        size: 10,
        page: 1,
      });
    }
  }, [portfolioFilter, data, navigate]);

  const getArtistList = async (page: number) => {
    if (!currentRole) return { list: [], nextPage: undefined };
    const response =
      searchArtworkData.sort === "FOLLOWING"
        ? await getAuthArtworkListAPI(updateLoading, currentRole, {
            ...searchArtworkData,
            page,
          })
        : await getArtworkListAPI(updateLoading, {
            ...searchArtworkData,
            page,
          });
    artworkListLocalStorage(response);
    return {
      list: response.list,
      nextPage: response.page < response.pages ? response.page + 1 : undefined,
    };
  };

  const { data: infiniteData } = useInfiniteScroll(
    async (d) => {
      const response = await getArtistList(d ? d.nextPage : 1);
      if (infiniteData) {
        infiniteData.list = infiniteData.list.filter(
          (artwork) =>
            !response.list.some(
              (newArtwork: IArtworkItem) => newArtwork.id === artwork.id,
            ),
        );
      }
      return response;
    },
    {
      target: containerRef,
      isNoMore: (d) => !d?.nextPage,
      reloadDeps: [currentRole, searchArtworkData],
    },
  );

  return (
    <div className="overflow-auto">
      {!infiniteData?.list?.length ? (
        <div className="flex flex-col justify-center items-center w-full h-full pt-16">
          <Icon className="text-[200px]" component={NoDataIcon} />
          <Typography.Text className="text-[#686868] text-base">
            No Data
          </Typography.Text>
        </div>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
          {infiniteData?.list?.map(
            (artwork: IArtworkItem) =>
              artwork.is_published && (
                <Link to={`/discover/portfolio/${artwork.id}`} key={artwork.id}>
                  <PortfolioItem artworkItem={artwork} />
                </Link>
              ),
          )}
        </div>
      )}
    </div>
  );
};

export default Portfolio;
