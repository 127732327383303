import { NoDataIcon } from "@/components/customComponents/CustomIcons";
import { SelectFollowOption } from "@/components/discover/artistDetail/SelectFollowOption";
import AuthorItem from "@/components/discover/homepage/AuthorItem";
import { getFollowersAPI, getFollowingAPI } from "@/pages/discover/api";
import { IFollowRequestData } from "@/pages/discover/types";
import useGlobalStore from "@/store";
import Icon from "@ant-design/icons";
import { useInfiniteScroll } from "ahooks";
import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface IPortFolioComProps {
  containerRef: React.RefObject<HTMLDivElement>;
  author_id: string;
  user_role: string;
}

export const ArtistFollow: React.FC<IPortFolioComProps> = ({
  containerRef,
  author_id,
  user_role,
}) => {
  const { currentRole, updateLoading } = useGlobalStore((state) => state);
  const [followState, setFollowState] = useState<string>("following");
  const [requestData, setRequestData] = useState<IFollowRequestData>({
    target_id: author_id,
    target_role: user_role,
    size: 10,
    page: 1,
  });

  const getFollowingList = async (page: number) => {
    if (!currentRole || !author_id || !user_role)
      return { list: [], nextPage: undefined };
    const response = await getFollowingAPI(updateLoading, currentRole, {
      ...requestData,
      page,
    });
    return {
      list: response.list,
      nextPage: response.page < response.pages ? response.page + 1 : undefined,
    };
  };

  const getFollowersList = async (page: number) => {
    if (!currentRole || !author_id || !user_role)
      return { list: [], nextPage: undefined };
    const response = await getFollowersAPI(updateLoading, currentRole, {
      ...requestData,
      page,
    });
    return {
      list: response.list,
      nextPage: response.page < response.pages ? response.page + 1 : undefined,
    };
  };

  const {
    data: infiniteData,
    loadMore,
    loading,
    reload,
  } = useInfiniteScroll(
    (d) =>
      followState === "followers"
        ? getFollowersList(d ? d.nextPage : 1)
        : getFollowingList(d ? d.nextPage : 1),
    {
      target: containerRef,
      isNoMore: (d) => !d?.nextPage,
      reloadDeps: [currentRole, followState],
    },
  );

  return (
    <Row className="flex flex-col">
      <Col>
        <SelectFollowOption
          followState={followState}
          setFollowState={setFollowState}
        />
      </Col>
      <Col className="mt-6">
        {infiniteData?.list.length === 0 ? (
          <Col className="flex flex-col justify-center items-center w-full h-full">
            <Icon className="text-[200px]" component={NoDataIcon} />
            <Typography.Text className="text-[#686868] text-base">
              No Data
            </Typography.Text>
          </Col>
        ) : (
          <Row className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
            {infiniteData?.list.map((author) => (
              <Link
                to={`/discover/author/${author.id}?role=${author.role}`}
                key={author.id + author.role}
              >
                <AuthorItem authorItem={author} comState="follow" />
              </Link>
            ))}
          </Row>
        )}
      </Col>
    </Row>
  );
};
