import { padLeftImgUrl } from "@/utils/common";
import { useOne } from "@refinedev/core";
import { Conversation } from "@tencentcloud/chat";
import { Avatar, Divider, Space, Spin } from "antd";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { UserType } from "../../../__generated__/graphql";
import { useApplicantButtons } from "../../../components/users/hooks/useApplicantButtons";
import { useAppliedButtons } from "../../../components/users/hooks/useAppliedButtons";
import useGlobalStore from "../../../store";

interface ChatHeaderProps {
  conversation: Conversation;
}
type partyBType = "Company" | "Individual";

const handleUserIdAndRole = (imId: string) => {
  const appliCompSuffix = "_company";
  const appliIndiSuffix = "_individual";
  let suitableSuffix = appliIndiSuffix;
  let suitableRole = "Individual";

  if (imId.indexOf(appliCompSuffix) > -1) {
    suitableSuffix = appliCompSuffix;
    suitableRole = "Company";
  }
  const [userId] = imId.split(suitableSuffix);
  return { userId, role: suitableRole };
};
const baseConfigOption = {
  hideTip: true,
  hideTalkBtn: true,
};

const CustomChatHeader = (props: ChatHeaderProps) => {
  const { currentRole, chatClient } = useGlobalStore((state) => state);
  const { conversation } = props;
  const { userProfile, customData, conversationID } = conversation;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const [jobInfo, setJobInfo] = useState<any>();
  console.log("conversation data:", conversation);

  const { userId, role } = handleUserIdAndRole(userProfile.userID);
  const isPartyA = useMemo(() => {
    const jInfo = JSON.parse(customData || "{}").jInfo;
    return currentRole === UserType.Company ? jInfo?.role === 2 : false;
  }, [currentRole, customData]);

  const updateJobInfo = useCallback((custom_data: string) => {
    try {
      setJobInfo(JSON.parse(custom_data || "{}")?.jInfo);
      return true;
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (customData) {
      const isSuccess = updateJobInfo(customData);
      if (isSuccess) return;
    } else if (!chatClient) return;
    // Bottom up plan
    (async () => {
      const res = await chatClient?.getConversationProfile(conversationID);
      if (res.code === 0) {
        const {
          conversation: { customData: _customData },
        } = res.data;
        updateJobInfo(_customData);
      }
    })();
  }, [chatClient, customData, conversationID, updateJobInfo]);

  const {
    data: dataA,
    isLoading: isLoadingA,
    refetch: refetchA,
  } = useOne({
    resource: "applications/company",
    id: `${jobInfo?.id}?applicant_user_id=${userId}&role=${role}`,
    queryOptions: {
      cacheTime: 0,
      enabled: !!currentRole && !!jobInfo?.id && isPartyA,
    },
  });

  const {
    data: dataB,
    isLoading: isLoadingB,
    refetch: refetchB,
  } = useOne({
    resource: "applications/applicant",
    id: `${jobInfo?.id}?role=${
      currentRole === UserType.Company ? "Company" : "Individual"
    }`,
    queryOptions: {
      cacheTime: 0,
      enabled: !!currentRole && !!jobInfo?.id && !isPartyA,
    },
  });

  const { data: jobData } = useOne({
    resource: "jobs",
    id: jobInfo?.id,
    queryOptions: {
      enabled: !!jobInfo?.id,
      cacheTime: 0,
      // @ts-ignore
      select(data) {
        return data?.data;
      },
    },
  });

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const applyInfo: any = useMemo(
    () => (isPartyA ? dataA?.data : dataB?.data),
    [dataA, dataB, isPartyA],
  );
  const aConfigOption = useMemo(
    () => ({
      ...baseConfigOption,
      userId,
      role: role as partyBType,
      companyId: applyInfo?.company_id,
    }),
    [userId, role, applyInfo?.company_id],
  );
  const bConfigOption = useMemo(
    () => ({ ...baseConfigOption, jobId: jobInfo?.id }),
    [jobInfo?.id],
  );
  const aButtonAndModals = useApplicantButtons(
    applyInfo,
    refetchA,
    aConfigOption,
    isPartyA,
  );
  const bButtonAndModals = useAppliedButtons(
    applyInfo,
    refetchB,
    bConfigOption,
    !isPartyA,
    // @ts-ignore
    jobData,
  );
  const isLoading = isPartyA ? isLoadingA : isLoadingB;

  return (
    <Spin spinning={!!jobInfo?.id && isLoading}>
      <div className="border-solid border-slate-100 border-b border-t p-3">
        <div className="">
          <div className="header-content flex justify-between">
            <div className="flex justify-start">
              {userProfile.avatar ? (
                <Avatar className="mr-2" src={userProfile.avatar} />
              ) : (
                <Avatar className="mr-2">{userProfile.nick}</Avatar>
              )}
              <h3 className="title text-base">
                {userProfile.nick} ｜ {jobInfo?.pName}
              </h3>
            </div>
            <div className="header-handle text-right">
              Position:{" "}
              <span className="text-lg font-bold">{jobInfo?.titl}</span>
            </div>
          </div>
          <Divider orientation="left" className="!mt-0 !mb-0">
            <span className="text-xs font-light">
              The latest application information.
            </span>
          </Divider>
          <div className="flex justify-between items-center">
            {applyInfo ? (
              <div className="truncate">
                {isPartyA ? (
                  <Space
                    size={0}
                    split={
                      <Divider className="border-gray-400" type="vertical" />
                    }
                  >
                    <span>Payment Method: {applyInfo?.payment_method}</span>
                    {applyInfo?.payment_method === "Negotiable" ? null : (
                      <>
                        <span>
                          {applyInfo?.count} {applyInfo?.count_unit}
                        </span>
                        <span>
                          Unit price: {applyInfo?.currency || "USD"}
                          &nbsp;{applyInfo?.rate?.toFixed(2)}{" "}
                          {applyInfo?.rate_unit}
                        </span>
                      </>
                    )}
                    <span>
                      Total: {applyInfo?.currency || "USD"}
                      &nbsp;{applyInfo?.total_cost?.toFixed(2)}
                    </span>
                    <span>
                      {applyInfo?.answer_url ? (
                        <a
                          style={{ color: "#147aff" }}
                          href={padLeftImgUrl(applyInfo?.answer_url)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View test
                        </a>
                      ) : (
                        "No answer submitted"
                      )}
                    </span>
                  </Space>
                ) : (
                  <>
                    Payment Method:{" "}
                    {applyInfo?.payment_method === "Negotiable" ? (
                      applyInfo?.payment_method
                    ) : (
                      <Space
                        size={0}
                        split={
                          <Divider
                            className="border-gray-400"
                            type="vertical"
                          />
                        }
                      >
                        <span>{applyInfo?.payment_method}</span>
                        <span>
                          {applyInfo?.count} {applyInfo?.count_unit}
                        </span>
                        <span>
                          Unit price: {applyInfo?.currency || "USD"}
                          {applyInfo?.rate} {applyInfo?.rate_unit}
                        </span>
                        <span>
                          Total: {applyInfo?.currency || "USD"}
                          {applyInfo?.total_cost}
                        </span>
                        {applyInfo?.answer_url ? (
                          <a
                            style={{ color: "#147aff" }}
                            href={padLeftImgUrl(applyInfo?.answer_url)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View test
                          </a>
                        ) : null}
                      </Space>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className="pl-4">No application information.</div>
            )}
            <div>{aButtonAndModals || bButtonAndModals}</div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default memo(CustomChatHeader);
