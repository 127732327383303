import {
  CompanyIcon,
  DefaultUserIcon,
} from "@/components/customComponents/CustomIcons";
import { IIdentity } from "@/interfaces";
import { ICompanyInfo } from "@/pages/companies/type";
import { getFollowCountAPI } from "@/pages/discover/api";
import useGlobalStore from "@/store";
import { formatNumber } from "@/utils/formatNumber";
import { axiosInstance } from "@/utils/http";
import { EditOutlined, EnvironmentFilled } from "@ant-design/icons";
import { useApiUrl, useGetIdentity } from "@refinedev/core";
import {
  Avatar,
  Button,
  Col,
  Image,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
  Upload,
  message,
} from "antd";
import ImgCrop from "antd-img-crop";
import { RcFile } from "antd/es/upload";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getImgUrl } from "../../../utils/common";
import { isEmptyExtendedInfo } from "../utils";

interface Props {
  companyInfo: ICompanyInfo | undefined;
  readonly?: boolean;
  refetchData?: () => void;
}

const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;
const marketplaceUploadUrl = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;
const { Text } = Typography;
const defaultAvatar = "https://placehold.jp/150x150.png";
const defaultCover =
  "https://placehold.jp/c2bcbc/ffffff/1600x140.png?text=PageCover";
const TOKEN_KEY = "leyline-auth";

const getStatusText = (status: string) => {
  switch (status) {
    case "APPROVED":
      return "Verified";
    case "PENDING":
      return "Pending";
    case "UNSUBMITTED":
      return "Incomplete";
    case "DENIED":
      return "Unverified";
    default:
      return "Unknown";
  }
};
const getTagColor = (status: string) => {
  switch (status) {
    case "APPROVED":
      return "green";
    case "UNSUBMITTED":
      return "gray";
    case "PENDING":
      return "yellow";
    case "DENIED":
      return "red";
    default:
      return "default";
  }
};

const CompanyImgInfo = ({ companyInfo, readonly, refetchData }: Props) => {
  const { currentRole } = useGlobalStore((state) => state);
  const { extended_info } = companyInfo ?? { extended_info: null };
  const navigate = useNavigate();
  const apiUrl = useApiUrl();
  const token = localStorage.getItem(TOKEN_KEY);
  const [followCount, setFollowCount] = useState({
    follower_count: 0,
    followee_count: 0,
  });
  const { data: user } = useGetIdentity<IIdentity>();
  const user_id = user?.id || "";
  const coverImageUrl = useMemo(() => {
    return extended_info?.cover_url
      ? `${IMG_PREFIX}${extended_info.cover_url}`
      : defaultCover;
  }, [extended_info?.cover_url]);
  const extendedInfoIsEmpty = extended_info
    ? isEmptyExtendedInfo(extended_info)
    : true;

  const handleCoverUpload = (file: RcFile) => {
    uploadCoverImage(file)
      .then((bannerId) => {
        if (bannerId) {
          const newBannerUrl = `${marketplaceUploadUrl}${bannerId}`;
          // setCoverImageUrl(newBannerUrl);
          return updateCompanyInfo(bannerId);
        }
        return Promise.reject("Upload failed");
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const getFollowCount = useCallback(async () => {
    if (!currentRole) return;
    const response = await getFollowCountAPI(user_id, currentRole);
    setFollowCount(response);
  }, [user_id, currentRole]);

  useEffect(() => {
    getFollowCount();
  }, [getFollowCount]);

  const uploadCoverImage = async (file: RcFile) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axiosInstance.put(
        `${apiUrl}/companies/${companyInfo?.id}/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        },
      );
      if (response.status === 200) {
        return response.data.id;
      }
      message.error("Failed to upload cover image");
      return null;
    } catch (error) {
      console.error("Error uploading cover image:", error);
      return null;
    }
  };

  const updateCompanyInfo = async (bannerUrl: string) => {
    const updateData = {
      cover_url: bannerUrl,
    };

    try {
      const updateResponse = await axiosInstance.put(
        `/companies/${companyInfo?.id}/cover`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (updateResponse.status === 200) {
        message.success("Company cover updated successfully.");
        refetchData?.();
        return true;
      }
      console.error("Failed to update company information.");
      return false;
    } catch (error) {
      console.error("Error updating company information:", error);
      return false;
    }
  };

  return (
    <div
      className="mb-3 p-5 text-white w-full h-[230px] relative rounded-lg"
      style={{
        background:
          "linear-gradient(91deg, rgba(0, 0, 0, 0.80) 18.06%, rgba(16, 16, 51, 0.30) 82.31%",
      }}
    >
      {coverImageUrl && (
        <Col className="absolute top-0 left-0 rounded-xl w-full h-full">
          <Image
            preview={false}
            className="rounded-xl opacity-40"
            width="100%"
            height="100%"
            src={getImgUrl(coverImageUrl?.toString()) || ""}
          />
        </Col>
      )}
      {!readonly && (
        <>
          <ImgCrop aspect={4 / 1}>
            <Upload
              name="cover"
              showUploadList={false}
              beforeUpload={handleCoverUpload}
              customRequest={() => {}}
              accept="image/*"
            >
              <Button
                style={{
                  position: "absolute",
                  top: 30,
                  right: 30,
                  zIndex: 100,
                }}
                icon={<EditOutlined />}
              >
                Edit Cover
              </Button>
            </Upload>
          </ImgCrop>
          <Button onClick={() => navigate(-1)}>Back</Button>
        </>
      )}
      <Row align="middle">
        <Col span={2} />
        <Col flex="0 1 100px">
          <Space direction="vertical" align="center" size="middle">
            <div className="relative">
              <Avatar
                size={84}
                className="object-cover border-[2px] border-solid border-[#03B3B4]"
                src={
                  getImgUrl(extended_info?.logo_url) || (
                    <DefaultUserIcon width="84px" height="84px" />
                  )
                }
              />
              <Space className="absolute z-10 left-1/2 top-[-15%] transform -translate-x-1/2">
                <CompanyIcon width="30px" height="30px" />
              </Space>
            </div>
            {!readonly && !extendedInfoIsEmpty && (
              <Button
                type="primary"
                ghost
                onClick={() => navigate("/companies/edit-homepage")}
              >
                Edit Page
              </Button>
            )}
          </Space>
        </Col>
        <Col flex="1 1 100px" className="pl-2">
          <Space direction="vertical">
            <Row justify="space-between" align="middle">
              <Col>
                <Typography.Paragraph
                  ellipsis={{ rows: 1 }}
                  className="!text-white text-xl !mb-0"
                >
                  {extended_info?.name
                    ? extended_info.name
                    : companyInfo?.extended_info?.name}
                </Typography.Paragraph>
              </Col>
              <Col>
                <Tooltip title="Click to view verification status">
                  {readonly ? (
                    <Tag
                      className="ml-4"
                      color={
                        companyInfo?.is_verified === true ? "green" : "red"
                      }
                    >
                      {companyInfo?.is_verified === true
                        ? "Verified"
                        : "Unverified"}
                    </Tag>
                  ) : (
                    <Tag
                      className="ml-4"
                      color={getTagColor(
                        companyInfo?.verification_status || "UNSUBMITTED",
                      )}
                      onClick={() => navigate("/companies/show")}
                      style={{ cursor: "pointer" }}
                    >
                      {getStatusText(
                        companyInfo?.verification_status || "UNSUBMITTED",
                      )}
                    </Tag>
                  )}
                </Tooltip>
              </Col>
            </Row>
            <Text className="text-white">
              {extended_info
                ? extended_info.industry
                  ? extended_info.scale
                    ? `${extended_info.industry} | ${extended_info.scale}`
                    : extended_info.industry
                  : extended_info.scale
                    ? extended_info.scale
                    : ""
                : ""}
            </Text>
            <Text className="text-white">
              {extended_info ? (
                extended_info.country ? (
                  <div className="flex gap-1">
                    <EnvironmentFilled />
                    {extended_info.country}
                  </div>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </Text>
            <Text className="text-white">
              {formatNumber(followCount.followee_count)} Following{" "}
              {formatNumber(followCount.follower_count)} Followers
            </Text>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyImgInfo;
