import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import type { UploadProps } from "antd/es/upload";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import { useEffect, useState } from "react";
const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

type UploadCropProps = {
  value: string;
  onChange: (value: string) => void;
  uploadProps: UploadProps;
  listType?: string;
  aspect?: number;
  modalTitle?: string;
};
const UploadCrop = ({
  value,
  onChange,
  uploadProps,
  aspect,
  modalTitle,
}: UploadCropProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    if (value) {
      setFileList([
        {
          uid: value,
          name: "page_cover.png",
          //@ts-ignore
          url: IMG_PREFIX + value,
          thumbUrl: IMG_PREFIX + value,
        },
      ]);
    }
  }, [value]);

  // biome-ignore lint:
  useEffect(() => {
    if (fileList.length > 0) {
      const file = fileList[0];
      if (file?.response?.id) {
        onChange(file.response.id);
      }
    }
  }, [fileList]);

  const imgChange = (ev: { file: UploadFile; fileList: UploadFile[] }) => {
    setFileList([...ev.fileList]);
  };

  const imgRemove = () => onChange("");

  return (
    <ImgCrop aspect={aspect ? aspect : 5 / 1} modalTitle={modalTitle}>
      <Upload
        listType="picture-card"
        maxCount={1}
        {...uploadProps}
        onChange={imgChange}
        onRemove={imgRemove}
        fileList={fileList}
        accept="image/*"
        className="upload-list-inline"
      >
        {fileList.length < 1 && (
          <button
            style={{ cursor: "pointer", border: 0, background: "none" }}
            type="button"
          >
            {<PlusOutlined />}
            <div style={{ marginTop: 8 }}>Page Cover</div>
          </button>
        )}
      </Upload>
    </ImgCrop>
  );
};

export default UploadCrop;
