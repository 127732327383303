import dayjs from "dayjs";
import React from "react";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import App from "./App";
import "./i18n";

import * as Sentry from "@sentry/react";
import LocaleData from "dayjs/plugin/localeData";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import WeekDay from "dayjs/plugin/weekday";
import ErrorBoundaryWrapper from "./ErrorBoundaryWrapper";

dayjs.extend(WeekDay);
dayjs.extend(LocaleData);
dayjs.extend(LocalizedFormat);
const isDEV = import.meta.env.DEV;

Sentry.init({
  enabled: !isDEV,
  dsn: "https://c20bd6c2d94e7ad4244e0027cb328b0c@o4506898354077696.ingest.us.sentry.io/4506944114262016",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/dev-api\.leylinepro\.com\/mktp\//,
    /^https:\/\/api\.leylinepro\.com\/mktp\//,
  ],
});

const container = document.getElementById("root");
// biome-ignore lint:
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      <ErrorBoundaryWrapper>
        <App />
      </ErrorBoundaryWrapper>
    </React.Suspense>
  </React.StrictMode>,
);
