import { isImageFile, isVideoFile } from "@/utils/upload";
import { InboxOutlined } from "@ant-design/icons";
import { Create, useStepsForm } from "@refinedev/antd";
import {
  IResourceComponentsProps,
  useApiUrl,
  useGo,
  useOne,
  useParsed,
} from "@refinedev/core";
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Steps,
  Upload,
  message,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { axiosInstance } from "../../utils/http";

import { BudgetSummaryTable } from "./budgetSummaryTable";
import { JobInfoSummary } from "./jobInfoSummary";

import PaymentMethodForm, {
  JobPaymentMethod,
} from "@/components/businessComponents/paymentMethod";
import UploadOrInput from "@/pages/enterprises/components/UploadOrInput";
import { Alert, Space, Typography } from "antd";

import { softwareOptions } from "../../utils/selectionList";
const IMG_PREFIX = import.meta.env.VITE_MEDIA_ASSETS_BASE_URL;

import { UserType, useImportImUserMutation } from "../../__generated__/graphql";
import { delFileUrlPrefix, isImage, isVideo } from "../../utils/common";

const { TextArea } = Input;
const { Step } = Steps;

interface OptionItem {
  label: string;
  value: string;
}
interface ICreateJobIn {
  project_id: string;
  group_id: string;
  title: string;
  description: string;
  software: string[];
  location: string;
  employment_type: string;
  remote_option: string;
  question: {
    link: string;
    description: string;
    pic_url: string;
    video_url: string;
  };
  is_enabled: number;
  headcount: number;
  payment_method: number;
  count: number;
  count_unit: string;
  rate: number;
  rate_unit: string;
  total_cost: number;
}

const paymentMethodValMap = {
  0: "Piece-rate",
  1: "Time-based",
  2: "Negotiable",
  "Piece-rate": 0,
  "Time-based": 1,
  Negotiable: 2,
  null: 3,
};

export const JobCreate: React.FC<IResourceComponentsProps> = () => {
  const { current, gotoStep, stepsProps, formProps, saveButtonProps } =
    useStepsForm({
      warnWhenUnsavedChanges: false,
    });

  const [projectList, setProjectList] = useState(null);
  const [groupList, setGroupList] = useState([]);
  const [jobInDepartment, setJobInDepartment] = useState([]);

  const { id: jobId } = useParsed();

  const [calculateRateUnit, setCalculateRateUnit] = useState("");
  const [importImUserMutation] = useImportImUserMutation();
  const [submitBody, setSubmitBody] = useState({} as ICreateJobIn);
  const [projectInfo, setProjectInfo] = useState({});
  const debounceTimer = useRef();
  const paymentMethodFormRef = useRef();

  const {
    data: jobData,
    isError,
    isLoading,
  } = useOne({
    resource: "jobs",
    id: jobId,
    queryOptions: {
      staleTime: 1000,
      enabled: !!jobId,
    },
  });

  const fetchProjectList = async () => {
    const loadingMessage = message.loading(
      "Loading project list, please wait...",
      0,
    );
    try {
      const response = await axiosInstance.get("/projects", {
        params: {
          page: 1,
          size: 100,
          is_violated: false,
          progress_status: ["NOT_STARTED", "IN_PROGRESS"],
        },
      });
      if (response.status === 200) {
        const projects = response.data.items;
        setProjectList(projects);
        console.log(projects);

        const projectId = jobId
          ? jobData?.data?.project_id
          : sessionStorage.getItem("currentProjectId");
        // Maybe is null, it is normal.
        projectId && fetchProjectInfo(projectId);
      } else {
        message.error("Failed to get project list");
        console.log(response.data);
      }
    } catch (error) {
      message.error("Failed to get project list");
      console.log(error);
    } finally {
      loadingMessage();
    }
  };

  const fetchGroupOptions = async () => {
    const response = await axiosInstance.get("jobs/groups/options");
    if (response.status === 200) {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      const items = response.data.map((item: { name: any; id: any }) => ({
        label: item.name,
        value: item.id,
      }));
      setGroupList(items);
    }
  };
  const fetchProjectInfo = async (projectId: string) => {
    const response = await axiosInstance.get(`/projects/${projectId}`);
    if (response.status === 200) {
      setProjectInfo(response.data);
    }
  };

  const displayFormValue = useCallback(async () => {
    const data = jobData?.data || {};
    const benchmarkFileUrl =
      data.benchmark?.pic_url || data.benchmark?.video_url;
    const testFileUrl = data.test?.pic_url || data.test?.video_url;

    formProps.form?.setFieldsValue({
      detail: {
        project: data.project_id,
        department: data.group_id,
        job_title: data.title,
        skills_and_requirement: data.description,
        software: data.software,
        location: data.location,
        remote: data.remote_option,
        job_type: data.employment_type,
        benchmarkType: benchmarkFileUrl
          ? "upload"
          : data.benchmark?.link
            ? "link"
            : undefined,
        benchmarkFile: benchmarkFileUrl
          ? [
              {
                uid: "1",
                name: "benchmark file",
                status: "done",
                url: IMG_PREFIX + benchmarkFileUrl,
              },
            ]
          : undefined,
        benchmarkLink: data.benchmark?.link || undefined,
        testType: testFileUrl ? "upload" : data.test?.link ? "link" : undefined,
        testFile: testFileUrl
          ? [
              {
                uid: "1",
                name: "test file",
                status: "done",
                url: IMG_PREFIX + testFileUrl,
              },
            ]
          : undefined,
        testLink: data.test?.link || undefined,
      },
      headcount: data.headcount,
      payment_method: data.payment_method,
      totalAmount: data.total_cost,
      budget: {
        duration_per_ppl: data.payment_method === "Time-based" ? data.count : 0,
        quantity: data.payment_method === "Piece-rate" ? data.count : 0,
        unit: data.count_unit,
        unit_price: data.rate,
      },
    });
  }, [formProps.form, jobData]);

  // biome-ignore lint:
  useEffect(() => {
    // TODO: check no project
    fetchGroupOptions();
    jobId && jobData && displayFormValue();
  }, [jobData]);

  // biome-ignore lint:
  useEffect(() => {
    jobId && jobData && fetchProjectList();
    !jobId && fetchProjectList();
  }, [jobId, jobData]);

  useEffect(() => {
    if (current !== 1) return;
    (async () => {
      const loadingMessage = message.loading(
        "Fetching all jobs of current department, please wait...",
        0,
      );
      try {
        const jobResponse = await axiosInstance.get(
          `/jobs/projects/${submitBody.project_id}`,
          {
            params: {
              group_ids: [submitBody.group_id],
            },
          },
        );
        // @ts-ignore
        setJobInDepartment(jobResponse.data || []);
      } catch (error) {
        console.error("Error fetching all jobs :", error);
        message.error("Failed to fetch all jobs - try again later");
      } finally {
        loadingMessage();
      }
    })();
  }, [submitBody.project_id, submitBody.group_id, current]);

  const filterProjectOption = (inputValue: string, option?: OptionItem) => {
    return (option?.label?.indexOf(inputValue) ?? -1) > -1;
  };

  const doSubmit = useCallback(
    async (current: number, delta: number, onlySaveFlag?: boolean) => {
      if (current === 0) {
        !onlySaveFlag && gotoStep(current + 1);
        // @ts-ignore
        const { detail } = formProps.form.getFieldsValue();
        // handle benchmark
        const benchmarkFileUrl =
          detail.benchmarkType === "upload" && detail.benchmarkFile?.length > 0
            ? delFileUrlPrefix(
                detail.benchmarkFile[0].response?.id ||
                  detail.benchmarkFile[0].url,
              )
            : undefined;
        const benchmarkLink =
          detail.benchmarkType === "link" ? detail.benchmarkLink : undefined;
        // handle test
        const testFileUrl =
          detail.testType === "upload" && detail.testFile?.length > 0
            ? delFileUrlPrefix(
                detail.testFile[0].response?.id || detail.testFile[0].url,
              )
            : undefined;
        const testLink =
          detail.testType === "link" ? detail.testLink : undefined;

        const newBody = {
          ...submitBody,
          project_id: detail.project,
          group_id: detail.department,
          title: detail.job_title,
          description: detail.skills_and_requirement,
          software: detail.software, // can be nullable
          location: detail.location, // can be nullable
          employment_type: detail.job_type,
          remote_option: detail.remote,
          test: {
            link: testLink,
            // description: detail.test_desp,
            pic_url: testFileUrl && isImage(testFileUrl) ? testFileUrl : null,
            video_url: testFileUrl && isVideo(testFileUrl) ? testFileUrl : null,
          },
          benchmark: {
            link: benchmarkLink,
            // description: detail.test_desp,
            pic_url:
              benchmarkFileUrl && isImage(benchmarkFileUrl)
                ? benchmarkFileUrl
                : null,
            video_url:
              benchmarkFileUrl && isVideo(benchmarkFileUrl)
                ? benchmarkFileUrl
                : null,
          },
          is_enabled: 1,
        };
        console.log("submitBody", submitBody, newBody); // @ts-ignore
        setSubmitBody(newBody);
        return;
      }
      if (current === 1) {
        // @ts-ignore
        const values = formProps.form.getFieldsValue();
        // @ts-ignore
        const budget = values.budget || {};
        // @ts-ignore
        const headcount = values.headcount || {};
        console.log(budget);

        !onlySaveFlag && gotoStep(current + 1);
        const newBody = {
          ...submitBody,
          headcount: headcount ? parseInt(headcount) : null,
          payment_method: paymentMethodVal,
          count:
            paymentMethodVal !== JobPaymentMethod.Negotiable
              ? paymentMethodVal === JobPaymentMethod.Paperwork
                ? parseFloat(budget.quantity)
                : parseFloat(budget.duration_per_ppl)
              : null, // can be nullable
          count_unit:
            paymentMethodVal !== JobPaymentMethod.Negotiable
              ? budget.unit
              : null, // can be nullable
          rate:
            paymentMethodVal !== JobPaymentMethod.Negotiable
              ? parseFloat(budget.unit_price)
              : null, // can be nullable
          rate_unit:
            paymentMethodVal !== JobPaymentMethod.Negotiable
              ? paymentMethodVal === JobPaymentMethod.Time
                ? calculateRateUnit
                : ""
              : null, // can be nullable
          currency,
          total_cost:
            paymentMethodVal === JobPaymentMethod.Negotiable
              ? totalAmountVal
              : undefined,
        };
        console.log(submitBody, newBody); // @ts-ignore
        setSubmitBody(newBody);
        return;
      }
      if (current === 2) {
        const loadingMessage = message.loading(
          "Submitting job, please wait...",
          0,
        );
        try {
          const response = await fetch(
            jobId ? `${apiUrl}/jobs/${jobId}` : `${apiUrl}/jobs`,
            {
              method: jobId ? "PUT" : "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("leyline-auth")}`,
              },
              body: JSON.stringify(submitBody),
            },
          );

          const data = await response.json();
          if (response.status === 200 || response.status === 201) {
            importImUserMutation({
              variables: {
                getUserSigIn: {
                  role: UserType.Company,
                },
              },
            });
            setTimeout(() => {
              go({ to: "/jobs" });
            }, 300);
          } else {
            message.error(
              `Error - ${
                data.detail || `Failed to ${jobId ? "update" : "create"} job`
              }`,
            );
          }
        } catch (error) {
          console.error("Error creating job:", error);
          message.error(
            `Failed to ${
              jobId ? "update" : "create"
            }create job - try again later`,
          );
        } finally {
          loadingMessage();
        }
      }
    },
    [
      gotoStep,
      importImUserMutation,
      submitBody,
      formProps?.form?.getFieldsValue,
      jobId,
      calculateRateUnit,
    ],
  );

  // const jobTitleValue = Form.useWatch(["detail", "job_title"], formProps.form);
  const [jobTitleValue, setJobTitleValue] = useState("");
  Form.useWatch((values) => {
    // @ts-ignore
    if (values.detail?.job_title) {
      // @ts-ignore
      setJobTitleValue(values.detail?.job_title);
    }
  }, formProps.form);

  const headcountVal = Form.useWatch("headcount", formProps.form);
  const quantity = Form.useWatch(["budget", "quantity"], formProps.form);
  const durationPerPpl = Form.useWatch(
    ["budget", "duration_per_ppl"],
    formProps.form,
  );
  const unit = Form.useWatch(["budget", "unit"], formProps.form);
  const unitPrice = Form.useWatch(["budget", "unit_price"], formProps.form);
  const totalAmountVal = Form.useWatch("totalAmount", formProps.form);
  const paymentMethodVal = Form.useWatch("payment_method", formProps.form);

  const apiUrl = useApiUrl();
  const go = useGo();

  const [currency, setCurrency] = useState("USD");

  const onValuesChange = useCallback(() => {
    clearTimeout(debounceTimer.current);
    // @ts-ignore
    debounceTimer.current = setTimeout(() => {
      doSubmit(current, 1, true);
    }, 500);
  }, [doSubmit, current]);

  const handleChange = (value: string[]) => {
    const filteredTags = value.filter((tag) => tag.length <= 30);
    if (filteredTags.length < value.length)
      message.warning("The Tag should be no longer than 30 characters");
    formProps.form?.setFieldsValue({ detail: { software: filteredTags } });
  };

  const formList = [
    <Row key="Job Information" gutter={20}>
      <Col span={24}>
        <Form.Item
          label="Project"
          name={["detail", "project"]}
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select a project"
            showSearch
            filterOption={filterProjectOption}
            options={
              projectList // @ts-ignore
                ? projectList.map((val) => {
                    return {
                      value: val.id,
                      label: val.name,
                    };
                  })
                : []
            }
            onChange={(projectId) => {
              fetchProjectInfo(projectId);
            }}
          />
        </Form.Item>

        <Form.Item
          label="Group"
          name={["detail", "department"]}
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select a group"
            options={groupList}
            // onChange={(value) => onChangeDepartment(value)}
          />
        </Form.Item>

        <Form.Item
          label="Job Title"
          name={["detail", "job_title"]}
          rules={[{ required: true }, { max: 60 }]}
        >
          <Input maxLength={60} placeholder="Enter the job title" />
        </Form.Item>

        <Form.Item
          label="Skills and Requirement"
          name={["detail", "skills_and_requirement"]}
          rules={[{ required: true }]}
        >
          <TextArea
            autoSize={{ minRows: 4, maxRows: 30 }}
            showCount
            maxLength={10000}
            placeholder="Please enter"
          />
        </Form.Item>

        <Form.Item
          label="Software (Max: 20)"
          name={["detail", "software"]}
          rules={[{ required: false }]}
        >
          <Select
            mode="tags"
            maxCount={20}
            style={{ width: "100%" }}
            placeholder="Add software"
            options={softwareOptions}
            onChange={handleChange}
            onInputKeyDown={(event) => {
              // @ts-ignore
              if (event.target?.value?.length >= 30) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>

        <Form.Item
          label="Location"
          name={["detail", "location"]}
          rules={[{ required: false }, { max: 200 }]}
        >
          <Input maxLength={200} placeholder="Please enter" />
        </Form.Item>

        <Form.Item
          label="Work Model"
          name={["detail", "remote"]}
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select work model"
            options={[
              { value: "Remote", label: "Remote" },
              { value: "On-site", label: "On-site" },
              { value: "Hybrid", label: "Hybrid" },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Job Type"
          name={["detail", "job_type"]}
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select job type"
            options={[
              { value: "Contract", label: "Part-time/Contract" },
              { value: "Full-time", label: "Full-time" },
            ]}
          />
        </Form.Item>

        <UploadOrInput
          typeFieldName={["detail", "benchmarkType"]}
          typeFieldLabel="Benchmark"
          typeFieldRequired
          uploadProps={{ accept: "image/*,video/*" }}
          fileFieldName={["detail", "benchmarkFile"]}
          linkFieldName={["detail", "benchmarkLink"]}
        />

        <UploadOrInput
          typeFieldName={["detail", "testType"]}
          typeFieldLabel="Test"
          uploadProps={{ accept: "image/*,video/*" }}
          fileFieldName={["detail", "testFile"]}
          linkFieldName={["detail", "testLink"]}
        />
      </Col>
    </Row>,

    <Row key="Job Budget" gutter={20}>
      <Space direction="vertical" className="ml-[16%] mb-5">
        <Alert
          message={
            "Completing this section can help enterprises manage project budgets more effectively"
          }
          description={
            "Visible only to yourself, not displayed on the details page"
          }
          type="info"
          showIcon
        />
      </Space>

      <Col span={24}>
        <PaymentMethodForm
          normalLayout
          ref={paymentMethodFormRef}
          form={formProps.form as FormInstance}
          currency={currency}
          setCurrency={setCurrency}
        />
        <BudgetSummaryTable // @ts-ignore
          editingJobTitle={jobTitleValue}
          jobInDepartment={jobInDepartment}
          paymentMethod={paymentMethodVal}
          pplNum={headcountVal || 0}
          quantity={quantity || 0}
          duration={durationPerPpl || 0}
          unit={unit || "-"}
          unitPrice={unitPrice || 0}
          currency={currency}
          total={
            paymentMethodVal === JobPaymentMethod.Negotiable
              ? totalAmountVal ?? 0
              : quantity && unitPrice
                ? quantity * unitPrice
                : headcountVal && durationPerPpl && unitPrice
                  ? headcountVal * durationPerPpl * unitPrice
                  : 0
          }
        />
      </Col>
    </Row>,

    // Step 3: Submit
    <Row key="Submit" gutter={20}>
      <Col span={24}>
        {/* use jobData?.data to display company information */}
        <JobInfoSummary // @ts-ignore
          jobInfo={{ ...jobData?.data, ...submitBody }}
          departmentList={[]}
          projectInfo={projectInfo}
          goBack={() => {
            gotoStep(current - 1);
          }}
        />
      </Col>
    </Row>,
  ];

  return (
    <Create
      title={jobId ? "Edit Job" : "Create Job"}
      isLoading={false}
      saveButtonProps={saveButtonProps}
      headerButtons={
        <>
          {current > 0 && (
            <Button
              onClick={() => {
                gotoStep(current - 1);
              }}
            >
              Previous
            </Button>
          )}
          {current < formList.length - 1 && (
            <>
              <Button
                onClick={() => {
                  doSubmit(current, 1);
                  // gotoStep(current + 1);
                }}
              >
                Next
              </Button>
            </>
          )}
          {current === formList.length - 1 && (
            <Button
              onClick={() => {
                doSubmit(current, 1);
                // go({ to: "/jobs" });
              }}
            >
              Done
            </Button>
          )}
        </>
      }
      footerButtons={
        <>
          {current > 0 && (
            <Button
              onClick={() => {
                gotoStep(current - 1);
              }}
            >
              Previous
            </Button>
          )}
          {current < formList.length - 1 && (
            <Button
              onClick={() => {
                doSubmit(current, 1);
                // gotoStep(current + 1);
              }}
            >
              Next
            </Button>
          )}
          {current === formList.length - 1 && (
            <Button
              onClick={() => {
                doSubmit(current, 1);
                // go({ to: "/jobs" });
              }}
            >
              Done
            </Button>
          )}
        </>
      }
    >
      <Steps {...stepsProps} responsive>
        <Step title="Job Information" />
        <Step title="Job Budget" />
        <Step title="Submit" />
      </Steps>
      <Form
        // {...formProps}
        form={formProps?.form}
        style={{ margin: "30px auto 0" }}
        layout="horizontal"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        className={current === formList.length - 1 ? "" : "w-2/3"}
        onValuesChange={onValuesChange}
        onFinish={(values) => {
          console.log("----");
          console.log(values);
          console.log("----");
        }}
      >
        {formList[current]}
      </Form>
    </Create>
  );
};
