import { InboxOutlined } from "@ant-design/icons";
import { SaveButton } from "@refinedev/antd";
import { useApiUrl } from "@refinedev/core";
import {
  Button,
  Col,
  Form,
  FormProps,
  Input,
  Row,
  Select,
  Space,
  Upload,
  UploadFile,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import React from "react";
import { autoFill } from "../../utils/dev";
import { skillOptions, softwareOptions } from "../../utils/selectionList";

const { TextArea } = Input;
const { Option } = Select;

type WorkPreferenceFormProps = {
  formProps: FormProps;
  onAvatarPreview?: (file: UploadFile) => void;
};

export const WorkPreferenceForm: React.FC<WorkPreferenceFormProps> = ({
  formProps,
  onAvatarPreview,
}) => {
  return (
    <Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Work Model"
            name="remote_option"
            rules={[{ required: true }]}
          >
            <Select>
              <Option value="Remote">Remote</Option>
              <Option value="On-site">On-site</Option>
              <Option value="Hybrid">Hybrid</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Employment Type"
            name="employment_type"
            rules={[{ required: true }]}
          >
            <Select>
              <Option value="Full-time">Full-time</Option>
              <Option value="Contract">Contract</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Skills" name="skills">
            <Select
              showSearch
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Add skills"
              options={skillOptions}
            >
              {/* Options */}
            </Select>
          </Form.Item>
          <Form.Item label="Software" name="software">
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Add software"
              options={softwareOptions}
            >
              {/* Options  */}
            </Select>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Space>
            {/* <Button
              onClick={() => {
                autoFill(formProps, 0, 1);
              }}
            >
              Autofill the form
            </Button> */}
          </Space>
        </Col>
      </Row>
    </Form.Item>
  );
};
