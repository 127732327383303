import { CustomCard } from "@/components/customComponents/CustomCard";
import { ResumeProjectExperienceItem } from "@/components/discover/artistDetail/ResumeProjectExperienceItem";
import { ResumeWorkExperienceItem } from "@/components/discover/artistDetail/ResumeWorkExperienceItem";
import { IUserInfo } from "@/interfaces";
import useGlobalStore from "@/store";
import { SettingOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Space } from "antd";
import { useNavigate } from "react-router-dom";

interface IArtistResumeProps {
  user_role: string;
  artistUserInfo: IUserInfo;
  userId: string;
  authorId: string;
}

export const ArtistResume: React.FC<IArtistResumeProps> = ({
  user_role,
  artistUserInfo,
  userId,
  authorId,
}) => {
  const { currentRole } = useGlobalStore((state) => state);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      {authorId === userId && user_role === currentRole && (
        <div className="-mt-3 mb-3.5 text-right">
          <Button
            className="py-2 px-6 h-full rounded-full bg-[#00ADAE] text-white text-sm border-none"
            onClick={() => navigate("/users/edit")}
          >
            <SettingOutlined />
            <span>Edit Resume</span>
          </Button>
        </div>
      )}
      <div className="flex flex-col gap-5">
        <CustomCard title="Professional Summary">
          <div>
            {artistUserInfo.profile.summary ||
              "No profile information available."}
          </div>
        </CustomCard>
        <CustomCard title="Project experience">
          <div>
            {artistUserInfo.project_experience &&
            artistUserInfo.project_experience.length > 0 ? (
              artistUserInfo.project_experience.map((project) => (
                <ResumeProjectExperienceItem
                  key={project.id + project.name}
                  projectExperience={project}
                />
              ))
            ) : (
              <span>No project experience available. </span>
            )}
          </div>
        </CustomCard>
        <CustomCard title="Work experience">
          <div>
            {artistUserInfo.work_experience &&
            artistUserInfo.work_experience.length > 0 ? (
              artistUserInfo.work_experience.map((work, index) => (
                <Col>
                  <ResumeWorkExperienceItem
                    key={work.id + work.title}
                    workExperience={work}
                  />
                  {index < artistUserInfo.work_experience.length - 1 && (
                    <Divider className="bg-white opacity-10" />
                  )}
                </Col>
              ))
            ) : (
              <span>No work experience available. </span>
            )}
          </div>
        </CustomCard>
        <CustomCard title="Skills">
          <div className="flex flex-row gap-2 flex-wrap">
            {artistUserInfo.work_preference.skills &&
            artistUserInfo.work_preference.skills.length > 0 ? (
              artistUserInfo.work_preference.skills?.map((skill, index) => (
                <Space
                  // biome-ignore lint:
                  key={skill + index}
                  className="bg-[#404444] text-white rounded-full text-sm py-0.5 px-2 hover:bg-[#606464]"
                >
                  {skill}
                </Space>
              ))
            ) : (
              <span>No skills specified.</span>
            )}
          </div>
        </CustomCard>
        <CustomCard title="Softwares">
          <div className="flex flex-row gap-2 flex-wrap">
            {artistUserInfo.work_preference.software &&
            artistUserInfo.work_preference.software.length > 0 ? (
              artistUserInfo.work_preference.software?.map(
                (software, index) => (
                  <Space
                    // biome-ignore lint:
                    key={software + index}
                    className="bg-[#404444] text-white rounded-full text-sm py-0.5 px-2 hover:bg-[#606464]"
                  >
                    {software}
                  </Space>
                ),
              )
            ) : (
              <span>No software specified.</span>
            )}
          </div>
        </CustomCard>
      </div>
    </div>
  );
};
