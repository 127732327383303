import { UserType } from "@/__generated__/graphql";
import AdminChatHeader from "@/pages/enterprises/components/AdminChatHeader";
import AlertNotice from "@/pages/enterprises/components/AlertNotice";
import { sendEmail } from "@/pages/enterprises/services";
import eventBus from "@/utils/eventBus";
import { RoleTypeValue } from "@/utils/imUtils";
import {
  LeftOutlined,
  LoadingOutlined,
  ShoppingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  TUIChat,
  TUIChatHeader,
  TUIConversation,
  TUIConversationList,
  TUIKit,
  TUIMessageInput,
  TUIMessageList,
} from "@leyline/chat-uikit-react";
import "@leyline/chat-uikit-react/dist/cjs/index.css";
import { useBack } from "@refinedev/core";
import { Conversation } from "@tencentcloud/chat";
import TencentCloudChat from "@tencentcloud/chat";
import {
  IMessageModel,
  StoreName,
  TUIConversationService,
  TUIStore,
} from "@tencentcloud/chat-uikit-engine";
import { Avatar, Badge, Button, Segmented, Spin } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
// import { useHasCompanyRole } from "../../hooks";
import useGlobalStore from "../../store";
import CustomChatHeader from "./components/CustomChatHeader";
import styles from "./styles/chat.module.css";

const { SDK_NOT_READY, SDK_READY } = TencentCloudChat.EVENT;

const RoleItemComp = ({ label, count }: { label: string; count: number }) => {
  return (
    <span>
      {label}
      <Badge size="small" className="ml-2" count={count} />
    </span>
  );
};

const filterFindArtist = (imId: string, role: number) => {
  return (
    imId.endsWith("_individual") ||
    imId === "leyline_bot_party_a" ||
    (imId.endsWith("_company") && role === 2)
  );
};

export function ChatContent() {
  const [isLoading, setIsLoading] = useState(false);
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const [activeConversation, setActiveConversation] = useState<any>();
  const [isManualSwitchRole, setIsManualSwitchRole] = useState(false);
  const [tabVal, setTabVal] = useState(1);
  const [unreadCount1, setUnreadCount1] = useState(0);
  const [unreadCount2, setUnreadCount2] = useState(0);
  const { chatClient, currentRole } = useGlobalStore((state) => state);

  const { state: locationState } = useLocation();
  const { opponentImId, activeTab } = locationState || {};
  const back = useBack();

  const clearActiveConversation = useCallback(() => {
    setActiveConversation(null);
    // @ts-ignore
    TUIConversationService?.switchConversation(null);
  }, []);

  const filterConversation1 = useCallback<
    (list: Conversation[]) => Conversation[]
  >(
    (conversationList) => {
      return currentRole
        ? conversationList.filter((conversation) => {
            const imId = conversation.userProfile.userID;
            const jInfo = JSON.parse(conversation.customData || "{}").jInfo;
            return !filterFindArtist(imId, jInfo?.role);
          })
        : [];
    },
    [currentRole],
  );

  const filterConversation2 = useCallback<
    (list: Conversation[]) => Conversation[]
  >(
    (conversationList) => {
      return currentRole
        ? conversationList.filter((conversation) => {
            const imId = conversation.userProfile.userID;
            const jInfo = JSON.parse(conversation.customData || "{}").jInfo;
            return filterFindArtist(imId, jInfo?.role);
          })
        : [];
    },
    [currentRole],
  );

  const switchToConversation = useCallback(
    async (convId: string) => {
      const res = await chatClient?.getConversationProfile(convId);
      if (res.code === 0) {
        const { conversation } = res.data;
        TUIConversationService?.switchConversation(
          conversation?.conversationID,
        );
        setActiveConversation(conversation);
      }
    },
    [chatClient?.getConversationProfile],
  );

  useEffect(() => {
    const handler = () => {
      setIsManualSwitchRole(true);
      setTimeout(() => setIsManualSwitchRole(false), 10000);
    };
    eventBus.subscribe("onSwitchGlobalRole", handler);
    const onConversationIDUpdated = (id: string) => {
      id && switchToConversation(id);
    };
    const onConversationListUpdated = (convList: Conversation[]) => {
      let count1 = 0;
      let count2 = 0;
      console.log("convList", convList);
      for (let i = 0; i < convList.length; i++) {
        const conv = convList[i];
        const imId = conv.userProfile.userID;
        const jInfo = JSON.parse(conv.customData || "{}").jInfo;
        if (filterFindArtist(imId, jInfo?.role)) count2 += conv.unreadCount;
        else count1 += conv.unreadCount;
      }
      setUnreadCount1(count1);
      setUnreadCount2(count2);
    };
    const onReceivedMessageList = (list: Array<IMessageModel>) => {
      const lastestMsg = list[list.length - 1] || {};
      const localLastImMsg = JSON.parse(
        localStorage.getItem("lastImMsg") || "{}",
      );
      const from = lastestMsg.from;
      const convId = lastestMsg.conversationID;
      if (
        lastestMsg.flow === "out" &&
        lastestMsg.status === "success" &&
        !["leyline_bot_party_a", "leyline_bot_party_b"].includes(
          lastestMsg.to,
        ) &&
        localLastImMsg[from]?.[convId] !== lastestMsg.ID
      ) {
        sendEmail({
          receiver_im_id: lastestMsg.to,
          message_content: lastestMsg.payload.text,
          sender_im_nickname: lastestMsg.nick,
        });
        if (!localLastImMsg[from] || typeof localLastImMsg[from] === "string")
          localLastImMsg[from] = {};
        localLastImMsg[from][convId] = lastestMsg.ID;
        localStorage.setItem("lastImMsg", JSON.stringify(localLastImMsg));
      }
    };

    TUIStore.watch(StoreName.CONV, {
      currentConversationID: onConversationIDUpdated,
      conversationList: onConversationListUpdated,
    });
    TUIStore.watch(StoreName.CHAT, {
      messageList: onReceivedMessageList,
    });
    return () => {
      eventBus.unsubscribe("onSwitchGlobalRole", handler);
      TUIStore.unwatch(StoreName.CONV, {
        currentConversationID: onConversationIDUpdated,
        conversationList: onConversationListUpdated,
      });
      TUIStore.unwatch(StoreName.CHAT, {
        messageList: onReceivedMessageList,
      });
    };
  }, [switchToConversation]);

  // @ts-ignore
  useEffect(() => {
    if (!chatClient) return;
    if (opponentImId) {
      setTimeout(async () => {
        switchToConversation(`C2C${opponentImId}`);
      }, 100);
    }
    return clearActiveConversation;
  }, [chatClient, opponentImId, switchToConversation, clearActiveConversation]);

  useEffect(() => {
    setTabVal(activeTab || 1);
  }, [activeTab]);

  useEffect(() => {
    if (!currentRole) return;
    setIsLoading(true);
    // Avoid continuous loading.
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
    return clearActiveConversation;
  }, [currentRole, clearActiveConversation]);

  useEffect(() => {
    const readyFn = () => {
      setIsLoading(false);
      setIsManualSwitchRole(false);
    };
    const notReadyFn = () => setIsLoading(true);
    chatClient?.on(SDK_READY, readyFn);
    chatClient?.on(SDK_NOT_READY, notReadyFn);
    return () => {
      chatClient?.off(SDK_READY, readyFn);
      chatClient?.off(SDK_NOT_READY, notReadyFn);
    };
  }, [chatClient]);

  const isHideTab = useMemo(() => {
    return (
      !currentRole ||
      [UserType.Individual, UserType.Applicantindividual].includes(currentRole)
    );
  }, [currentRole]);

  return (
    <>
      <div className="flex justify-between items-center mt-2 mb-2">
        <Button size="small" icon={<LeftOutlined />} onClick={() => back()}>
          Back
        </Button>
        {isLoading && (
          <span className="text-gray-400">
            <Spin indicator={<LoadingOutlined spin />} /> Data loading...
          </span>
        )}
      </div>
      <div className={`${styles.chatContainer} relative`}>
        {!isManualSwitchRole && (
          <TUIKit
            chat={chatClient}
            language={"en"}
            activeConversation={activeConversation}
          >
            <div className="h-full flex flex-col w-[360px]">
              {!isHideTab && (
                <Segmented
                  className="w-auto m-auto mt-2"
                  value={tabVal}
                  onChange={(e) => {
                    setTabVal(e);
                    clearActiveConversation();
                  }}
                  options={[
                    {
                      label: (
                        <RoleItemComp
                          label="Find Artists"
                          count={unreadCount2}
                        />
                      ),
                      value: 2,
                      icon: <UserOutlined />,
                    },
                    {
                      label: (
                        <RoleItemComp label="Find Jobs" count={unreadCount1} />
                      ),
                      value: 1,
                      icon: <ShoppingOutlined />,
                    },
                  ]}
                />
              )}
              {tabVal === 1 && (
                <TUIConversation filterConversation={filterConversation1}>
                  <TUIConversationList />
                </TUIConversation>
              )}
              {tabVal === 2 && (
                <TUIConversation filterConversation={filterConversation2}>
                  <TUIConversationList />
                </TUIConversation>
              )}
            </div>
            <div className="h-full flex-1 flex bg-white box-border flex-col relative w-full">
              <AlertNotice />
              {!!activeConversation && (
                <TUIChat className="!flex-grow overflow-y-auto">
                  <TUIChatHeader
                    /* @ts-ignore */
                    TUIChatHeader={
                      ["leyline_bot_party_a", "leyline_bot_party_b"].includes(
                        activeConversation.userProfile.userID,
                      )
                        ? AdminChatHeader
                        : CustomChatHeader
                    }
                  />
                  <TUIMessageList />
                  <TUIMessageInput />
                </TUIChat>
              )}
            </div>
          </TUIKit>
        )}
      </div>
    </>
  );
}
