import { CustomPopconfirm } from "@/components/customComponents";
import { CustomCheckbox } from "@/components/customComponents/CustomCheckbox";
import { CustomDropDown } from "@/components/customComponents/CustomDropDown";
import {
  ArtworksFolderIcon,
  BulkMoveIcon,
} from "@/components/customComponents/CustomIcons";
import { CustomInput } from "@/components/customComponents/CustomInput";
import { CustomSelect } from "@/components/customComponents/CustomSelect";
import {
  artworkMoveToFolderAPI,
  deleteArtworkAPI,
  deleteBulkArtworkAPI,
  getMyArtworkListAPI,
} from "@/pages/discover/api";
import { IFolder, ISearchMyArtworkList } from "@/pages/discover/types";
import useGlobalStore from "@/store";
import Icon, {
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  EyeOutlined,
  HeartOutlined,
  MessageOutlined,
  MoreOutlined,
  PlusOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { useInfiniteScroll } from "ahooks";
import {
  Button,
  CheckboxProps,
  Col,
  Image,
  Popconfirm,
  Row,
  Space,
  Typography,
} from "antd";
import { debounce, set } from "lodash";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

interface IArtWork {
  folderList: IFolder[];
  artworkAllCount: number;
  setArtworkAllCount: (data: number) => void;
  setArtworkFilter: (data: ISearchMyArtworkList) => void;
  searchData: ISearchMyArtworkList;
  setIsDeleteArtworState: (data: string) => void;
}

export const PersonalArtworkManagement: React.FC<IArtWork> = ({
  folderList,
  artworkAllCount,
  setArtworkAllCount,
  setArtworkFilter,
  searchData,
  setIsDeleteArtworState,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { currentRole, updateLoading } = useGlobalStore((state) => state);
  const [deleteState, setDeleteState] = useState<string>("");
  const [selectedArtworkKeys, setSelectedArtworkKeys] = useState<React.Key[]>(
    [],
  );
  const navigate = useNavigate();

  const getArtistList = async (page: number) => {
    if (!currentRole) return { list: [], nextPage: undefined };
    const response = await getMyArtworkListAPI(updateLoading, currentRole, {
      ...searchData,
      sort: "LATEST",
      page,
    });
    setArtworkAllCount(response.total);
    return {
      list: response.list,
      nextPage: response.page < response.pages ? response.page + 1 : undefined,
    };
  };

  const {
    data: infiniteData,
    loadMore,
    loading,
    reload,
  } = useInfiniteScroll((d) => getArtistList(d ? d.nextPage : 1), {
    target: containerRef,
    isNoMore: (d) => !d?.nextPage,
    reloadDeps: [currentRole, deleteState, searchData, folderList],
  });

  const onArtworkEdit = (id: string) => {
    localStorage.setItem("workState", "false");
    navigate(`/portfolio/create?edit_mode=true&artwork_id=${id}`);
  };

  const onArtworkDelete = async (id: string) => {
    await deleteArtworkAPI(id);
    setIsDeleteArtworState(id);
    setDeleteState(id);
    setArtworkAllCount(artworkAllCount - 1);
  };

  const onSetArtworkSearchWord = debounce((value: string) => {
    setArtworkFilter({ ...searchData, search: value });
  }, 1000);

  const handleCreateArtwork = () => {
    localStorage.setItem("workState", "false");
    navigate("/portfolio/create");
  };

  const onArtworkSelectChange = (key: React.Key) => {
    const newSelectedRowKeys = selectedArtworkKeys.includes(key)
      ? selectedArtworkKeys.filter((k) => k !== key)
      : [...selectedArtworkKeys, key];
    setSelectedArtworkKeys(newSelectedRowKeys);
  };

  const onSelectAllChange: CheckboxProps["onChange"] = (e) => {
    const checked = e.target.checked;
    const newSelectedRowKeys = checked
      ? infiniteData?.list.map(({ id }) => id)
      : [];

    // @ts-ignore
    setSelectedArtworkKeys(newSelectedRowKeys);
  };

  const onSeletedArtworksDelete = async (ids: React.Key[]) => {
    console.log("ids", ids);
    await deleteBulkArtworkAPI(ids.map(String));
    setDeleteState(ids.map(String)[0]);
    setIsDeleteArtworState(ids.map(String)[0]);
    setArtworkAllCount(artworkAllCount - ids.length);
    setSelectedArtworkKeys(
      selectedArtworkKeys.filter((key) => !ids.includes(key)),
    );
  };

  const onClearSelection = () => {
    setSelectedArtworkKeys([]);
  };

  const onMoveToFolder = async (folderId: string) => {
    await artworkMoveToFolderAPI(folderId, selectedArtworkKeys.map(String));
    setIsDeleteArtworState(folderId);
  };

  const fileMenuItems = folderList.map((folder) => ({
    key: folder.id,
    label: (
      <Space
        className="opacity-50 text-white text-sm"
        onClick={() => onMoveToFolder(folder.id)}
      >
        <span>{folder.folder_name}</span>
      </Space>
    ),
  }));

  return (
    <Row
      ref={containerRef}
      className="flex flex-col px-4 h-[calc(100vh-80px)] overflow-auto"
    >
      <Col className="z-[999]">
        <div className="flex flex-row justify-between py-[30px] h-auto bg-[#1d1f22] items-center">
          <div className="flex flex-row gap-4">
            <CustomInput
              placeholder="Please Enter Artwork Title..."
              className="!rounded-full !w-[298px]"
              onChange={(e) => onSetArtworkSearchWord(e.target.value)}
            />
            <CustomSelect
              placeholder="Status"
              // defaultValue="0"
              options={[
                { value: "", label: "All" },
                { value: "false", label: "Not Published" },
                { value: "true", label: "Published" },
              ]}
              onChange={(value) =>
                setArtworkFilter({
                  ...searchData,
                  is_published: value === "" ? null : value,
                })
              }
              roundRadius
              // className="!rounded-full !w-[142px] personal-artwork-select"
              className="!w-[142px] h-[37px]"
            />
            <div className="flex flex-row gap-3 items-center">
              <CustomCheckbox
                onChange={onSelectAllChange}
                checked={
                  selectedArtworkKeys.length === infiniteData?.list.length &&
                  !!infiniteData?.list.length
                }
              />
              <Typography.Text className="text-base text-opacity-85 text-white">
                Select All
              </Typography.Text>
            </div>
            {selectedArtworkKeys.length === infiniteData?.list.length &&
              !!infiniteData?.list.length && (
                <div className="flex flex-row gap-2.5 items-center text-[#999999]">
                  <CustomDropDown
                    menu={{
                      items: fileMenuItems,
                    }}
                    placement="bottomLeft"
                  >
                    <div className="p-[5px] bg-white bg-opacity-10 rounded-md h-[37px] hover:bg-opacity-30 hover:text-white">
                      <ExportOutlined className="text-2xl" />
                    </div>
                  </CustomDropDown>
                  <CustomPopconfirm
                    title="Are you sure you want to delete the selected artworks?"
                    placement="bottomLeft"
                    onConfirm={() =>
                      onSeletedArtworksDelete(selectedArtworkKeys)
                    }
                  >
                    <div className="p-[5px] bg-white bg-opacity-10 rounded-md hover:bg-opacity-30 hover:text-white">
                      <DeleteOutlined className="text-2xl" />
                    </div>
                  </CustomPopconfirm>
                </div>
              )}
          </div>
          {infiniteData?.list && infiniteData?.list.length > 0 && (
            <div className="flex flex-row items-center gap-4">
              {selectedArtworkKeys.length > 0 && (
                <>
                  <Typography.Text className="text-base text-[#999]">
                    {selectedArtworkKeys.length} Artworks Selected
                  </Typography.Text>
                  <Typography.Text
                    className="text-base text-[#00ADAE]"
                    onClick={onClearSelection}
                  >
                    Clear Selection
                  </Typography.Text>
                </>
              )}
              <Button
                type="primary"
                // className="bg-[#00ADAE] px-4 py-1 border-none rounded-full text-white text-base float-right"
                onClick={handleCreateArtwork}
              >
                <PlusOutlined />
                <span>Create New Artwork</span>
              </Button>
            </div>
          )}
        </div>
      </Col>
      <Col className="py-[30px] flex-1">
        {infiniteData?.list && infiniteData?.list.length > 0 ? (
          <Col className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
            {infiniteData?.list.map((artwork) => (
              <Col
                className="p-2.5 rounded-sm bg-[#141618] flex flex-col gap-[15px]"
                key={artwork.id}
              >
                <div className="absolute right-5 top-5 z-[10]">
                  <CustomCheckbox
                    checked={selectedArtworkKeys.includes(artwork.id)}
                    onChange={() => onArtworkSelectChange(artwork.id)}
                  />
                </div>
                <Image
                  preview={false}
                  width="100%"
                  height="166px"
                  src={
                    artwork.cover_picture
                      ? artwork.cover_picture
                      : "https://placehold.co/700x400?text=No+Image&font=roboto"
                  }
                />
                <Col className="flex flex-row justify-between gap-2">
                  <Typography.Text className="text-white text-base opacity-85 overflow-hidden overflow-ellipsis whitespace-nowrap">
                    {artwork.title} {artwork.description.replace(/\n/g, " ")}
                  </Typography.Text>
                  <div className="text-[#f55f4e] text-xs rounded-full px-[5px] bg-white flex items-center w-auto h-auto whitespace-nowrap">
                    {artwork.is_published ? "Published" : "Not Published"}
                  </div>
                </Col>
                <Col className="flex flex-row justify-between">
                  <Col className="flex flex-row gap-[15px]">
                    <Col className="flex items-center gap-1 text-white opacity-50 text-sm">
                      <HeartOutlined />
                      <span>{artwork.liked_count}</span>
                    </Col>
                    <Col className="flex items-center gap-1 text-white opacity-50 text-sm">
                      <StarOutlined />
                      <span>{artwork.bookmarked_count}</span>
                    </Col>
                    <Col className="flex items-center gap-1 text-white opacity-50 text-sm">
                      <MessageOutlined />
                      <span>{artwork.commented_count}</span>
                    </Col>
                    <Col className="flex items-center gap-1 text-white opacity-50 text-sm">
                      <EyeOutlined />
                      <span>{artwork.visited_count}</span>
                    </Col>
                  </Col>
                  <Col>
                    <CustomDropDown
                      menu={{
                        items: [
                          {
                            key: "1",
                            label: (
                              <Space
                                className="opacity-50 text-white text-sm"
                                onClick={() => onArtworkEdit(artwork.id)}
                              >
                                <EditOutlined className="text-base" />
                                <span>Edit</span>
                              </Space>
                            ),
                          },
                          {
                            key: "2",
                            label: (
                              <CustomPopconfirm
                                title="Are you sure you want to delete the artwork?"
                                placement="topRight"
                                onConfirm={() => onArtworkDelete(artwork.id)}
                                className="opacity-50 text-white flex gap-2"
                              >
                                <DeleteOutlined className="text-base" />
                                <span>Delete</span>
                              </CustomPopconfirm>
                            ),
                          },
                        ],
                      }}
                      placement="bottomRight"
                    >
                      <MoreOutlined className="text-sm text-white cursor-pointer" />
                    </CustomDropDown>
                  </Col>
                </Col>
              </Col>
            ))}
          </Col>
        ) : (
          <Col className="flex flex-1 justify-center items-center h-full">
            <Col className="flex flex-col items-center">
              <Icon className="text-[120px]" component={ArtworksFolderIcon} />
              <Typography.Text className="text-[#686868] text-sm">
                You have not uploaded any artworks
              </Typography.Text>
              <Button
                className="bg-[#00ADAE] px-6 py-2 border-none rounded-full text-white text-base h-auto w-fit mt-12"
                onClick={handleCreateArtwork}
              >
                <PlusOutlined />
                <span>Create Now</span>
              </Button>
            </Col>
          </Col>
        )}
      </Col>
    </Row>
  );
};
