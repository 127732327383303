import {
  AppstoreFilled,
  FolderOpenFilled,
  LayoutFilled,
  LoadingOutlined,
  ProjectFilled,
  SettingOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import {
  ErrorComponent,
  ThemedLayoutV2,
  useNotificationProvider,
} from "@refinedev/antd";
import { Authenticated, Refine } from "@refinedev/core";
import { RefineKbarProvider } from "@refinedev/kbar";
import routerProvider, {
  CatchAllNavigate,
  DocumentTitleHandler,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import React, { memo, useEffect } from "react";
import { CustomizedThemedLayout } from "./components/layout";
// import dataProviderGQL, { GraphQLClient } from "@refinedev/graphql";

import { Toaster } from "react-hot-toast";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { authProvider } from "./authProvider";

import * as Sentry from "@sentry/react";
import { App as AntdApp, Spin } from "antd";
import "dayjs/locale/de";
import { useTranslation } from "react-i18next";
import { PiBuildingsFill } from "react-icons/pi";
import { Header, OffLayoutArea, Sider, Title } from "./components";
import { ConfigProvider } from "./contexts";
import { AuthPage } from "./pages/auth";
import {
  CompanyCreate,
  CompanyHomepage,
  CompanyShow,
  EditCompanyHomepage,
} from "./pages/companies";

import { DiscoverHomepage } from "./pages/discover";

import {
  ApplyJobAlternative,
  ChatContent,
  PublicCompanyHomepage,
  PublicJobDetails,
  PublicJobsAlternative,
  PublicProjectDetails,
} from "./pages/enterprises";
import { JobCreate, JobDetail, JobEdit, JobList } from "./pages/jobs";
import {
  CreateProject,
  EditProject,
  EditProjectVerification,
  ProjectDetailsNew,
  ProjectManagement,
  ProjectVerification,
} from "./pages/projects";
import { UserEdit, UserEditV2, UserProfilePage, UserShow } from "./pages/users";
import { dataProvider as restDataProvider } from "./rest-data-provider";

import { HeaderLite } from "@/components/header/HeaderLite";
import { PersonalSider } from "@/components/personalCenter/PersonalSider";
import { usePrevious } from "@/hooks";
import { ArtistPortfolio } from "@/pages/discover/ArtistPortfolio";
import { PortfolioDetail } from "@/pages/discover/PortfolioDetail";
import { InvitationLanding } from "@/pages/invitation/InvitationLanding";
import { CreateArtwork } from "@/pages/personal/CreateArtwork";
import { PersonalPortfolio } from "@/pages/personal/PersonalPortfolio";
import { SettingsPage } from "@/pages/setting";
import useGlobalStore from "@/store";
import { ApolloProvider } from "@apollo/client";
import "@refinedev/antd/dist/reset.css";
import "./main.css";
import { AIPage } from "./pages/ai";
import { CompleteAccountPage } from "./pages/auth/components/AccountPage";
import { JobListStatic } from "./pages/jobs/listStatic";
import PreviewIcons from "./pages/previewIcons";
import { ProducerTool } from "./pages/producerTool";
import SettlementList from "./pages/wallet/SettlementList";
import apolloClient from "./utils/apolloClient";
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// const client = new GraphQLClient("https://dev-api.leylinepro.com/chat/graphql");

const RouteListen = memo(() => {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  useEffect(() => {
    if (prevLocation) {
      sessionStorage.setItem("prevPagePath", prevLocation.pathname);
    }
  }, [prevLocation]);
  return null;
});

const App: React.FC = () => {
  // This hook is used to automatically login the user.
  // We use this hook to skip the login page and demonstrate the application more quickly.
  // const { loading } = useAutoLoginForDemo();
  // const loading = false;
  const { loading } = useGlobalStore((state) => ({
    loading: state.loading,
  }));
  //if defined in .env, use that, otherwise use default
  const API_URL =
    import.meta.env.VITE_MARKETPLACE_API_BASE_URL || "http://localhost:3000";

  const API_URL_PT =
    import.meta.env.VITE_PRODUCER_TOOL_API_BASE_URL || "http://localhost:3000";

  const BASE_URL = import.meta.env.BASE_URL || "/";
  const dataProvider = restDataProvider(API_URL);
  const producerToolDataProvider = restDataProvider(API_URL_PT);
  // const dataProvider1 = dataProviderGQL(client);

  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  // const [currentTheme, setCurrentTheme] = useState<"light" | "dark">("dark");

  // if (loading) {
  //   return null;
  // }

  return (
    <BrowserRouter basename={BASE_URL}>
      <ApolloProvider client={apolloClient}>
        <ConfigProvider>
          <AntdApp>
            <RefineKbarProvider>
              {/* <DevtoolsProvider> */}
              <div>
                <Toaster />
              </div>
              {loading && (
                <div className="fixed z-[99999] w-full h-full bg-black bg-opacity-45">
                  <div className="flex justify-center items-center h-full">
                    <Spin indicator={<LoadingOutlined spin />} />
                  </div>
                </div>
              )}
              <RouteListen />
              <Refine
                routerProvider={routerProvider}
                dataProvider={{
                  default: dataProvider,
                  PT: producerToolDataProvider,
                }}
                authProvider={authProvider}
                i18nProvider={i18nProvider}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                  disableTelemetry: true,
                  projectId: "uFpJ6k-YuIBrU-qOLOgN",
                  reactQuery: {
                    clientConfig: {
                      defaultOptions: {
                        queries: {
                          staleTime: 1000 * 60 * 5,
                        },
                      },
                    },
                  },
                }}
                notificationProvider={useNotificationProvider}
                resources={[
                  {
                    name: "/users",
                    list: "/users/home",
                    show: "/users/show",
                    create: "/users/profile",
                    edit: "/users/edit",
                    meta: {
                      label: "Application",
                      icon: <AppstoreFilled />,
                    },
                  },
                  {
                    name: "/portfolio",
                    list: "/portfolio/home",
                    create: "/portfolio/create",
                    meta: {
                      label: "Portfolio",
                      icon: <FolderOpenFilled />,
                    },
                  },
                  {
                    name: "/companies",
                    list: "/companies",
                    // show: "/companies/show",
                    edit: "/companies/edit",
                    create: "/companies/create",
                    meta: {
                      label: "My Company",
                      icon: <PiBuildingsFill />,
                    },
                  },
                  {
                    name: "/projects",
                    list: "/projects",
                    create: "/projects/create-project",
                    meta: {
                      label: "My Projects",
                      icon: <ProjectFilled />,
                    },
                  },
                  {
                    name: "/jobs", // edit/:id page auto request use name prefix!!
                    list: "/jobs",
                    show: "/jobs/detail/:id",
                    create: "/jobs/create",
                    // edit: "/jobs/edit/:id",
                    meta: {
                      label: "My Openings",
                      icon: <LayoutFilled />,
                    },
                  },
                  {
                    name: "/wallet",
                    list: "/wallet/settlements",
                    // show: "/jobs/detail/:id",
                    // create: "/jobs/create",
                    // edit: "/jobs/edit/:id",
                    meta: {
                      label: "Wallet",
                      icon: <WalletOutlined />,
                    },
                  },
                  {
                    name: "/setting",
                    list: "/setting",
                    // show: "/setting",
                    // create: "/jobs/create",
                    // edit: "/jobs/edit/:id",
                    meta: {
                      label: "Setting",
                      icon: <SettingOutlined />,
                    },
                  },
                ]}
              >
                <SentryRoutes>
                  <Route
                    element={
                      <CustomizedThemedLayout
                        Header={Header}
                        Title={Title}
                        OffLayoutArea={OffLayoutArea}
                      >
                        <Outlet />
                      </CustomizedThemedLayout>
                    }
                  >
                    {/* <Route index element={<UserShow />} /> */}
                    <Route index element={<DiscoverHomepage />} />

                    <Route
                      path="/discover/portfolio/:id"
                      element={<PortfolioDetail />}
                    />
                    <Route
                      element={
                        <Authenticated
                          key="authenticated-routes"
                          fallback={<CatchAllNavigate to="/login" />}
                        >
                          <Outlet />
                        </Authenticated>
                      }
                    >
                      <Route
                        index
                        path="/discover/author/:id"
                        element={<ArtistPortfolio key="artist" />}
                      />
                      <Route
                        element={
                          <PersonalSider>
                            <Outlet />
                          </PersonalSider>
                        }
                      >
                        <Route path="/users">
                          <Route index element={<UserShow />} />
                          <Route path="home" element={<UserShow />} />
                          <Route path="exedit" element={<UserEdit />} />
                          <Route path="edit" element={<UserEditV2 />} />
                          <Route
                            path="profile"
                            element={<ArtistPortfolio key="userProfile" />}
                          />
                        </Route>
                        <Route path="/portfolio">
                          <Route index element={<PersonalPortfolio />} />
                          <Route path="home" element={<PersonalPortfolio />} />
                          <Route path="create" element={<CreateArtwork />} />
                        </Route>
                        <Route path="/companies">
                          <Route
                            index
                            element={<CompanyHomepage key="company" />}
                          />
                          <Route path="show" element={<CompanyShow />} />
                          <Route path="create" element={<CompanyCreate />} />
                          <Route
                            path="homepage"
                            element={<CompanyHomepage key="company" />}
                          />
                          {/* todo: */}
                          <Route
                            path="homepage/:id"
                            element={<CompanyHomepage />}
                          />
                          <Route
                            path="edit-homepage"
                            element={<EditCompanyHomepage />}
                          />
                        </Route>
                        <Route path="/jobs">
                          <Route index element={<JobList />} />
                          <Route
                            path="list-static"
                            index
                            element={<JobListStatic />}
                          />
                          <Route path="detail/:id" element={<JobDetail />} />
                          <Route path="create" element={<JobCreate />} />
                          <Route path="edit/:id" element={<JobCreate />} />
                        </Route>
                        <Route path="/wallet">
                          <Route index element={<SettlementList />} />
                          <Route
                            path="settlements"
                            element={<SettlementList />}
                          />
                        </Route>
                        <Route path="/projects">
                          <Route index element={<ProjectManagement />} />
                          <Route
                            path="/projects/create-project"
                            element={<CreateProject />}
                          />
                          <Route
                            path="/projects/edit-project"
                            element={<EditProject />}
                          />
                          <Route
                            path="/projects/project-details"
                            element={<ProjectDetailsNew />}
                          />
                          <Route
                            path="/projects/verification"
                            element={<ProjectVerification />}
                          />
                          <Route
                            path="/projects/edit-verification"
                            element={<EditProjectVerification />}
                          />
                        </Route>
                        <Route path="/setting">
                          <Route index element={<SettingsPage />} />
                          {/* <Route
                            path=""
                            element={<EditProjectVerification />}
                          /> */}
                        </Route>
                      </Route>
                    </Route>

                    <Route path="/ai" element={<AIPage />} />

                    <Route path="/producer-tool" element={<ProducerTool />} />

                    <Route
                      path="/enterprises/public-jobs"
                      element={<PublicJobsAlternative />}
                    />

                    <Route
                      path="/enterprises/public-job-details/:id"
                      element={<PublicJobDetails />}
                    />

                    <Route
                      path="/enterprises/public-project-details"
                      element={<PublicProjectDetails />}
                    />

                    <Route
                      path="/enterprises/apply-job"
                      element={<ApplyJobAlternative onSuccess={() => {}} />}
                    />

                    <Route
                      path="/enterprises/public-company-homepage"
                      element={<PublicCompanyHomepage />}
                    />

                    <Route path="/enterprises/chat" element={<ChatContent />} />
                    <Route path="*" element={<ErrorComponent />} />
                    {/* <Route path="/companies">
                    <Route index element={<OrderList />} />
                    <Route path="show/:id" element={<OrderShow />} />
                  </Route>
                  
                  <Route path="/projects">
                  <Route index element={<ProjectList />} />
                  <Route path="show/:id" element={<ProjectShow />} />
                </Route> */}
                  </Route>

                  <Route
                    element={
                      <CustomizedThemedLayout OffLayoutArea={OffLayoutArea}>
                        <Outlet />
                      </CustomizedThemedLayout>
                    }
                  >
                    <Route
                      path="/login"
                      element={
                        <AuthPage
                          type="login"
                          formProps={{
                            initialValues: {
                              email: "",
                              password: "",
                            },
                          }}
                        />
                      }
                    />
                    <Route
                      path="/register"
                      element={
                        <AuthPage
                          type="register"
                          formProps={{
                            initialValues: {
                              email: "",
                              password: "",
                            },
                          }}
                        />
                      }
                    />
                    <Route
                      path="/forgot-password"
                      element={<AuthPage type="forgotPassword" />}
                    />
                    <Route
                      path="/update-password"
                      element={<AuthPage type="updatePassword" />}
                    />
                  </Route>

                  <Route
                    path="/complete-account"
                    element={<CompleteAccountPage />}
                  />

                  <Route
                    element={
                      <ThemedLayoutV2
                        Header={HeaderLite}
                        Sider={() => <></>}
                        Title={Title}
                        OffLayoutArea={OffLayoutArea}
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    }
                  >
                    <Route
                      path="/staff/invitation"
                      element={<InvitationLanding />}
                    />
                  </Route>

                  {/* <Route
                    element={
                      <ThemedLayoutV2
                        Header={Header}
                        Title={Title}
                        OffLayoutArea={OffLayoutArea}
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    }
                  ></Route> */}
                  <Route path="/preview-icons" element={<PreviewIcons />} />
                </SentryRoutes>
                <UnsavedChangesNotifier />
                <DocumentTitleHandler />
              </Refine>
              {/* <DevtoolsPanel />
            </DevtoolsProvider> */}
            </RefineKbarProvider>
          </AntdApp>
        </ConfigProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default App;
